import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = new Vuex.Store({
    state() {
        return {
            storedData: [],
            oRstoredData: [],
            user: {},
            webApplicationToShow: [],
            dataLowConnection: {},
        }
    },
    mutations: {
        deleteEtadlByIndex(st, index) {
            if (st.storedData == undefined)
                st.storedData = []
            st.storedData.splice(index, 1)
        },
        setStoredData(st, val) {
            if (st.storedData == undefined)
                st.storedData = []
            st.storedData.push(val)
        },
        setOrStoredData(st, val) {
            if (st.oRstoredData == undefined)
                st.oRstoredData = []
            st.oRstoredData.push(val)
        },
        clearOrStoredDataById(st, id) {
            let findedIndx = st.oRstoredData.findIndex((val) => val.data.id == id)
            
            if (st.oRstoredData && st.oRstoredData.length > 0 && findedIndx !== -1) {
                st.oRstoredData.splice(findedIndx, 1);
            }
        },
        clearStoredDataById(st, id) {
            let findedIndx = st.storedData.findIndex((val) => val.data.CLD_IDEnreg == id)
            
            if (st.storedData && st.storedData.length > 0 && findedIndx !== -1) {
                st.storedData.splice(findedIndx, 1);
            }
        },
        clearStoredData(st) {
            st.storedData = []
        },
        clearOrStoredData(st) {
            st.oRstoredData = []
        }
    },
    plugins: [vuexLocal.plugin]
});

export default store