import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import momentPlugin from '@fullcalendar/moment'
import interactionPlugin from "@fullcalendar/interaction";

// import Vue from 'vue';

// import { Calendar } from "@fullcalendar/core";

import fr from "@fullcalendar/core/locales/fr";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
// import { computed, watch } from "@vue/runtime-core";

import { onMounted } from "vue";
import { useStore } from 'vuex'

import moment from 'moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import engine from "@/projects/planning-disponibilite/core/index.js";

export default function calendarData(windowSize) {
    const viewListe = ref([
        { title: 'Parc complet', value: 0 },
        { title: 'Contrats actif', value: 1 },
        { title: 'Sous familles active', value: 2 },
    ])
    const nbrDaysdurationListe = ref([1, 7, 15, 30, 90]);
    const slotdurationListe = ref(["01:00", "02:00", "06:00", "12:00"]);
    const startPlanningTime = ref(["00:00", "02:00", "04:00", "06:00", "08:00", "10:00"]);
    const endPlanningTime = ref(["14:00", "16:00", "18:00", "20:00", "22:00", "23:59"]);
    const selectedNbrDays = ref(7)
    const selectedslotduration = ref("02:00")
    const selectedStartTime = ref("00:00")
    const selectedEndTime = ref("23:59")
    const selectedView = ref(1)
    const store = useStore()
    const selectedPlanningVue = ref({});
    const calendarDates = ref({ start: undefined, end: undefined });
    const resourceIds = ref([]);
    const filteredRessouceIds = ref([])
    const eventsList = ref([]);
    const eventsListReserved = ref([]);
    const eventsListSav = ref([]);
    const contratState = ref([]);
    const daysToHide = ref([]);
    const showAllSubFamily = ref(false);
    const showActifContrat = ref(false);
    const selectedSubFamilyToShow = ref([]);
    const loaded = ref(0)
    // const isLittleScreen = ref(undefined)
    const settingsData = ref({});
    const isMobile = ref(false)
    const eventsTypeToShow = ref({})
    // const nbrLoadedEvents = ref(0)
    const eventColorState = ref({})
    const isModalsOpen = ref({
        'dateModal': { value: false, data: {} },
        'eventModal': { value: false, data: {} },
        'settingsModal': { value: false, data: {} },
        'eventSavModal': { value: false, data: {} },
    });
    // const isModalSettingsOpen = ref(false);
    const eventColorByType = ref({
        LOCATION: { label: 'Location', color: '#A1887F' },
        RESERVATION: { label: 'Reservation', color: '#81C784' },
        SAV: { label: 'Sav', color: '#A1887F' },
        LIVRAISON: { label: 'Livraison', color: '#FF0000' },
    })

    onMounted(() => {
        setPlanningVue()
        updateCalendarEventsToShow()
        updateCalendarDaysToShow()
        getContratState()
        // getColorState()

        engine.get_parc(function (data) {
            if (data.code == 0) {
                resourceIds.value = data.data.data.map((val) => ({
                    id: (val.fieldData.SOUS_FAMILLE + val.fieldData.MATRICULE).toUpperCase(),
                    title: val.fieldData.MATRICULE,
                    INDEX_SFAMILLE: val.fieldData.INDEX_SFAMILLE,
                    subFamily: val.fieldData.SOUS_FAMILLE,
                    matricule: val.fieldData.MATRICULE,
                }));
            }
        })
    })

    // const setNewScreenSize = function(screenSize) {
    //     if (screenSize < 650)
    //         isLittleScreen.value = true
    //     else
    //         isLittleScreen.value = false
    // }

    const getCustomButtons = computed(function () {
        let customButtons = {}
        // customButtons.showSubFamily = {
        //     text: showAllSubFamily.value == true ? 'Cacher Parc' : 'Afficher Parc',
        //     click: changeRessourceType
        // }
        customButtons.showActifContrat = {
            text: showActifContrat.value == true ? 'Afficher tout les contrat' : 'Afficher les contrat Actif',
            click: changeShowActifContrat
        }
        customButtons.openSettings = {
            text: 'Options',
            click: openSettings
        }
        return customButtons
    })

    const getAllEvents = computed(function () {
        return eventsList.value.concat(eventsListReserved.value.concat(eventsListSav.value))
    })

    const calendarOptions = computed(function () {
        let option = {
            // editable: true,

            datesSet: updateCalendarView,
            dateClick: calendarClicked,
            eventClick: eventClicked,
            schedulerLicenseKey: '0359429366-fcs-1650894843',
            // nowIndicator: true,
            initialView: "defaultTimeLine",
            duration: { days: 2 },
            locale: fr,
            height: windowSize,
            headerToolbar: {
                left: isRoot.value ? isMobile.value == false ? 'today openSettings' : 'prev today' : 'today',
                center: 'title',
                right: isMobile.value == false ? 'prev,next' : 'next'
            },
            timeZone: 'UTC',
            views: {
                day: {
                    titleFormat: 'dddd D MMMM YYYY',
                },
                defaultTimeLine: {
                    type: 'resourceTimelineWeek',
                    duration: { days: selectedNbrDays.value },
                    slotDuration: selectedslotduration.value,
                    slotMinTime: selectedStartTime.value,
                    slotMaxTime: selectedEndTime.value,
                },
            },
            plugins: [momentPlugin, dayGridPlugin, timeGridPlugin, resourceTimelinePlugin, interactionPlugin],
            aspectRatio: 1.6,
            resourceAreaWidth: '255px',
            resourceGroupField: 'subFamily',
            allDaySlot: false,
            resourceAreaHeaderContent: 'Sous famille - Matricules',
            hiddenDays: daysToHide.value,
            events: getAllEvents.value,
            resources: filteredRessouceIds.value,
            customButtons: getCustomButtons.value
        }
        return option;
    })

    const updateCalendarEventsToShow = function () {
        engine.get_planning_events_type_to_show(function (data) {
            if (data.code == 0) {
                let elem = data.data.data.map((val) => ({ key: val.fieldData.Key, value: val.fieldData.Value }))
                for (let i = 0; i < elem.length; i++) {
                    eventsTypeToShow.value[elem[i].key] = elem[i].value
                }
            }
            updateCalendarColors()
        })
    }

    const getContratState = function () {
        engine.get_contrat_state(function (data) {
            if (data.code == 0) {
                contratState.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const updateCalendarDaysToShow = function () {
        engine.get_day_to_hide(function (data) {
            if (data.code == 0) {
                daysToHide.value = data.data.data[0].fieldData.Value.split(',').map((val) => parseInt(val))
            }
        })
    }

    // const getColorState = function() {
    //     engine.get_state_colors(function(data) {
    //         if (data.code == 0 && data.data.data[0].fieldData.Value != "" && data.data.data[0].fieldData.Value != undefined) {
    //             eventColorState.value = JSON.parse(data.data.data[0].fieldData.Value)
    //         }
    //     })
    // }

    const updateCalendarColors = function () {
        engine.get_calendar_colors(function (data) {
            if (data.code == 0 && data.data.data[0].fieldData.Value != "" && data.data.data[0].fieldData.Value != undefined) {
                eventColorByType.value = { ...eventColorByType.value, ...JSON.parse(data.data.data[0].fieldData.Value) }
                if (eventsTypeToShow.value['showLivraison'] == 1) {
                    delete eventColorByType.value.LIVRAISON
                }
                setEvents()
            }
        })
    }
    1

    const openSettings = function () {
        setModal('settingsModal', true, settingsData.value)
    }

    const eventClicked = function (data) {
        let modalData = data.event._def.extendedProps.eventData
        if (data.event._def.extendedProps.type == 'SAV')
            setModal('eventSavModal', true, data)
        else
            setModal('eventModal', true, modalData)
    }

    const calendarClicked = function () {
        return false
        // if (isRoot.value )
        //     setModal('dateModal', true, data)
    }

    const setModal = function (key, value, data) {
        isModalsOpen.value[key] = { value, data }
    }

    const changeShowActifContrat = function () {
        showActifContrat.value = !showActifContrat.value
        verifRessourceListe()
    }

    // const changeRessourceType = function () {
    //     showAllSubFamily.value = !showAllSubFamily.value
    //     verifRessourceListe()
    // }

    const compare = function (a, b) {
        if (a.id < b.id)
            return -1;
        if (a.id > b.id)
            return 1;
        return 0;
    }

    const verifRessourceListe = function () {
        console.log("verifRessourceListe");

        filteredRessouceIds.value = []
        if (selectedSubFamilyToShow.value.length != 0) {
            for (let i = 0; i < selectedSubFamilyToShow.value.length; i++) {
                let selectedInMenu = resourceIds.value.find((val) => val.subFamily == selectedSubFamilyToShow.value[i])
                if (selectedInMenu) {
                    let value = resourceIds.value.filter((val) =>
                        selectedSubFamilyToShow.value[i].includes(val.subFamily))
                    filteredRessouceIds.value = filteredRessouceIds.value.concat(value)
                } else {
                    let manualSearch = resourceIds.value.filter((val) => val.id.includes(selectedSubFamilyToShow.value[i].toUpperCase()))
                    filteredRessouceIds.value = filteredRessouceIds.value.concat(manualSearch)
                }
            }
        } else {
            // if (showAllSubFamily.value) {
            if (selectedView.value == 0) {
                filteredRessouceIds.value = resourceIds.value
            }
            else if (selectedView.value == 1) {
                // else if (showActifContrat.value) {
                let findedMatricule = getAllEvents.value.map((val) => val.matricule)
                findedMatricule = [...new Set(findedMatricule)]
                filteredRessouceIds.value = resourceIds.value.filter((val) => findedMatricule.includes(val.matricule))
            }
            else if (selectedView.value == 2) {
                let findedSubFamily = getAllEvents.value.map((val) => val.subFamily)
                findedSubFamily = [...new Set(findedSubFamily)]
                filteredRessouceIds.value = resourceIds.value.filter((val) => findedSubFamily.includes(val.subFamily))
            }
        }

        filteredRessouceIds.value = filteredRessouceIds.value.filter(elm => elm);
        filteredRessouceIds.value = filteredRessouceIds.value.sort(compare)
        filteredRessouceIds.value = filteredRessouceIds.value.sort((a, b) =>
            (a.INDEX_SFAMILLE == '' ? resourceIds.value.length + 1 : a.INDEX_SFAMILLE) - (b.INDEX_SFAMILLE == '' ? resourceIds.value.length + 1 : b.INDEX_SFAMILLE));
    }

    const updateCalendarView = function (calendarData) {
        let start = moment(calendarData.start).format("x")
        let end = moment(calendarData.end).format("x")
        if (calendarDates.value.start == start && calendarDates.value.end == end) return
        else {
            showAllSubFamily.value = false
        }
        updateCalendarEvents(start, end)
    }

    const updateCalendarEvents = function (start, end) {
        calendarDates.value = { start, end }
        setEvents()
    }

    const setSubFamilyToShow = function (subFamily) {
        selectedSubFamilyToShow.value = subFamily
        verifRessourceListe()
    }

    const setEvents = function () {
        engine.get_events(
            parseFloat(calendarDates.value.start),
            parseFloat(calendarDates.value.end),
            function (data) {
                eventsList.value = []
                if (data.code == 0) {
                    let tmpEventsList = data.data.data.map((val) => val.fieldData)
                    let localEventList = []
                    for (let i = 0; i < tmpEventsList.length; i++) {

                        let state = contratState.value.find((val) => val.state == tmpEventsList[i].CLD_CONTRAT_STATE)
                        let subFamily = tmpEventsList[i].CLD_SOUS_FAMILLE
                        let matricule = tmpEventsList[i].CLD_MATRICULE
                        let title = ""
                        if (tmpEventsList[i].CLD_NOM_CLIENT)
                            title += tmpEventsList[i].CLD_NOM_CLIENT + " - "
                        title += tmpEventsList[i].CLD_CLIENT_ADRESSE + ' ' + tmpEventsList[i].CLD_CLIENT_VILLE + ' ' + tmpEventsList[i].CLD_CLIENT_CODEPOSTAL
                        let id = subFamily + matricule
                        if (eventsTypeToShow.value["showLivraison"] == 0) {
                            localEventList.push({
                                resourceIds: [id.toUpperCase()],
                                // title: 1,
                                start: moment(tmpEventsList[i].CLD_DATE_LIVRAISON_DEPART).format(),
                                end: moment(tmpEventsList[i].CLD_DATE_DEPART_TIMESTAMP).format(),
                                color: eventColorByType.value.LIVRAISON.color,
                                subFamily: subFamily,
                                matricule: matricule,
                                eventData: tmpEventsList[i],
                                type: "LOCATION"
                            })
                            localEventList.push({
                                resourceIds: [id.toUpperCase()],
                                // title: 'RETOUR',
                                start: moment(tmpEventsList[i].CLD_DATE_RETOUR_TIMESTAMP).format(),
                                end: moment(tmpEventsList[i].CLD_DATE_LIVRAISON_RETOUR).format(),
                                color: eventColorByType.value.LIVRAISON.color,
                                subFamily: subFamily,
                                matricule: matricule,
                                eventData: tmpEventsList[i],
                                type: "LOCATION"
                            })
                        }
                        if (eventsTypeToShow.value["showLocation"] == 0) {
                            let color = (state == undefined ? eventColorByType.value.LOCATION.color : state.color)

                            if (tmpEventsList[i].CLD_STATE && eventColorState.value[tmpEventsList[i].CLD_STATE] != undefined) {
                                color = eventColorState.value[tmpEventsList[i].CLD_STATE].color
                            }

                            localEventList.push({
                                resourceIds: [id.toUpperCase()],
                                title: title,
                                start: moment(tmpEventsList[i].CLD_DATE_DEPART_TIMESTAMP).format(),
                                end: moment(tmpEventsList[i].CLD_DATE_RETOUR_TIMESTAMP).format(),
                                // color: eventColorByType.value.LOCATION.color,
                                color: color,
                                subFamily: subFamily,
                                matricule: matricule,
                                eventData: tmpEventsList[i],
                                type: "LOCATION"
                            })
                        }
                    }
                    eventsList.value = localEventList
                }
                loaded.value += 1
                setEventsReserved()
                setEventsSav()
            })
    }

    const setEventsSav = function () {
        engine.get_events_sav(
            parseFloat(calendarDates.value.start),
            parseFloat(calendarDates.value.end),
            function (data) {
                console.log("start",parseFloat(calendarDates.value.start));
                console.log("fin",parseFloat(calendarDates.value.end));                
                console.log("get_events_sav", data);
                
                eventsListSav.value = []
                if (data.code == 0) {
                    let tmpEventsList = data.data.data.map((val) => val.fieldData)
                    let localEventListSav = []
                    for (let i = 0; i < tmpEventsList.length; i++) {
                        let subFamily = tmpEventsList[i].sous_famille
                        let matricule = tmpEventsList[i]['N° MATR INT']
                        // let title = tmpEventsList[i].societeliv + " : " + tmpEventsList[i].villeliv + ' ' + tmpEventsList[i].adliv
                        let title = "SAV"
                        let id = subFamily + matricule
                        localEventListSav.push({
                            resourceIds: [id.toUpperCase()],
                            title: title,
                            start: moment(tmpEventsList[i].Debut_TIMESTAMP).format(),
                            end: moment(tmpEventsList[i].Fin_TIMESTAMP).format(),
                            color: eventColorByType.value.SAV.color,
                            subFamily: subFamily,
                            matricule: matricule,
                            eventData: tmpEventsList[i],
                            type: "SAV"
                        })
                    }
                    eventsListSav.value = localEventListSav
                }
                loaded.value += 1
            })
    }

    const setSelectedView = function (view) {
        selectedView.value = view
        verifRessourceListe()
    }

    const setEventsReserved = function () {
        engine.get_events_reserved(
            parseFloat(calendarDates.value.start),
            parseFloat(calendarDates.value.end),
            function (data) {
                eventsListReserved.value = []
                if (data.code == 0) {
                    let tmpEventsList = data.data.data.map((val) => val.fieldData)
                    let localEventListReserved = []
                    for (let i = 0; i < tmpEventsList.length; i++) {
                        let subFamily = tmpEventsList[i].R_SOUS_FAMILLE
                        let matricule = tmpEventsList[i].R_MATRICULE
                        let title = ""
                        if (tmpEventsList[i].R_NOM_CLIENT)
                            title += tmpEventsList[i].R_NOM_CLIENT + " - "
                        title += tmpEventsList[i].R_CLIENT_ADRESSE + ' ' + tmpEventsList[i].R_CLIENT_VILLE + ' ' + tmpEventsList[i].R_CLIENT_CODEPOSTAL
                        let id = subFamily + matricule
                        if (eventsTypeToShow.value["showLivraison"] == 0) {
                            localEventListReserved.push({
                                resourceIds: [id.toUpperCase()],
                                // title: title,
                                start: moment(tmpEventsList[i].R_DATE_LIVRAISON_DEPART).format(),
                                end: moment(tmpEventsList[i].R_DATE_DEPART_TIMESTAMP).format(),
                                color: eventColorByType.value.LIVRAISON.color,
                                subFamily: subFamily,
                                matricule: matricule,
                                eventData: tmpEventsList[i],
                                type: "RESERVATION"
                            })
                            localEventListReserved.push({
                                resourceIds: [id.toUpperCase()],
                                // title: title,
                                start: moment(tmpEventsList[i].R_DATE_RETOUR_TIMESTAMP).format(),
                                end: moment(tmpEventsList[i].R_DATE_LIVRAISON_RETOUR).format(),
                                color: eventColorByType.value.LIVRAISON.color,
                                subFamily: subFamily,
                                matricule: matricule,
                                eventData: tmpEventsList[i],
                                type: "RESERVATION"
                            })

                        }
                        if (eventsTypeToShow.value["showReservation"] == 0) {
                            let color = eventColorByType.value.RESERVATION.color

                            if (tmpEventsList[i].CLD_STATE && eventColorState.value[tmpEventsList[i].CLD_STATE] != undefined) {
                                color = eventColorState.value[tmpEventsList[i].CLD_STATE].color
                            }

                            localEventListReserved.push({
                                resourceIds: [id.toUpperCase()],
                                title: title,
                                start: moment(tmpEventsList[i].R_DATE_DEPART_TIMESTAMP).format(),
                                end: moment(tmpEventsList[i].R_DATE_RETOUR_TIMESTAMP).format(),
                                color: color,
                                subFamily: subFamily,
                                matricule: matricule,
                                eventData: tmpEventsList[i],
                                type: "RESERVATION"
                            })
                        }
                    }
                    eventsListReserved.value = localEventListReserved
                    verifRessourceListe()
                } else {
                    verifRessourceListe()
                }
                loaded.value += 1
            })
    }

    const updatePlanningVue = function () {
        let newData = {
            selectedNbrDays: selectedNbrDays.value,
            selectedslotduration: selectedslotduration.value,
            selectedStartTime: selectedStartTime.value,
            selectedEndTime: selectedEndTime.value,
        }
        let token = localStorage.getItem("userToken");


        engine.update_planning_vue(newData, 'planning-disponibilite', token, function (data) {
            console.log("update_planning_vue", data);
        })

    }

    const setPlanningVue = function () {
        let data = store.state.user.planning_display

        if (data && data.length > 0) {
            selectedPlanningVue.value = JSON.parse(data)
            if (selectedPlanningVue.value['planning-disponibilite']) {
                selectedNbrDays.value = selectedPlanningVue.value['planning-disponibilite'].selectedNbrDays
                selectedslotduration.value = selectedPlanningVue.value['planning-disponibilite'].selectedslotduration
                selectedStartTime.value = selectedPlanningVue.value['planning-disponibilite'].selectedStartTime
                selectedEndTime.value = selectedPlanningVue.value['planning-disponibilite'].selectedEndTime
            }
        } else {
            selectedPlanningVue.value = {}
        }
    }

    const isRoot = computed(function () {
        return store.state.user.admin == 1 ? true : false
    })

    const isEventsLoaded = computed(function () {
        return getAllEvents.length > 0
    })

    const calendarIsOpening = computed(function () {
        return false
    })

    return {
        viewListe,
        calendarOptions,
        resourceIds,
        setSubFamilyToShow,
        isModalsOpen,
        setModal,
        eventColorByType,
        updateCalendarColors,
        updateCalendarDaysToShow,
        daysToHide,
        isEventsLoaded,
        isMobile,
        calendarIsOpening,
        contratState,
        eventColorState,
        setSelectedView,
        selectedView,
        nbrDaysdurationListe,
        slotdurationListe,
        startPlanningTime,
        endPlanningTime,
        selectedNbrDays,
        selectedslotduration,
        selectedStartTime,
        selectedEndTime,
        updatePlanningVue
        // setNewScreenSize
    };
}