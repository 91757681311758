<template>
    <div class="sav-form" style="height: 100vh; overflow: auto;">
        <v-col>
            <div id="checkListePDF">
                <v-col cols="12">
                    <v-row align=center>
                        <v-col style="font-weight: bold;" class="">
                            <div v-if='societe["RS"]'>
                                {{ societe["RS"] }}
                            </div>
                            <div v-if='societe["RS"] != agency["ag_nom"]'>
                                {{ agency["ag_nom"] }}
                            </div>
                            <div v-if='agency["ag_adresse"]'> {{ agency["ag_adresse"] }} </div>
                            <div v-if='agency["ag_cp"]'> {{ agency["ag_cp"] }} </div>
                            <div v-if='agency["ag_ville"]'> {{ agency["ag_ville"] }} </div>
                            <div v-if='agency["ag_telephone"]'> {{ agency["ag_telephone"] }} </div>
                            <div v-if='agency["ag_courriel"]'> {{ agency["ag_courriel"] }} </div>
                            <div v-if='agency["ag_siteweb"]'> {{ agency["ag_siteweb"] }} </div>
                        </v-col>
                        <v-col cols="auto" align="right" class=""
                            v-if="societe['Logo_Entete1_JSON'] && societe['Logo_Entete1_JSON']['Logo_Entete1_Base64']">
                            <div>
                                <img style="height: 90px; border: solid 1px #FAFAFA !important;"
                                    :src="societe['Logo_Entete1_JSON']['Logo_Entete1_Base64']" />
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col style="color: black;" align="center" cols="12" class="pt-0">
                    <h1 style="text-transform: uppercase;" class="pt-0 mt-0">
                        {{ itemsData['N° RAP COMP'] }}
                    </h1>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12" md="6">
                    <table class="table">
                        <tr>
                            <td class="table-line" style="border-bottom: none;"
                                :style="localForm['client'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                Client
                            </td>
                            <td class="table-line" style="border-bottom: none;border-left:none;"
                                :style="localForm['client'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                <div v-if="localForm['client'] && localForm['client'].SOCIETE">
                                    {{ localForm['client'].SOCIETE }}
                                </div>
                            </td>
                        </tr>
                        <tr v-if="localForm['client']">
                            <td class="table-line" style="border-bottom:none">Adresse</td>
                            <td class="table-line" style="border-left:none;border-bottom:none">
                                {{ localForm['client'].ADRESSE }} {{ localForm['client'].COMP_ADRESSE }} {{
                                    localForm['client'].VILLE }} {{ localForm['client'].CODE_POSTAL }}
                            </td>
                        </tr>
                        <tr v-if="localForm['client']">
                            <td class="table-line" style="border-bottom:none">
                                Contact
                            </td>
                            <td class="table-line" style="border-left:none;border-bottom:none">
                                {{ localForm['client'].TELEPHONE }}
                            </td>
                        </tr>
                        <tr v-if="localForm['client']"
                            :style="itemsData == undefined ? 'border-bottom:solid 1px black' : ''">
                            <td class="table-line" style="border-bottom:none">Adresse d'intervention</td>
                            <td class="table-line" style="border-left:none;border-bottom:none">
                                {{ localForm['client'].ADRESSE_LIV }} {{ localForm['client'].COMP_ADRESSE_LIV
                                }}
                                {{
                                    localForm['client'].VILLE_LIV }} {{ localForm['client'].CODE_POSTAL_LIV }}
                            </td>
                        </tr>
                        <tr v-if="itemsData">
                            <td class="table-line" style=""
                                :style="this.optionsObj.show_LIEU_INTERV && this.optionsObj.show_LIEU_INTERV == 1 ? 'border-bottom:none' : 'border-bottom: solid 1px black;'">
                                {{ this.optionsObj.show_LIEU_INTERV }}
                                Type de travaux effectués </td>
                            <td class="table-line" style="border-left:none"
                                :style="this.optionsObj.show_LIEU_INTERV && this.optionsObj.show_LIEU_INTERV == 1 ? 'border-bottom:none' : 'border-bottom: solid 1px black;'">
                                {{ itemsData['type_travaux'] }}
                            </td>
                        </tr>
                        <tr v-if="this.optionsObj.show_LIEU_INTERV == 1">
                            <td class="table-line">Lieu d'intervention</td>
                            <td class="table-line" style="border-left:none;">
                                <div>
                                    {{ localForm['LIEU_INTERV'] }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12">
                    <h3>
                        Information materiel
                    </h3>
                    <table class="table">
                        <tr>
                            <td class="table-line" style="border-bottom: none;"
                                :style="localForm['materiel'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                Matricule
                            </td>
                            <td class="table-line" style="border-bottom: none;border-left:none;"
                                :style="localForm['materiel'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                <div>
                                    <div
                                        v-if="localForm['materielUnknow'] == false || localForm['materielUnknow'] == undefined">
                                        <div v-if="localForm['materiel']">
                                            {{ localForm['materiel'].MATRICULE }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ localForm['materielMatricul'] }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="(elem, idx) in materielElemToShow" :key="elem.key">
                            <td class="table-line" style="border-bottom: none;"
                                :style="(idx + 1 == materielElemToShow.length) && this.optionsObj.show_Horametre != 1 ? 'border-bottom: solid 1px black' : 'border-bottom:none'">
                                {{ elem.label }}
                            </td>
                            <td v-if="localForm['materiel']" class="table-line" style="border-left: none;"
                                :style="(idx + 1 == materielElemToShow.length) && this.optionsObj.show_Horametre != 1 ? 'border-bottom: solid 1px black' : 'border-bottom:none'">
                                {{ localForm['materiel'][elem.key] }}
                            </td>
                        </tr>
                        <tr v-if="isRetour && this.optionsObj.show_Horametre == 1">
                            <td class="table-line" style="border-bottom: none;">
                                Compteur horaire de sortie
                            </td>
                            <td v-if="itemsData" class="table-line" style="border-bottom: none;border-left: none;">
                                {{ itemsData['old_Horametre'] }}
                            </td>
                        </tr>
                        <tr v-if="this.optionsObj.show_Horametre == 1">
                            <td class="table-line" style="">
                                {{ isRetour ? 'Compteur horaire de retour' : 'Compteur horaire'
                                }}
                            </td>
                            <td class="table-line" style="border-left: none;">
                                <div>
                                    {{ localForm['Horametre'] }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col>
                    <h3>
                        Liste des vérifications
                    </h3>
                    <table class="table" id="checkListePDF_hide" style="width: 100%;">
                        <tr>
                            <td class="table-line">
                                <Vue3EasyDataTable hide-footer :headers="headers2.slice(0, 2)"
                                    :items="localForm['Check_liste']" header-text-direction="center"
                                    body-text-direction="center" @click-row="clickRow"
                                    empty-message="Aucun accesoire ajouté">

                                    <template #item-CH_DESIGNATION="item">
                                        <div style="font-weight: bold; font-size: 15px;" align="left">
                                            {{ item.CH_DESIGNATION }}
                                        </div>
                                    </template>

                                    <template #item-CH_ETAT="item">
                                        <div class="pa-2" style="background-color: rgba(76, 175, 80,0.5);"
                                            id="cardEdition">
                                            <v-card variant="flat" color="white">
                                                <v-text-field
                                                    v-model="localForm['Check_liste'].find((val) => val.CH_DESIGNATION == item.CH_DESIGNATION).CH_ETAT"
                                                    hide-details density="compact">
                                                </v-text-field>
                                            </v-card>
                                        </div>
                                    </template>

                                    <template #item-CH_OLD_ETAT="item">
                                        <div variant="flat" color="white" height="100%">
                                            {{localForm['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                                item.CH_DESIGNATION).CH_OLD_ETAT}}
                                        </div>
                                    </template>
                                </Vue3EasyDataTable>
                            </td>
                        </tr>
                    </table>
                    <div style="display: none;" id="checkListePDF_show">
                        <table class="table">
                            <tr>
                                <td v-for="(elem, idx) in headers2.slice(0, 2)" :key="idx" class="table-line"
                                    :style="idx != 0 ? 'border-left:none;' : ''">
                                    {{ elem.text }}
                                </td>
                            </tr>
                            <tr v-for="(item, idx) in localForm['Check_liste']" :key="idx">
                                <td class="table-line" style="border-top: none;">
                                    {{ item.CH_DESIGNATION }}
                                </td>
                                <td class="table-line" style="border-left:none;border-top: none;">
                                    {{localForm['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                        item.CH_DESIGNATION).CH_ETAT
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </v-col>
                <div>
                    <v-divider class="mt-6" color="lightgrey"></v-divider>
                    <v-col style="padding-bottom: 20px;font-weight:bold;position:relative" align="center">
                        {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au capital
                        de {{ societe["Capital"] }} € - SIRET
                        {{ societe["NumeroSIRET"] }} - APE : {{ societe["CodeAPE"] }} -
                        TVA : {{ societe["TVAIntracommunautaire"] }} <br />
                        {{ agency["ag_nom"] }} - {{ agency["ag_adresse"] }}
                        {{ agency["ag_cp"] }} {{ agency["ag_ville"] }} - Tél :
                        {{ agency["ag_telephone"] }}
                    </v-col>
                </div>
            </div>
        </v-col>
        <v-col align="center">
            <v-btn @click="save_html_into_pdf()" style="background-color: green;color: white;">
                Ouvrir en PDF
            </v-btn>
        </v-col>

    </div>
</template>

<script>
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Browser } from "@capacitor/browser";
import { v4 as uuidv4 } from "uuid";
import engine from '../core/DataApi'

export default {

    data() {
        return {
            isPrinting: false,
            localListeShortData: undefined,
            localForm: undefined,
        }
    },
    components: {
        Vue3EasyDataTable
    },
    methods: {
        async getcanvaFromDiv(divData) {
            return new Promise((resolve, reject) => {
                let html2canvasOptions = {
                    allowTaint: true,
                    removeContainer: true,
                    backgroundColor: null,
                    imageTimeout: 15000,
                    logging: true,
                    scale: 2,
                    useCORS: true,
                    processData: false,
                    contentType: false,
                    windowWidth: 1600,
                };

                // Appel à html2canvas
                html2canvas(divData, html2canvasOptions).then((canvas) => {
                    // Une fois le canvas prêt, on récupère l'image
                    let imgData = canvas.toDataURL("image/png");
                    resolve(imgData); // Résolution de la promesse avec l'image
                }).catch((error) => {
                    reject(error); // Si une erreur se produit, la promesse est rejetée
                });
            });
        },

        addCanvaToDoc(imgData, doc, size, formSize) {
            let imgWidth = 204;
            let imgHeight = 290;
            let position = 3;

            console.log("size", size);


            if (formSize) {
                size = 60
                size += (10 * formSize.length)
            }

            // size = size / 1.8



            if (size < 280)
                imgHeight = size

            doc.addImage(
                imgData,
                "PNG",
                3,
                position,
                imgWidth,
                imgHeight,
                undefined,
                "FAST"
            );
        },
        async save_html_into_pdf() {
            console.log("save_html_into_pdf");

            window.scrollTo(0, 0);
            let that = this;
            that.isPrinting = true;

            var elms = document.querySelectorAll("#checkListePDF_hide");
            var elms2 = document.querySelectorAll("#checkListePDF_show");

            for (let i = 0; i < elms.length; i++) elms[i].style.display = "none";
            for (let i = 0; i < elms2.length; i++) elms2[i].style.display = "";


            const elements = document.querySelectorAll("#checkListePDF");


            try {
                const [imgData1] = await Promise.all([
                    that.getcanvaFromDiv(elements[0]),
                ]);

                let doc = new jsPDF("p", "mm", "a4");
                this.addCanvaToDoc(imgData1, doc, elements[0].offsetHeight, undefined)

                that.isPrinting = false
                that.pdfLink = doc.output("datauristring");

                for (let i = 0; i < elms.length; i++) { elms[i].style.display = ""; }
                for (let i = 0; i < elms2.length; i++) elms2[i].style.display = "none";

                let id = uuidv4();
                engine.creat_tmp_pdf(id, doc.output(), function (data) {
                    if (data.code === 0) {
                        engine.openPdf(id, function (urlPdf) {
                            Browser.open({ url: urlPdf });
                        });
                    }
                });
            } catch (error) {
                console.error('Erreur lors de la capture des images:', error);
            }
        }
    },
    mounted() {
        this.localListeShortData = this.listeShortData
        this.localForm = this.form
    },
    props: {
        agency: {},
        societe: {},
        headers2: {},
        materielElemToShow: {},
        optionsObj: {},
        isRetour: {},
        itemsData: {},
        listeShortData: {},
        form: {}
    }
}
</script>