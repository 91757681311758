<template>
    <div v-if="error.show" style="position: fixed;bottom: 0px; z-index: 5;width:100vw;background-color: red"
        align=center>
        <v-card :color="error.color" class="pa-2">
            <div style="font-weight: bold;" align="center">
                {{ error.message }}
            </div>
        </v-card>
    </div>
    <v-dialog v-model="closeVerification.model" width="99%" max-width="250px">
        <v-card>
            <v-col class="pa-1">
                <v-col align="center" class="pb-1" style="font-weight: bold;">
                    Valider mon action ?
                </v-col>
                <v-col>
                    <v-row justify="center" align="center">
                        <v-col align="center">
                            <v-btn @click="closeVerification.model = false" variant="flat" color="red">
                                Non
                            </v-btn>
                        </v-col>
                        <v-col align="center">
                            <v-btn @click="closeVerification.callback" variant="flat" color="green">
                                Oui
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isAskSignatureOpen" width="100%" max-width="600">
        <v-col>
            <v-card class="pa-2" align=center>
                <v-row>
                    <v-col align="left">
                        <v-card-title>
                            Envoyer : {{ itemsData['N° RAP COMP'] }} par mail
                        </v-card-title>
                    </v-col>
                    <v-col align="right">
                        <v-btn @click="isAskSignatureOpen = false" color="red" density="compact" icon="mdi-close"
                            variant="text" class="ma-2">
                        </v-btn>
                    </v-col>
                </v-row>
                <v-col align="center">
                    <v-text-field dense hide-details variant="outlined" :label="'Email destinataire'"
                        v-model="email_pdf">
                    </v-text-field>
                    <v-col align=center>
                        <v-btn @click="addEmailToListe">
                            Ajouter
                        </v-btn>
                    </v-col>
                    <v-col>
                        <h3 align=center class="mb-2">
                            Liste des emails de destinataire ({{ emailSignatureListe.length }})
                        </h3>
                        <v-row>
                            <v-col v-for="(email, idx) in emailSignatureListe" :key="idx" cols="auto" class="pa-1">
                                <v-chip @click="deleteElemFromListeByIndex(emailSignatureListe, idx)">
                                    {{ email }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-menu open-on-hover open-delay="0" close-delay="0" v-if="emailList && emailList.length != 0">
                        <template v-slot:activator="{ props }">
                            <v-btn color="primary" v-bind="props" class="mt-2" density="compact" variant="tonal">
                                Email prédéfini
                            </v-btn>
                        </template>
<v-list>
    <v-list-item v-for="(item, index) in emailList" :key="index" :value="index">
        <v-list-item-title @click="email_pdf = item">{{ item }}</v-list-item-title>
    </v-list-item>
</v-list>
</v-menu> -->
                </v-col>
                <v-btn :disabled="emailSignatureListe.length == 0" color="#5C6BC0" @click="send_pdf()" class="mt-2"
                    style="color:white">
                    Envoyer
                </v-btn>
            </v-card>
        </v-col>
    </v-dialog>
    <v-dialog v-model="isSignatureOpen" width="600px">
        <v-card tile>
            <signature-pad id="signature" ref="signaturePad" width="350px" :options="{
                onBegin: () => {
                    $refs['signaturePad'].resizeCanvas();
                },
            }" />
            <v-card color="#eeeeee" tile>
                <v-col align="right">
                    <v-btn text elevation="0" color="green" variant="tonal" class="mx-2"
                        @click="(isSignatureOpen = false), save('signaturePad')">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn color="blue" class="mx-2" variant="tonal" @click="undo('signaturePad')" align="right">
                        <v-icon>mdi-undo</v-icon>
                    </v-btn>
                    <v-btn color="red" variant="tonal" class="mx-2" @click="clear('signaturePad')">
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </v-col>
            </v-card>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isPhotoViewerOpen" max-width="2000px" width="90%">
        <v-card color="rgb(0,0,0,0.2)">
            <div v-if="form.photos && form.photos.length !== 0">
                <v-carousel height="90vh" hide-delimiters v-model="carouselIndex">
                    <v-carousel-item v-for="(item, i) in form.photos" :key="i" :src="item">
                        <v-sheet color="transparent" height="100%" align="right">
                            <v-row>
                                <v-col cols="" align="right" class="">
                                    <v-btn fab small class="ma-2" style="float: right" color="rgba(76, 175, 80,0.5)">
                                        <v-icon :size="40" @click="downloadPic(item)" color="white">
                                            mdi-download
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col align="right" cols="auto" class="px-0">
                                    <v-btn :disabled="!isEditable" class="ma-2" color="red" variant="text" @click="function () {
                                        deleteElemFromListeByIndex(form.photos, i)
                                        carouselIndex = carouselIndex - 1
                                    }
                                    ">
                                        <v-icon :size="35" color="red"> mdi-delete </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" align="right" class="pl-0">
                                    <v-btn fab variant="text" small class="ma-2" style="float: right">
                                        <v-icon :size="40" color="red" @click="isPhotoViewerOpen = false">
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div v-else>
                <v-card>
                    <v-col>
                        Aucune photo enregistrée </v-col>
                </v-card>
            </div>
        </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="showOnlyCheckListe">
        <div style="background-color: white;">
            <div style="position: absolute; right: 10px; top: 10px;">
                <v-btn icon="mdi-close-circle" variant="text" color="rgba(255, 61, 0,0.5)" style="font-size: 25px;"
                        @click="showOnlyCheckListe = false">
                    </v-btn>
            </div>
            <modalOnlyListe :agency="agency" :societe="societe" :headers2="headers2"
                :materielElemToShow="materielElemToShow" :optionsObj="optionsObj" :isRetour="isRetour"
                :itemsData="itemsData" :listeShortData="listeShortData" :form="form"></modalOnlyListe>
        </div>
    </v-dialog>
    <v-col v-if="isEditable == false" cols="auto" align=center
        style="position: fixed;bottom: 0px;right: 10px; z-index: 10;">
        <v-card color="red" style="color:black;font-weight: bold;" class="pa-1 px-3">
            Cet OR est signé et cloturé il n'est donc plus modifiable.
        </v-card>
    </v-col>
    <div>

    </div>
    <div class="sav-form" id="captureMaterielSav" style="padding-bottom: 35px;">
        <div id="showInPrint" style="display:none">
            <v-col cols="12">
                <v-row align=center>
                    <v-col style="font-weight: bold;" class="">
                        <div v-if='societe["RS"]'>
                            {{ societe["RS"] }}
                        </div>
                        <div v-if='societe["RS"] != agency["ag_nom"]'>
                            {{ agency["ag_nom"] }}
                        </div>
                        <div v-if='agency["ag_adresse"]'> {{ agency["ag_adresse"] }} </div>
                        <div v-if='agency["ag_cp"]'> {{ agency["ag_cp"] }} </div>
                        <div v-if='agency["ag_ville"]'> {{ agency["ag_ville"] }} </div>
                        <div v-if='agency["ag_telephone"]'> {{ agency["ag_telephone"] }} </div>
                        <div v-if='agency["ag_courriel"]'> {{ agency["ag_courriel"] }} </div>
                        <div v-if='agency["ag_siteweb"]'> {{ agency["ag_siteweb"] }} </div>
                    </v-col>
                    <v-col cols="auto" align="right" class=""
                        v-if="societe['Logo_Entete1_JSON'] && societe['Logo_Entete1_JSON']['Logo_Entete1_Base64']">
                        <div>
                            <img style="height: 90px; border: solid 1px #FAFAFA !important;"
                                :src="societe['Logo_Entete1_JSON']['Logo_Entete1_Base64']" />
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </div>
        <v-col v-if="isPrinting == false" style="padding-bottom: 0px;">
            <v-row>
                <div id="hideInPrint" style="position:absolute;right:0px;top:0px">
                    <v-btn icon="mdi-close-circle" variant="text" color="rgba(255, 61, 0,0.5)" style="font-size: 25px;"
                        @click="closeModal()">
                    </v-btn>
                </div>
                <v-col id="hideInPrint" class="px-0 mt-2">
                    <v-row class="">
                        <v-col cols="auto">
                            <v-card class="pa-4 ml-3 mb-2" flat color="rgba(76, 175, 80,0.5)" align="center"
                                style="text-transform: uppercase; font-weight: bold;">
                                Champ modifiable
                            </v-card>
                            <v-card class="pa-4 ml-3 mb-2" flat color="rgba(255, 61, 0,0.5)"
                                style="text-transform: uppercase; font-weight: bold;">
                                Champ obligatoire
                            </v-card>
                        </v-col>
                        <v-col cols="" align="right" class="" v-if="societe && societe['Logo_Entete1_JSON']">
                            <div>
                                <img style="height:90px; border: solid 1px #FAFAFA !important;"
                                    :src="societe['Logo_Entete1_JSON']['Logo_Entete1_Base64']" />
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col style="color: black;" align="center" cols="12" class="pt-0">
                    <h1 style="text-transform: uppercase;" class="pt-0 mt-0">
                        {{ itemsData['N° RAP COMP'] }}
                    </h1>
                </v-col>
                <v-divider></v-divider>
                <v-col v-if="optionsObj.use_search_filter != false" cols="12" class="pa-0" id="hideInPrint">
                    <table class="table" style="border-bottom:none">
                        <tr>
                            <td class="table-line" style="border-bottom:none">
                                <v-card class="pa-2" align=center id="hideInPrint" variant="flat">
                                    <v-text-field hide-details label="Rechercher un client" variant="outlined"
                                        v-model="listeShortData['clientValue']"></v-text-field>
                                    <div v-if="listeShortData['client']" align="left" class="pa-2 pb-0"
                                        style="font-size:12px">
                                        Liste de client trouvé {{ listeShortData['client'].length }}
                                    </div>
                                    <v-btn class="my-2" variant="tonal"
                                        @click="getClientListeByName(listeShortData['clientValue'])">Rechercher</v-btn>
                                </v-card>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="6">
                            <table class="table">
                                <tr>
                                    <td class="table-line" style="border-bottom: none;"
                                        :style="form['client'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                        Client
                                    </td>
                                    <td class="table-line" style="border-bottom: none;border-left:none;"
                                        :style="form['client'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                        <v-card id="hideInPrint" class="" variant="flat" color="rgba(255, 61, 0,0.5)">
                                            <v-autocomplete v-model="form['client']" class="edition" style=""
                                                :items="optionsObj.use_search_filter ? listeShortData['client'] : clientListe"
                                                :readonly="!isEditable" item-title="SOCIETE" return-object
                                                label="Client" variant="underlined" hide-details
                                                no-data-text="Modifier la recherche de client pour plus de proposition">
                                            </v-autocomplete>
                                        </v-card>
                                        <div id="showInPrint" style="display:none"
                                            v-if="form['client'] && form['client'].SOCIETE">
                                            {{ form['client'].SOCIETE }}
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="form['client']">
                                    <td class="table-line" style="border-bottom:none">Adresse</td>
                                    <td class="table-line" style="border-left:none;border-bottom:none">
                                        {{ form['client'].ADRESSE_LIV }} {{ form['client'].COMP_ADRESSE_LIV }} {{
                                            form['client'].VILLE_LIV }} <span v-if="form['client'].CODE_POSTAL_LIV">{{ form['client'].CODE_POSTAL_LIV }}</span>
                                    </td>
                                </tr>
                                <tr v-if="form['client']">
                                    <td class="table-line" style="border-bottom:none">
                                        Contact
                                    </td>
                                    <td class="table-line" style="border-left:none;border-bottom:none">
                                        {{ form['client'].TELEPHONE_LIV }}
                                    </td>
                                </tr>
                                <tr v-if="form['client']"
                                    :style="itemsData == undefined ? 'border-bottom:solid 1px black' : ''">
                                    <td class="table-line" style="border-bottom:none">Adresse d'intervention</td>
                                    <td class="table-line" style="border-left:none;border-bottom:none">
                                        {{ form['client'].ADRESSE_LIV }} {{ form['client'].COMP_ADRESSE_LIV
                                        }}
                                        {{
                                            form['client'].VILLE_LIV }} <span v-if="form['client'].CODE_POSTAL_LIV">{{ form['client'].CODE_POSTAL_LIV }}</span>
                                    </td>
                                </tr>
                                <tr v-if="itemsData">
                                    <td class="table-line" style=""
                                        :style="this.optionsObj.show_LIEU_INTERV && this.optionsObj.show_LIEU_INTERV == 1 ? 'border-bottom:none' : 'border-bottom: solid 1px black;'">
                                        {{ this.optionsObj.show_LIEU_INTERV }}
                                        Type de travaux effectués </td>
                                    <td class="table-line" style="border-left:none"
                                        :style="this.optionsObj.show_LIEU_INTERV && this.optionsObj.show_LIEU_INTERV == 1 ? 'border-bottom:none' : 'border-bottom: solid 1px black;'">
                                        {{ itemsData['type_travaux'] }}
                                    </td>
                                </tr>
                                <tr v-if="this.optionsObj.show_LIEU_INTERV == 1">
                                    <td class="table-line">Lieu d'intervention</td>
                                    <td class="table-line" style="border-left:none;">
                                        <v-card id="hideInPrint" variant="flat" :color="getColorByKey('LIEU_INTERV')"
                                            class="">
                                            <v-text-field v-model="form['LIEU_INTERV']" label="Lieu d'intervention"
                                                :readonly="!isEditable" class="edition" style="" variant="underlined"
                                                hide-details></v-text-field>
                                        </v-card>
                                        <div id="showInPrint" style="display:none">
                                            {{ form['LIEU_INTERV'] }}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                        <v-col cols="12" md="6">
                            <table class="table">
                                <tr>
                                    <td class="table-line" style="border-bottom: none;">Date du rapport</td>
                                    <td class="table-line" style="border-bottom: none;border-left:none;">
                                        <v-card variant="flat" id="hideInPrint" :color="getColorByKey('DATE RAPPORT')"
                                            class="">
                                            <v-text-field type="date" label="Choisir une date" class="edition"
                                                v-model="form['DATE RAPPORT']" hide-details variant="underlined"
                                                :readonly="!isEditable">
                                            </v-text-field>
                                        </v-card>
                                        <div id="showInPrint" style="display:none">
                                            {{ formatDate(form['DATE RAPPORT'], 'DD/MM/YYYY') }}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="table-line" style="border-bottom: none;">Technicien principal</td>
                                    <td class="table-line" style="border-bottom: none;border-left:none;">
                                        <v-card variant="flat" id="hideInPrint" color="rgba(255, 61, 0,0.5)" class="">
                                            <v-select label="Nom du technicien" class="edition"
                                                v-model="form['technicien']" :items="technicienListe" return-object
                                                :item-title="'NOM_TECHNICIEN'" hide-details variant="underlined"
                                                :readonly="!isEditable">
                                            </v-select>
                                        </v-card>
                                        <div id="showInPrint" style="display:none">
                                            {{ form['technicien'] }}
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td class="table-line" style="">Agence</td>
                                    <td class="table-line" style="border-left:none;">
                                        <div id="hideInPrint">
                                            <v-card v-if="isCReation" variant="flat" color="rgba(255, 61, 0,0.5)"
                                                class="pa-2">
                                                <v-select label="Agence" class="edition" v-model="agency"
                                                    :items="agenceListe" return-object :item-title="'ag_nom'"
                                                    hide-details variant="underlined" :disabled="!isCReation">
                                                </v-select>
                                            </v-card>
                                            <div v-else>
                                                {{ agency.ag_nom }}
                                            </div>
                                        </div>
                                        <div id="showInPrint" style="display:none">
                                            {{ agency.ag_nom }}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                        <v-col cols="12">
                            <h3>
                                Information materiel
                            </h3>
                            <v-card v-if="form['materielUnknow'] != true && optionsObj.use_search_filter != false"
                                class="mb-2 pa-1" align=center id="hideInPrint">
                                <v-text-field hide-details label="Rechercher un matricule"
                                    v-model="listeShortData['matriculeValue']"></v-text-field>
                                <div v-if="listeShortData['matricule']" align="left" class="pa-2 pb-0"
                                    style="font-size:12px">
                                    Liste de matricule trouvé {{ listeShortData['matricule'].length }}
                                </div>
                                <v-btn class="my-2" variant="tonal"
                                    @click="getMatriculListeByName(listeShortData['matriculeValue'])">Rechercher</v-btn>
                            </v-card>
                            <table class="table">
                                <tr>
                                    <td class="table-line" style="border-bottom: none;"
                                        :style="form['materiel'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                        Matricule
                                        <div id="hideInPrint">
                                            <v-checkbox class="" v-model="form['materielUnknow']"
                                                @update:modelValue="resetMatricul" label="Matricule inconnue"
                                                hide-details></v-checkbox>
                                        </div>
                                    </td>
                                    <td class="table-line" style="border-bottom: none;border-left:none;"
                                        :style="form['materiel'] == undefined ? 'border-bottom:solid 1px black' : ''">
                                        <div id="hideInPrint">
                                            <v-card
                                                v-if="form['materielUnknow'] == false || form['materielUnknow'] == undefined"
                                                variant="flat" color="rgba(255, 61, 0,0.5)" class="" id="cardEdition">
                                                <v-autocomplete class="edition" v-model="form['materiel']" return-object
                                                    item-title="MATRICULE" :readonly="!isEditable"
                                                    :items="listeShortData['matricule'] ? listeShortData['matricule'] : parcListe"
                                                    hide-details label="Matricule" variant="underlined"
                                                    no-data-text="Modifier la recherche de matricule pour plus de proposition">
                                                </v-autocomplete>
                                            </v-card>
                                            <v-card v-else variant="flat" :color="getColorByKey('DATE RAPPORT')"
                                                class="pa-2" id="cardEdition">
                                                <v-text-field class="edition" v-model="form['materielMatricul']"
                                                    return-object :readonly="!isEditable" hide-details label="Matricule"
                                                    variant="underlined">
                                                </v-text-field>
                                            </v-card>
                                        </div>
                                        <div id="showInPrint" style="display:none">
                                            <div
                                                v-if="form['materielUnknow'] == false || form['materielUnknow'] == undefined">
                                                <div v-if="form['materiel']">
                                                    {{ form['materiel'].MATRICULE }}
                                                </div>
                                            </div>
                                            <div v-else>
                                                {{ form['materielMatricul'] }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="(elem, idx) in materielElemToShow" :key="elem.key">
                                    <td class="table-line" style="border-bottom: none;"
                                        :style="(idx + 1 == materielElemToShow.length) && this.optionsObj.show_Horametre != 1 ? 'border-bottom: solid 1px black' : 'border-bottom:none'">
                                        {{ elem.label }}
                                    </td>
                                    <td v-if="form['materiel']" class="table-line" style="border-left: none;"
                                        :style="(idx + 1 == materielElemToShow.length) && this.optionsObj.show_Horametre != 1 ? 'border-bottom: solid 1px black' : 'border-bottom:none'">
                                        {{ form['materiel'][elem.key] }}
                                    </td>
                                </tr>
                                <tr v-if="isRetour && this.optionsObj.show_Horametre == 1">
                                    <td class="table-line" style="border-bottom: none;">
                                        Compteur horaire de sortie
                                    </td>
                                    <td v-if="itemsData" class="table-line"
                                        style="border-bottom: none;border-left: none;">
                                        {{ itemsData['old_Horametre'] }}
                                    </td>
                                </tr>
                                <tr v-if="this.optionsObj.show_Horametre == 1">
                                    <td class="table-line" style="">
                                        {{ isRetour ? 'Compteur horaire de retour' : 'Compteur horaire'
                                        }}
                                    </td>
                                    <td class="table-line" style="border-left: none;">
                                        <v-card id="hideInPrint" variant="flat" :color="getColorByKey('Horametre')"
                                            class="">
                                            <v-text-field class="edition" v-model="form['Horametre']" hide-details
                                                :label="isRetour ? 'Compteur horaire de retour' : 'Compteur horaire'"
                                                variant="underlined" :readonly="!isEditable">
                                            </v-text-field>
                                        </v-card>
                                        <div id="showInPrint" style="display:none">
                                            {{ form['Horametre'] }}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-col cols="12" v-if="getComplementInfo && getComplementInfo.length >= 1" class="mt-6">
                        <v-row>
                            <v-col v-for="elem in getComplementInfo" :key="elem.key" cols="12" class="pa-0">
                                <div class="table table-line">
                                    <div id="hideInPrint">
                                        <v-card id="hideInPrint" variant="flat" :color="getColorByKey(elem.key)"
                                            class="">
                                            <v-text-field class="edition" variant="underlined" :label="elem.label"
                                                :readonly="elem.disabled" v-model="form[elem.key]" hide-details
                                                :type="elem.type" />
                                        </v-card>
                                    </div>
                                    <div id="showInPrint" style="display:none">
                                        {{ elem.label }} : {{ form[elem.key] }}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-col>
                <v-col cols="12"
                    v-if="optionsObj.key_to_hide == undefined || optionsObj.key_to_hide == '' || (optionsObj.key_to_hide && !optionsObj.key_to_hide.split(',').includes('type_travaux'))">
                    <div class="">
                        <table class="table">
                            <tr>
                                <td class="table-line">
                                    <v-card id="hideInPrint" variant="flat" :color="getColorByKey('type_travaux')"
                                        class="">
                                        <v-select v-model="form['type_travaux']"
                                            v-if="optionsObj && optionsObj.inter_type"
                                            :items="optionsObj.inter_type.split(',')" label="Type de travaux"
                                            :readonly="!isEditable" class="edition" variant="underlined"
                                            hide-details></v-select>
                                        <v-text-field v-else v-model="form['type_travaux']" label="Type de travaux"
                                            :readonly="!isEditable" class="edition" variant="underlined"
                                            hide-details></v-text-field>
                                    </v-card>
                                    <div id="showInPrint" style="display:none">
                                        Type de travaux : {{ form['type_travaux'] }}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <!-- <v-card variant="flat" :color="getColorByKey('type_travaux')" class="" id="">
                            <v-select v-model="form['type_travaux']" v-if="optionsObj && optionsObj.inter_type"
                                :items="optionsObj.inter_type.split(',')" label="Type de travaux"
                                :readonly="!isEditable" class="edition" variant="underlined" hide-details></v-select>
                            <v-text-field v-else v-model="form['type_travaux']" label="Type de travaux"
                                :readonly="!isEditable" class="edition" variant="underlined"
                                hide-details></v-text-field>
                        </v-card> -->
                    </div>
                </v-col>
                <v-col cols="12">
                    <table class="table">
                        <tr>
                            <td class="table-line" style="">
                                <v-card id="hideInPrint" variant="flat" :color="getColorByKey('themeDevis')" class="">
                                    <v-textarea v-model="form['themeDevis']" label="Travaux demandés : "
                                        :readonly="!isEditable" class="edition" rows="2" auto-grow variant="underlined"
                                        hide-details></v-textarea>
                                </v-card>
                                <div id="showInPrint" style="display:none">
                                    Travaux demandés : {{ form['themeDevis'] }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>

                <v-col cols="12" id="hideInPrint">
                    <h3
                        v-if="form['Message_Interne'] && form['Message_Interne'].split(',').filter((val) => val != '' && val != '\r').length > 3">
                        Information de préparation (interne)
                    </h3>
                    <table
                        v-if="form['Message_Interne'] && form['Message_Interne'].split(',').filter((val) => val != '' && val != '\r').length > 3"
                        class="table">
                        <tr v-for="(elem, idx) in form['Message_Interne'].split(',').filter((val) => val != '' && val != '\r')"
                            :key="idx">
                            <td class="table-line" style="">
                                {{ elem.split(':')[0] }}
                            </td>
                            <td class="table-line" style="">
                                {{ elem.split(':')[1] }}
                            </td>
                        </tr>
                    </table>
                    <table v-else style="width:100%">
                        <tr>
                            <td class="table-line" style="">
                                <v-card variant="flat" id="cardEdition" :color="getColorByKey('travauxExe')" class="">
                                    <v-textarea v-model="form['Message_Interne']"
                                        label="Information de préparation (interne)..." :readonly="!isEditable"
                                        class="edition" variant="underlined" hide-details></v-textarea>
                                </v-card>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" v-if="form['equipement'] && form['equipement'].equipement.length != 0">
                    <h3>
                        Equipement(s)
                    </h3>
                    <v-col id="hideInPrint" class=" px-0 pt-0" v-if="parcListe && parcListe.length > 0 && isEditable">
                        <table class="table">
                            <tr>
                                <td class="table-line">
                                    <v-text-field label="Rechercher un équipement (3 lettres minimum)" clearable
                                        class="edition" variant="outlined" v-model="equipementSearch" hide-details>
                                    </v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-line" v-if="equipementSearch && equipementSearch.length > 2">
                                    <v-col>
                                        <v-row>
                                            <v-col cols="12">
                                                Liste des équipements <span
                                                    style="color:red;font-weight: bold">valides</span> :
                                            </v-col>
                                            <v-col class="pa-1" cols="auto"
                                                v-for="(elem, idx) in parcListe.filter((val) => val.MATRICULE.toUpperCase().includes(equipementSearch.toUpperCase()))"
                                                :key="idx">
                                                <v-chip>
                                                    {{ elem.MATRICULE }}
                                                </v-chip>
                                            </v-col>
                                            <v-col
                                                v-if="parcListe.filter((val) => val.MATRICULE.toUpperCase().includes(equipementSearch.toUpperCase())).length == 0"
                                                align="center">
                                                Aucun équipement ne correspond à votre recherche
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </td>
                            </tr>
                        </table>
                    </v-col>
                    <table class="table">
                        <tr v-for="(equi, idx) in form['equipement'].equipement" :key="idx">
                            <td class="table-line" style="width: 50%;border-top: none;"
                                :style="idx == 0 ? 'border-top:solid 1px' : ''">
                                {{ equi.description }}
                            </td>
                            <td class="table-line" style="width: 50%;border-left: none;border-top: none;"
                                :style="idx == 0 ? 'border-top:solid 1px' : ''">
                                <v-chip id="hideInPrint"
                                    v-if="parcListe.find((val) => val.MATRICULE == equi.matricule) == undefined"
                                    color="red" style="font-weight:bold" class="mb-2">
                                    Équipement inconnu
                                </v-chip>
                                <v-card id="hideInPrint" variant="flat" :color="getColorByKey('equipement')" class="">
                                    <v-text-field class="edition" :readonly="!isEditable" v-model="equi.matricule"
                                        hide-details>
                                    </v-text-field>
                                </v-card>
                                <div id="showInPrint" style="display:none">
                                    {{ equi.matricule }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" v-if="optionsObj.show_facturable == 1">
                    <table class="table">
                        <tr>
                            <td class="table-line" style="">
                                <v-card id="hideInPrint" variant="flat" :color="getColorByKey('facturable')" class="">
                                    <v-col class="pb-0" style="color:#4E654F">
                                        Facturable :
                                    </v-col>
                                    <v-item-group selected-class="bg-primary" v-model="form['facturable']"
                                        :disabled="!isEditable">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="6" class="pa-2">
                                                    <v-item v-slot="{ isSelected, toggle }" value="Oui">
                                                        <v-card variant="flat" :color="isSelected ? 'green' : 'white'"
                                                            dark class="pa-2" align="center" @click="toggle">
                                                            <div>
                                                                Oui
                                                            </div>
                                                        </v-card>
                                                    </v-item>
                                                </v-col>
                                                <v-col cols="6" class="pa-2">
                                                    <v-item v-slot="{ isSelected, toggle }" value="Non">
                                                        <v-card variant="flat" :color="isSelected ? 'red' : 'white'"
                                                            dark @click="toggle" class="pa-2" align="center">
                                                            <div>
                                                                Non
                                                            </div>
                                                        </v-card>
                                                    </v-item>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-item-group>
                                </v-card>
                                <div id="showInPrint" style="display:none">
                                    Facturable : {{ form['facturable'] }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" class="">
                    <h3>
                        Gestion du stock
                    </h3>
                    <table class="table mb-3" v-if="isEditable" id="hideInPrint">
                        <tr>
                            <td class="table-line" align="center">
                                <div v-if="!form['isSelectedAccessoirePerso']">
                                    <v-text-field hide-details label="Rechercher un article"
                                        v-model="listeShortData['articleValue']" variant="outlined"></v-text-field>
                                    <div v-if="listeShortData['article']" align="left" class="pa-2 pb-0"
                                        style="font-size:12px">
                                        Liste d'article(s) trouvé(s) {{ listeShortData['article'].length }}
                                    </div>
                                </div>
                                <v-col cols="12" class="py-1 pl-0">
                                    <v-checkbox label="Article inconnue" v-model="form['isSelectedAccessoirePerso']"
                                        hide-details></v-checkbox>
                                </v-col>
                                <v-btn v-if="!form['isSelectedAccessoirePerso']" class="my-2" variant="tonal"
                                    @click="getArticleListeByName(listeShortData['articleValue'])">Rechercher</v-btn>
                            </td>
                        </tr>
                        <tr
                            v-if="(listeShortData['article'] && listeShortData['article'].length > 0) || form['isSelectedAccessoirePerso']">
                            <td class="table-line" align="center">
                                <v-col cols="pa-0">
                                    <v-row justify="center" align="center">
                                        <v-col cols="" class="">
                                            <v-autocomplete
                                                v-if="form['isSelectedAccessoirePerso'] == undefined || form['isSelectedAccessoirePerso'] == false"
                                                class="edition" v-model="selectedAccessoire"
                                                :items="listeShortData['article']" hide-details
                                                no-data-text="Modifier la recherche d'article pour plus de proposition"
                                                label="Sélectionner un article" variant="outlined"
                                                :readonly="!isEditable" return-object></v-autocomplete>
                                            <v-text-field v-else class="edition" v-model="selectedAccessoire"
                                                hide-details label="Nom de l'article..." variant="outlined"
                                                :readonly="!isEditable"></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" class="pa-2">
                                            <v-btn style="width:100%" class="" @click="addAcc"
                                                :disabled="!isEditable">Ajouter</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </td>
                        </tr>
                    </table>
                    <table class="table" id="hideInPrint">
                        <tr>
                            <td class="table-line">
                                <Vue3EasyDataTable hide-footer :headers="headers" :items="items"
                                    header-text-direction="center" body-text-direction="center" @click-row="clickRow"
                                    empty-message="Aucun accesoire ajouté">

                                    <template #item-qte="item">
                                        <v-col class="pa-0">
                                            <v-text-field v-model="items.find((val) => val.id == item.id).qte"
                                                type="number" hide-details density="compact"
                                                :disabled="!isEditable || item.IS_FROM_WEB == 'NON'"></v-text-field>
                                        </v-col>
                                    </template>

                                    <template #item-delete="item">
                                        <v-col class="pa-0" v-if="isEditable">
                                            <v-btn id="hideInPrint"
                                                @click="deleteItemFromArrayByIndex(items, items.findIndex((val) => val.id == item.id))"
                                                icon="mdi-delete" style="color: white; background-color: red;" size="30"
                                                variant="text" :disabled="!isEditable || item.IS_FROM_WEB == 'NON'">
                                            </v-btn>
                                        </v-col>
                                    </template>

                                    <template #item-pm="item">
                                        <v-col class="pa-0">
                                            <v-col class="pa-0">
                                                <v-select v-model="items.find((val) => val.id == item.id)['PM']"
                                                    :items="headers.find((val) => val.value == 'pm').items" hide-details
                                                    density="compact"
                                                    :disabled="!isEditable || item.IS_FROM_WEB == 'NON'"></v-select>
                                            </v-col>
                                        </v-col>
                                    </template>
                                </Vue3EasyDataTable>
                            </td>
                        </tr>
                    </table>
                    <div style="display:none;width:100%" id="showInPrint">
                        <table class="table" style="width:100%">
                            <tr style="width: 100%;">
                                <td v-for="(elem, idx) in headers.slice(0, -1)" :key="idx" class="table-line"
                                    :style="idx != 0 ? 'border-left:none' : ''">
                                    {{ elem.text }}
                                </td>
                            </tr>
                            <tr v-for="(elem, idx) in items" :key="idx">
                                <td class="table-line" style="border-top: none;">
                                    {{ elem.PIECE_REFERENCE }}
                                </td>
                                <td class="table-line" style="border-top: none;border-left:none">
                                    {{ elem.PIECE_DESIGNATION }}
                                </td>
                                <td class="table-line" style="border-top: none;border-left:none">
                                    {{ elem.qte }}
                                </td>
                                <td v-if="elem.PM" class="table-line" style="border-top: none;border-left:none">
                                    {{ elem.PM }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </v-col>
                <v-col v-if="!formKeysToHide.includes('travauxExe')" cols="12">
                    <div>
                        <div class="stepContainer">
                            <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                                Travaux réalisés
                            </h3>
                            <v-card variant="flat" id="cardEdition" :color="getColorByKey('travauxExe')" class="">
                                <v-textarea v-model="form['travauxExe']" label="Travaux réalisés..."
                                    :readonly="!isEditable" class="edition" variant="underlined"
                                    hide-details></v-textarea>
                            </v-card>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12">
                    <table class="table">
                        <tr v-if="isRetour">
                            <td class="table-line" style="border-bottom:none">
                                <v-card id="hideInPrint" variant="text" min-height="80px" class="pa-2">
                                    <div style="font-size: 12px;" class="py-1">Commentaire de sortie : </div>
                                    {{ itemsData['old_Observ'] }}
                                </v-card>
                                <div id="showInPrint" style="display:none">
                                    Commentaire de sortie : {{ itemsData['old_Observ'] }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-line">
                                <v-card id="hideInPrint" variant="flat" :color="getColorByKey('observation')" class="">
                                    <v-textarea v-model="form['observation']" rows="2" auto-grow
                                        :label="isRetour ? 'Commentaire de retour : ' : 'Commentaire :'"
                                        :readonly="!isEditable" class="edition" variant="underlined"
                                        hide-details></v-textarea>
                                </v-card>
                                <div id="showInPrint" style="display:none">
                                    {{ isRetour ? 'Commentaire de retour : ' : 'Commentaire :' }} {{ form['observation']
                                    }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12">
                    <table class="table">
                        <tr>
                            <td class="table-line">
                                <h3 id="hideInPrint" class="pa-3 pl-0" style="text-transform:uppercase ;">
                                    Photo(s)
                                </h3>
                                <v-card variant="flat" color="rgba(76, 175, 80,0.5)" id="hideInPrint" class="pa-2">
                                    <v-file-input class="photo" v-model="form['addedPic']" hide-input prepend-icon=""
                                        accept="image/png, image/jpeg" :label="'Ajout de photo'" hide-details @change="updatePhotos()
                                            ">
                                    </v-file-input>
                                    <div align=center v-if="form['addedPic'] != undefined">
                                        <v-btn class="mt-2" @click="addPic()" style="background-color:white"
                                            variant="flat">Ajouter
                                            ma photo</v-btn>
                                    </div>
                                </v-card>
                                <v-card id="hideInPrint" @click="isPhotoViewerOpen = true" variant="outlined"
                                    class="pa-2 mt-4" align=center style="font-size:25px" v-if="form['photos']">
                                    Photo(s) enregistrée(s) : {{ form['photos'].length }}
                                    <v-icon>mdi-image </v-icon>
                                </v-card>
                                <div id="showInPrint" style="display:none">
                                    Nombre de photo : {{ form['photos'].length }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" id="date">
                    <h3>
                        Liste Date / Horaire d'intervention
                    </h3>
                    <!-- <table class="table">
                        <tr>
                            <td class="table-line"> -->
                    <table style="width:100%" class="table">
                        <thead>
                            <tr>
                                <th class="table-line">Date</th>
                                <th class="table-line" style="border-left:none"
                                    v-if="optionsObj.time_selection != true">
                                    Début</th>
                                <th class="table-line" style="border-left:none"
                                    v-if="optionsObj.time_selection != true">Fin
                                </th>
                                <th class="table-line" style="border-left:none"
                                    v-if="optionsObj.time_selection == true">
                                    Durée
                                    (HH:mm)
                                </th>
                                <th class="table-line" style="border-left:none">Technicien</th>
                                <th class="table-line" style="border-left:none">Type</th>
                                <th class="table-line" style="border-left:none"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(elem, idx) in slotHoraireListe" :key="idx">
                                <td class="table-line" style="border-top:none" align="center">{{
                                    formatDate(elem['date'],
                                        'DD/MM/YYYY')
                                }}
                                </td>
                                <td class="table-line" style="border-left:none;border-top:none"
                                    v-if="optionsObj.time_selection != true" align="center">
                                    {{
                                        elem['timeStart'] }}</td>
                                <td class="table-line" style="border-left:none;border-top:none"
                                    v-if="optionsObj.time_selection != true" align="center">
                                    {{
                                        elem['timeEnd'] }}</td>
                                <td class="table-line" style="border-left:none;border-top:none" align="center"
                                    v-if="optionsObj.time_selection == true">
                                    {{
                                        elem['time']
                                    }}</td>
                                <td class="table-line" style="border-left:none;border-top:none" align="center">{{
                                    elem['technicien'] }}</td>
                                <td class="table-line" style="border-left:none;border-top:none" align="center">{{
                                    elem['type'] }}</td>
                                <td class="table-line" style="border-left:none;border-top:none" align="center">
                                    <div v-if="isEditable" id="hideInPrint" style="width: 100%;" align=center>
                                        <v-btn @click="deleteItemFromArrayByIndex(slotHoraireListe, idx)"
                                            icon="mdi-delete" style="color: white; background-color: red;" size="30"
                                            variant="text">
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- </td>
                        </tr>
                    </table> -->
                    <table v-if="isEditable" id="hideInPrint" class="table mt-3">
                        <tr>
                            <td class="table-line">
                                <v-card variant="flat" color="transparent"
                                    style="border:solid 8px rgba(76, 175, 80,0.5)" class="pa-2">
                                    <v-row>
                                        <v-col cols="12" sm="6" class="pb-0">
                                            <v-text-field v-model="slotHoraire['date']" hide-details
                                                class="edition pa-2" type="date" variant="underlined"
                                                :disabled="!isEditable" label="Date d'intervention"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="pb-0">
                                            <v-select label="Technicien" class="edition pa-2"
                                                v-model="slotHoraire['technicien']" :items="technicienListe"
                                                item-title="NOM_TECHNICIEN" item-value="NOM_TECHNICIEN" hide-details
                                                variant="underlined" :disabled="!isEditable">
                                            </v-select>
                                        </v-col>
                                        <v-col v-if="optionsObj.time_selection != true" cols="12" sm="6" class="pb-0">
                                            <v-text-field v-model="slotHoraire['timeStart']" hide-details
                                                class="edition pa-2" type="time" :disabled="!isEditable"
                                                variant="underlined" label="Heure de départ"></v-text-field>
                                        </v-col>
                                        <v-col v-if="optionsObj.time_selection != true" cols="12" sm="6" class="pb-0">
                                            <v-text-field v-model="slotHoraire['timeEnd']" hide-details
                                                class="edition pa-2" type="time" variant="underlined"
                                                :disabled="!isEditable" label="Heure de fin"></v-text-field>
                                        </v-col>
                                        <v-col v-if="optionsObj.time_selection == true" cols="12" sm="6" class="pb-0">
                                            <v-text-field v-model="slotHoraire['time']" hide-details
                                                class="edition pa-2" type="time" variant="underlined"
                                                :disabled="!isEditable"
                                                label="Durée d'intervention (HH:mm)"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="pb-0">
                                            <v-card>
                                                <div class="v-label px-3 pt-2" style="font-size:12px;">
                                                    Type d'intervention
                                                </div>
                                                <v-row>
                                                    <v-col v-for="elem in slotHoraireType" :key="elem.type">
                                                        <v-checkbox hide-details v-model="slotHoraire['type']"
                                                            :label="elem.label" :value="elem.type"></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col align="center" cols="12">
                                            <v-btn @click="addSlotHoraire">
                                                Ajouter une tranche horaire
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col id="hideInPrint" v-if="form && form.Check_liste">
                    <h3>
                        Liste des vérifications
                        <v-icon v-if="optionsObj.show_liste_download && optionsObj.show_liste_download == 1" @click="showOnlyCheckListe = true">mdi-download</v-icon>
                    </h3>
                    <table class="table" id="hideInPrint">
                        <tr>
                            <td class="table-line">
                                <Vue3EasyDataTable hide-footer :headers="headers2" :items="form['Check_liste']"
                                    header-text-direction="center" body-text-direction="center" @click-row="clickRow"
                                    empty-message="Aucun accesoire ajouté">

                                    <template #item-CH_DESIGNATION="item">
                                        <div style="font-weight: bold; font-size: 15px;" align="left">
                                            {{ item.CH_DESIGNATION }}
                                        </div>
                                    </template>

                                    <template #item-CH_ETAT="item">
                                        <div class="pa-2" style="background-color: rgba(76, 175, 80,0.5);"
                                            id="cardEdition">
                                            <v-card variant="flat" color="white">
                                                <v-text-field
                                                    v-model="form['Check_liste'].find((val) => val.CH_DESIGNATION == item.CH_DESIGNATION).CH_ETAT"
                                                    hide-details density="compact" :readonly="!isEditable">
                                                </v-text-field>
                                            </v-card>
                                        </div>
                                    </template>

                                    <template #item-CH_OLD_ETAT="item">
                                        <div variant="flat" color="white" height="100%">
                                            {{form['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                                item.CH_DESIGNATION).CH_OLD_ETAT}}
                                        </div>
                                    </template>
                                </Vue3EasyDataTable>
                            </td>
                        </tr>
                    </table>
                    <div id="showInPrint" style="width:100%;display:none">
                        <table class="table">
                            <tr>
                                <td v-for="(elem, idx) in headers2" :key="idx" class="table-line"
                                    :style="idx != 0 ? 'border-left:none;' : ''">
                                    {{ elem.text }}
                                </td>
                            </tr>
                            <tr v-for="(item, idx) in form['Check_liste']" :key="idx">
                                <td class="table-line" style="border-top: none;">
                                    {{ item.CH_DESIGNATION }}
                                </td>
                                <td class="table-line" style="border-left:none;border-top: none;">
                                    {{form['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                        item.CH_DESIGNATION).CH_ETAT
                                    }}
                                </td>
                                <td class="table-line" style="border-left:none;border-top: none;">
                                    {{form['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                        item.CH_DESIGNATION).CH_OLD_ETAT}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </v-col>
                <v-col cols="12" v-if="optionsObj && optionsObj.selection_specifique_client">
                    <v-card variant="flat" class="outlined stepContainer">
                        <v-col>
                            <v-row>
                                <v-col class="px-0"
                                    v-for="(elem, idx) in JSON.parse(optionsObj.selection_specifique_client)"
                                    :key="idx">
                                    <h3 class="" style="text-transform:uppercase ;">
                                        {{ elem.key }}
                                    </h3>
                                    <v-col>
                                        <v-row>
                                            <v-col v-for="(choice, adx) in elem.value" :key="adx" cols="12" md="6">
                                                <v-card variant="flat">
                                                    <v-checkbox v-model="selectionSpecifiqueClient[elem.key]"
                                                        :label="choice" hide-details style="color:black; width:100%"
                                                        :value="choice"></v-checkbox>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <h3>
                        Signature
                    </h3>
                    <table class="table" id="hideInPrint">
                        <tr>
                            <td class="table-line" @click="writeSignature()">
                                <v-card variant="flat" color="rgba(76, 175, 80,0.5)" class="pa-2">
                                    <v-img contain :src="form['signaturePad']" width="100%" height="200px"
                                        style="background-color:white"></v-img>
                                </v-card>
                            </td>
                        </tr>
                    </table>
                    <div id="showInPrint" style="display:none">
                        <img :src="form['signaturePad']" width="100%" height="200px"
                            style="background-color:white;border:solid 1px black !important" />
                    </div>
                </v-col>
            </v-row>
            <div id="showInPrint" style="display:none">
                <v-divider class="mt-6" color="lightgrey"></v-divider>
                <v-col style="padding-bottom: 20px;font-weight:bold;position:relative" align="center">
                    {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au capital
                    de {{ societe["Capital"] }} € - SIRET
                    {{ societe["NumeroSIRET"] }} - APE : {{ societe["CodeAPE"] }} -
                    TVA : {{ societe["TVAIntracommunautaire"] }} <br />
                    {{ agency["ag_nom"] }} - {{ agency["ag_adresse"] }}
                    {{ agency["ag_cp"] }} {{ agency["ag_ville"] }} - Tél :
                    {{ agency["ag_telephone"] }}
                    <span style="position: absolute;bottom: 0px; right: 0px;">(1/ <span
                            v-if="form['Check_liste'] && form['Check_liste'].length > 0">2</span><span
                            v-else>1</span>)</span>
                </v-col>
            </div>
            <v-row justify="center" id="hideInPrint" class="mt-3">
                <v-col align=center cols="12" sm="10" md="6">
                    <v-row justify=center align=center>
                        <v-col v-if="itemsData" cols="6" class="pa-1">
                            <v-btn color="#7986CB" style="color:white;width:100%"
                                @click="save_html_into_pdf(false, false)">
                                Ouvrir en PDF
                            </v-btn>
                        </v-col>
                        <v-col v-if="itemsData" cols="6" class="pa-1">
                            <v-btn color="#90A4AE" style="color:white;width:100%"
                                @click="save_html_into_pdf(true, false)">
                                Envoyer par Mail
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="pa-1 pb-4">
                            <v-btn v-if="isEditable" :disabled="isUsed" @click="validForm" color="rgba(76, 175, 80)"
                                style="color:white;width:100%">
                                Enregistrer mon OR
                            </v-btn>
                            <div v-else>
                                <v-btn
                                    v-if="itemsData && itemsData.CARA_MATERIEL && JSON.parse(itemsData.CARA_MATERIEL) && JSON.parse(itemsData.CARA_MATERIEL).NUM_CONTRAT"
                                    color="#00695C" @click="function () {
                                        closeVerification = {
                                            model: true,
                                            callback: show_etadl
                                        }
                                    }" style="color:white;">
                                    Envoyer vers état des lieux
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </div>
    <div class="sav-form sav" id="captureMaterielSav" style="display: none;"
        v-if="form && form['Check_liste'] && form['Check_liste'].length > 0">
        <div>
            <v-col cols="12">
                <v-row align=center>
                    <v-col style="font-weight: bold;" class="">
                        {{ societe["RS"] }} <br>
                        {{ agency["ag_nom"] }} <br />
                        {{ agency["ag_adresse"] }} <br />
                        {{ agency["ag_cp"] }}
                        {{ agency["ag_ville"] }} <br />
                        {{ agency["ag_telephone"] }} <br />
                        {{ agency["ag_courriel"] }} <br />
                        {{ agency["ag_siteweb"] }} <br />
                    </v-col>
                    <v-col cols="auto" align="right" class=""
                        v-if="societe['Logo_Entete1_JSON'] && societe['Logo_Entete1_JSON']['Logo_Entete1_Base64']">
                        <div>
                            <img style="height: 90px; border: solid 1px #FAFAFA !important;"
                                :src="societe['Logo_Entete1_JSON']['Logo_Entete1_Base64']" />
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <h3>
                    Liste des vérifications
                </h3>
                <table class="table" id="hideInPrint">
                    <tr>
                        <td class="table-line">
                            <Vue3EasyDataTable hide-footer :headers="headers2" :items="form['Check_liste']"
                                header-text-direction="center" body-text-direction="center" @click-row="clickRow"
                                empty-message="Aucun accesoire ajouté">

                                <template #item-CH_DESIGNATION="item">
                                    <div style="font-weight: bold; font-size: 15px;" align="left">
                                        {{ item.CH_DESIGNATION }}
                                    </div>
                                </template>

                                <template #item-CH_ETAT="item">
                                    <div class="pa-2" style="background-color: rgba(76, 175, 80,0.5);" id="cardEdition">
                                        <v-card variant="flat" color="white">
                                            <v-text-field
                                                v-model="form['Check_liste'].find((val) => val.CH_DESIGNATION == item.CH_DESIGNATION).CH_ETAT"
                                                hide-details density="compact" :readonly="!isEditable">
                                            </v-text-field>
                                        </v-card>
                                    </div>
                                </template>

                                <template #item-CH_OLD_ETAT="item">
                                    <div variant="flat" color="white" height="100%">
                                        {{form['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                            item.CH_DESIGNATION).CH_OLD_ETAT}}
                                    </div>
                                </template>
                            </Vue3EasyDataTable>
                        </td>
                    </tr>
                </table>
                <div id="showInPrint" style="width:100%;display:none">
                    <table class="table" style="width:100%">
                        <tr>
                            <td v-for="(elem, idx) in headers2" :key="idx" class="table-line"
                                :style="idx != 0 ? 'border-left:none;' : ''">
                                {{ elem.text }}
                            </td>
                        </tr>
                        <tr v-for="(item, idx) in form['Check_liste']" :key="idx">
                            <td class="table-line" style="border-top: none;">
                                {{ item.CH_DESIGNATION }}
                            </td>
                            <td class="table-line" style="border-left:none;border-top: none;">
                                {{form['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                    item.CH_DESIGNATION).CH_ETAT
                                }}
                            </td>
                            <td v-if="Object.keys(headers2).length > 2" class="table-line"
                                style="border-left:none;border-top: none;">
                                {{form['Check_liste'].find((val) => val.CH_DESIGNATION ==
                                    item.CH_DESIGNATION).CH_OLD_ETAT}}
                            </td>
                        </tr>
                    </table>
                </div>
            </v-col>
            <div id="showInPrint" style="display:none">
                <v-divider class="mt-6" color="lightgrey"></v-divider>
                <v-col style="padding-bottom: 20px;font-weight:bold;position:relative" align="center">
                    {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au capital
                    de {{ societe["Capital"] }} € - SIRET
                    {{ societe["NumeroSIRET"] }} - APE : {{ societe["CodeAPE"] }} -
                    TVA : {{ societe["TVAIntracommunautaire"] }} <br />
                    {{ agency["ag_nom"] }} - {{ agency["ag_adresse"] }}
                    {{ agency["ag_cp"] }} {{ agency["ag_ville"] }} - Tél :
                    {{ agency["ag_telephone"] }}
                    <span style="position: absolute;bottom: 0px; right: 0px;">(2/2)</span>
                </v-col>
            </div>
        </div>
    </div>
    <div v-if="isPrinting == true"
        style="position:fixed; top: 0px;right:0px;left:0px; height: 100vh; background-color: #7986CB; z-index: 15 !important;overflow: hidden;">
        <v-row style="height:100%; overflow: hidden;" justify="center" align=center>
            <v-col align=center>
                <v-progress-circular size="170" style="padding: 20px" indeterminate color="white">
                    Génération du PDF
                </v-progress-circular>
            </v-col>
        </v-row>
    </div>
</template>

<script>


import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import { uuid } from 'vue-uuid'
import engine from '../core/DataApi'
import moment from 'moment'
import { ref } from "@vue/reactivity";
import SignaturePad from "vue3-signature-pad";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Browser } from "@capacitor/browser";
import { v4 as uuidv4 } from "uuid";
import { openDB } from 'idb';

import modalOnlyListe from './modalOnlyListe.vue';
// import { useStore } from 'vuex'

export default {
    props: {
        getOrListeAll: { type: Function },
        selectedAgency: {},
        technicienListe: {},
        agenceListe: {},
        clientListe: {},
        parcListe: {},
        stockListe: {},
        itemsData: {},
        optionsObj: {},
        user: {},
        mainCheckListe: {}
    },
    setup() {
        // const store = useStore()
        const form = ref({})
        const isAskSignatureOpen = ref(false)
        const selectionSpecifiqueClient = ref({})
        const slotHoraire = ref({})
        const slotHoraireType = ref([
            { type: 'Main d\'oeuvre', label: 'Main d\'oeuvre' },
            { type: 'Déplacement', label: 'Déplacement' },
        ])
        const slotHoraireListe = ref([])
        const clientDataToShowElem = ref([
            { label: 'Adresse', key: 'ADRESSE' },
            { label: 'Complément d\'adresse', key: 'COMP_ADRESSE' },
            { label: 'Ville', key: 'VILLE' },
            { label: 'Code postal', key: 'CODE_POSTAL' },
            { label: 'Téléphone', key: 'TELEPHONE' },
        ])
        const clientDataLivToShowElem = ref([
            { label: 'Adresse d\'intervention', key: 'ADRESSE_LIV' },
            { label: 'Complément d\'adresse d\'intervention', key: 'COMP_ADRESSE_LIV' },
            { label: 'Ville', key: 'VILLE_LIV' },
            { label: 'Code postal', key: 'CODE_POSTAL_LIV' },
        ])

        const addPic = function () {
            if (form.value['photos'] == undefined)
                form.value['photos'] = []

            let file = form.value['addedPic'][0];
            var reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function (e) {
                resizeBase64Img(e.target.result).then((result) => {
                    form.value['photos'].push(result)
                    form.value['addedPic'] = undefined
                });
            };
        }

        const resizeBase64Img = function (base64) {
            return new Promise((resolve) => {
                var canvas = document.createElement("canvas");
                let img = document.createElement("img");
                img.src = base64;

                img.onload = function () {
                    let imgWidth = 750;
                    let imgHeight = 750;
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                    var context = canvas.getContext("2d");
                    context.drawImage(img, 0, 0, imgWidth, imgHeight);
                    resolve(canvas.toDataURL());
                };
            });
        }

        return { form, clientDataToShowElem, isAskSignatureOpen, clientDataLivToShowElem, slotHoraire, slotHoraireListe, slotHoraireType, selectionSpecifiqueClient, addPic }
    },
    data() {
        return {
            showOnlyCheckListe: false,
            personalizedData: undefined,
            agency: {},
            isCReation: false,
            societe: undefined,
            closeVerification: {},
            equipementSearch: "",
            isRetour: false,
            showDetails: {},
            formKeysToHide: [],
            listeShortData: {},
            // findedClient: [],
            // filtreNomtech: "",
            isUsed: false,
            carouselIndex: 0,
            isPhotoViewerOpen: false,
            error: { show: false, message: undefined, color: undefined },
            pdfLink: undefined,
            emailSignatureListe: [],
            // emailList:[],
            email_pdf: undefined,
            isSignatureOpen: false,
            isEditable: true,
            isPrinting: false,
            uuid: uuid.v4(),
            selectedAccessoire: undefined,
            items: []
        }
    },
    mounted() {
        let that = this


        if (this.optionsObj.use_search_filter == false) {
            this.listeShortData['client'] = this.clientListe
            this.listeShortData['matricule'] = this.parcListe
            // this.listeShortData['article'] = this.stockListe
        }
        if (this.optionsObj.form_key_to_hide) {
            this.formKeysToHide = this.optionsObj.form_key_to_hide.split(',')
        }
        that.societe = {}

        if (this.mainCheckListe == undefined || this.mainCheckListe.length == 0) {
            if (this.optionsObj["Check_liste"] && JSON.parse(this.optionsObj["Check_liste"]))
                this.form["Check_liste"] = JSON.parse(this.optionsObj["Check_liste"])
        }

        if (this.itemsData) {
            engine.get_societe_soft_by_id(this.itemsData.ID_Societe, function (data) {

                if (data.code == 0) {
                    that.societe = data.data.data[0].fieldData;
                    if (that.societe["Logo_Entete1_JSON"] && that.societe["Logo_Entete2_JSON"]) {
                        let imageUrl = "data:image/jpeg;base64,";

                        that.societe["Logo_Entete1_JSON"] = JSON.parse(that.societe["Logo_Entete1_JSON"])
                        that.societe["Logo_Entete1_JSON"]['Logo_Entete1_Base64'] = imageUrl + that.societe["Logo_Entete1_JSON"]['Logo_Entete1_Base64']
                        that.societe["Logo_Entete2_JSON"] = JSON.parse(that.societe["Logo_Entete2_JSON"])
                        that.societe["Logo_Entete2_JSON"]['Logo_Entete2_Base64'] = imageUrl + that.societe["Logo_Entete2_JSON"]['Logo_Entete2_Base64']
                    }
                }
            });
            this.preloadData()
            that.agency = this.agenceListe.find((val) => val.ID_Agence == this.itemsData.ID_Agence)
            if (this.itemsData['Statut'] == 140)
                this.isEditable = false
            if (this.form["signaturePad"] && this.form["signaturePad"].length != 0) {
                this.isEditable = false
            }
            if (this.itemsData['Check_list'] && this.itemsData['Check_list'].length > 0) {
                this.form["Check_liste"] = JSON.parse(this.itemsData['Check_list']).fieldata
            }
            if (this.itemsData['retour'] == 'oui') {
                this.isRetour = true
            }

        } else {
            that.agency = this.selectedAgency
            this.isCReation = true
            if (this.optionsObj.inter_type)
                this.form['type_travaux'] = this.optionsObj.inter_type.split(',')[0]

            this.form["date_debut_location_TIMESTAMP"] = parseInt(moment().format('x'))
            this.form["date_fin_location_TIMESTAMP"] = parseInt(moment().format('x'))
            this.form['technicien'] = this.user.user_name
            this.form["date"] = moment().format('YYYY-MM-DD')
            this.form["timeStart"] = moment().set('m', 0).format('HH:mm')
            this.form["timeEnd"] = moment().add(1, 'h').set('m', 0).format('HH:mm')
            this.form["Check_liste"] = JSON.parse(this.optionsObj["Check_liste"])
            this.form['photos'] = []
            this.form['DATE RAPPORT'] = moment().format('YYYY-MM-DD')
            this.slotHoraire['technicien'] = this.user.user_name
            this.slotHoraire["date"] = this.form['DATE RAPPORT']

            if (this.checkListe && this.checkListe.length > 0) {
                this.form["Check_liste"] = this.checkListe.map((val) => ({ CH_DESIGNATION: val.libelle, CH_ETAT: '' }))
            }
        }
        console.log('Check_liste 2', this.form["Check_liste"])
        engine.get_form_special_data(function (data) {
            if (data.code == 0) {
                let personalizedDataArray = data.data.data.map((val) => val.fieldData);
                that.personalizedData = personalizedDataArray
            }
        });

    },
    watch: {
        agency(val) {
            let that = this
            engine.get_societe_soft_by_id(val.ID_Societe, function (data) {
                if (data.code == 0) {
                    that.societe = data.data.data[0].fieldData;
                    let imageUrl = "data:image/jpeg;base64,";
                    if (that.societe["Logo_Entete1_JSON"]) {
                        that.societe["Logo_Entete1_JSON"] = JSON.parse(that.societe["Logo_Entete1_JSON"])
                        that.societe["Logo_Entete1_JSON"]['Logo_Entete1_Base64'] = imageUrl + that.societe["Logo_Entete1_JSON"]['Logo_Entete1_Base64']
                    }
                    if (that.societe["Logo_Entete2_JSON"]) {
                        that.societe["Logo_Entete2_JSON"] = JSON.parse(that.societe["Logo_Entete2_JSON"])
                        that.societe["Logo_Entete2_JSON"]['Logo_Entete2_Base64'] = imageUrl + that.societe["Logo_Entete2_JSON"]['Logo_Entete2_Base64']
                    }
                }
            });
        }
    },
    components: {
        Vue3EasyDataTable,
        SignaturePad,
        modalOnlyListe
    },
    methods: {
        async storeImageInBdd(name, base64Image) {
            const db = await openDB('sav-database', 1, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains('images')) {
                        db.createObjectStore('images'); // Création d'un store pour les images
                    }
                }
            });
            await db.put('images', base64Image, name); // Stocke l'image avec le nom comme clé
        },
        async getImageFromBdd(name) {
            const db = await openDB('sav-database', 1, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains('images')) {
                        db.createObjectStore('images'); // Création d'un store pour les images
                    }
                }
            });
            return await db.get('images', name); // Retourne l'image en base64
        },
        show_etadl() {
            this.closeVerification.model = false
            engine.show_etadl(this.itemsData.num_contrat, this.itemsData.equipement, this.itemsData['N° MATR INT'], this.itemsData.CARA_MATERIEL,
                this.optionsObj.show_equipement_in_etadl == 1, this.form['Message_Interne'], function () {
                })
        },
        resetMatricul() {
            this.form['materielMatricul'] = undefined
            this.form['materiel'] = undefined
        },
        send_pdf() {
            let photo = []
            let that = this

            if (this.form['photos'].length > 0)
                photo = JSON.parse(JSON.stringify(this.form['photos']))

            engine.send_pdf_by_email(
                this.emailSignatureListe,
                this.pdfLink,
                this.itemsData['N° RAP COMP'],
                photo,
                function (data) {
                    if (data.data.errorListe.length == 0) {
                        that.error.message = 'Email(s) envoyé(s)'
                        that.error.color = '#66BB6A'
                        that.error.show = true
                    }
                    else {
                        that.error.message = 'Erreur rencontré sur le(s) email(s) : ' + data.data.errorListe.join(' - ')
                        that.error.color = 'red'
                        that.error.show = true
                    }
                    setInterval(() => {
                        that.error = {}
                    }, 3000);
                }
            );
            this.isAskSignatureOpen = false
        },
        downloadPic(pic) {
            var a = document.createElement("a"); //Create <a>
            a.href = pic
            a.download = "Image.png"; //File name Here
            a.click();
        },
        getColorByKey(key) {
            if (this.isPrinting)
                return 'white'
            else
                return this.getMandatoryItems.find((val) => val.key == key) ? 'rgba(255, 61, 0,0.5)' : 'rgba(76, 175, 80,0.5)'
        },
        loadPicturesfromDropBox() {
            let that = this
            engine.photo_folder_liste(this.itemsData['N° RAP COMP'], function (data) {
                if (data.code == 0 && (data.data && data.data.length > 0)) {
                    let listeFileName = data.data
                    for (let i = 0; i < listeFileName.length; i++) {
                        engine.photo_download(that.itemsData['N° RAP COMP'], listeFileName[i], function (data2) {
                            if (data2.code == 0) {
                                let blob = new Blob(
                                    [new Uint8Array(data2.data.result.fileBinary.data)],
                                    { type: "image/png" }
                                );
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    let url = reader.result;
                                    that.form['photos'].push(url)
                                };
                                reader.readAsDataURL(blob);
                            }
                        })
                    }
                }
            })
        },
        saveImg(base64Img, numOr) {
            engine.file_upload(base64Img, numOr, moment().format('DD-MM-YYYY hh.mm.ss'), function () { })
        },
        updatePhotos() {
            console.log("updatePhotos", this.form['addedPic']);
        },
        closeModal() {
            this.$emit('close')
        },
        deleteElemFromListeByIndex(liste, idx) {
            liste.splice(idx, 1)
        },
        addEmailToListe() {
            this.emailSignatureListe.push(this.email_pdf)
            this.email_pdf = undefined
        },
        async getImageSizeFromBase64(base64Img) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    resolve({
                        width: img.width,
                        height: img.height
                    });
                };
                img.onerror = () => {
                    reject({});
                };
                img.src = base64Img; // Définit la source de l'image
            });
        },

        addCanvaToDoc(imgData, doc, size, formSize) {
            let imgWidth = 204;
            let imgHeight = 290;
            let position = 3;

            if (formSize) {
                size = 60
                size += (10 * formSize.length)
            }

            // size = size / 1.8



            if (size < 280)
                imgHeight = size

            doc.addImage(
                imgData,
                "PNG",
                3,
                position,
                imgWidth,
                imgHeight,
                undefined,
                "FAST"
            );
        },

        async getcanvaFromDiv(divData) {
            return new Promise((resolve, reject) => {
                let html2canvasOptions = {
                    allowTaint: true,
                    removeContainer: true,
                    backgroundColor: null,
                    imageTimeout: 15000,
                    logging: true,
                    scale: 2,
                    useCORS: true,
                    processData: false,
                    contentType: false,
                    windowWidth: 1600,
                };

                // Appel à html2canvas
                html2canvas(divData, html2canvasOptions).then((canvas) => {
                    // Une fois le canvas prêt, on récupère l'image
                    let imgData = canvas.toDataURL("image/png");
                    resolve(imgData); // Résolution de la promesse avec l'image
                }).catch((error) => {
                    reject(error); // Si une erreur se produit, la promesse est rejetée
                });
            });
        },

        async save_html_into_pdf(sendSignature, onlyLoadFile) {
            console.log("save_html_into_pdf", sendSignature, onlyLoadFile);

            window.scrollTo(0, 0);
            let that = this;
            that.isPrinting = true;

            var elms = document.querySelectorAll("#hideInPrint");
            var elms2 = document.querySelectorAll("#showInPrint");

            for (let i = 0; i < elms.length; i++) elms[i].style.display = "none";
            for (let i = 0; i < elms2.length; i++) elms2[i].style.display = "block";

            const elements = document.querySelectorAll("#captureMaterielSav");

            if (elements.length > 1) {
                elements[0].style.width = "1000px"
                elements[1].style.width = "1000px"
                elements[0].style.display = "block"
                elements[1].style.display = "block"


                try {
                    const [imgData1, imgData2] = await Promise.all([
                        that.getcanvaFromDiv(elements[0]),
                        that.getcanvaFromDiv(elements[1])
                    ]);

                    let doc = new jsPDF("p", "mm", "a4");
                    this.addCanvaToDoc(imgData1, doc, elements[1].offsetHeight, undefined)
                    if (that.form && that.form['Check_liste'] && that.form['Check_liste'].length > 0) {
                        doc.addPage()
                        this.addCanvaToDoc(imgData2, doc, elements[0].offsetHeight, that.form['Check_liste'])
                    }

                    if (that.form['photos'] && that.form['photos'].length > 0) {
                        for (const base64Img of Object.values(that.form['photos'])) {
                            let size = await that.getImageSizeFromBase64(base64Img)
                            let ratio = size.width / size.height
                            doc.addPage();
                            doc.addImage(base64Img, 'PNG', 3, 3, 290, 290 / ratio);
                        }
                    }

                    that.isPrinting = false;
                    that.pdfLink = doc.output("datauristring");
                    for (let i = 0; i < elms.length; i++) { elms[i].style.display = "block"; }
                    for (let i = 0; i < elms2.length; i++) elms2[i].style.display = "none";

                    elements[1].style.display = "none"
                    elements[0].style.width = "100%"
                    elements[1].style.width = "100%"

                    if (onlyLoadFile) {                        
                        that.isPrinting = false;
                        that.closeModal()
                    } else {
                        if (sendSignature && sendSignature == true) {
                            that.emailSignatureListe = that.form['client']['CONTACT_MAIL_TOUS'].split('|').filter((val) => val.length > 0)
                            that.isAskSignatureOpen = true
                            that.isPrinting = false;
                        } else {
                            let id = uuidv4();
                            engine.creat_tmp_pdf(id, doc.output(), function (data) {
                                if (data.code === 0) {
                                    engine.openPdf(id, function (urlPdf) {
                                        Browser.open({ url: urlPdf });
                                    });
                                }
                            });
                        }
                    }
                } catch (error) {
                    console.error('Erreur lors de la capture des images:', error);
                }
            }
        },
        formatDate(date, form) {
            let tmpDate = moment(date).format(form)
            if (tmpDate == 'Invalid date')
                tmpDate = undefined
            return tmpDate
        },
        deleteItemFromArrayByIndex(array, idx) {
            array.splice(idx, 1)
        },
        writeSignature() {
            if (this.isEditable === true) {
                this.isSignatureOpen = true;
            }
        },
        undo(key) {
            if (this.$refs[key].length == undefined)
                this.$refs[key].undoSignature();
            else
                this.$refs[key][0].undoSignature();
        },
        save(key) {
            const { isEmpty, data } = this.$refs[key].saveSignature();
            if (isEmpty === false) this.form[key] = data;
        },
        clear(key) {
            if (this.$refs[key].length == undefined)
                this.$refs[key].clearSignature();
            else
                this.$refs[key][0].clearSignature();
        },
        addSlotHoraire() {
            if (this.optionsObj.time_selection && Object.keys(this.slotHoraire).length >= 4) {
                this.slotHoraireListe.push(this.slotHoraire)
                this.slotHoraire = {}
                this.slotHoraire['technicien'] = this.user.user_name
                this.slotHoraire["date"] = this.form['DATE RAPPORT']
            }
            if (this.optionsObj.time_selection != true && Object.keys(this.slotHoraire).length >= 5) {
                this.slotHoraireListe.push(this.slotHoraire)
                this.slotHoraire = {}
                this.slotHoraire['technicien'] = this.user.user_name
                this.slotHoraire["date"] = this.form['DATE RAPPORT']
            }
        },
        getMatriculListeByName(name) {
            let that = this
            engine.get_matricule_liste_by_name(name, function (data) {
                if (data.code == 0) {
                    that.listeShortData['matricule'] = data.data.data.map((val) => val.fieldData)
                } else {
                    that.listeShortData['matricule'] = []
                }
            })
        },
        getClientListeByName(name) {
            let that = this
            engine.get_client_liste_by_name(name, function (data) {
                if (data.code == 0) {
                    that.listeShortData['client'] = data.data.data.map((val) => val.fieldData)
                } else {
                    that.listeShortData['client'] = []
                }
            })
        },
        getArticleListeByName(name) {
            let that = this
            engine.get_article_liste_by_name(name, function (data) {
                if (data.code == 0) {
                    that.listeShortData['article'] = data.data.data.map((val) => val.fieldData)
                    for (let i = 0; i < that.listeShortData['article'].length; i++) {
                        let key = that.listeShortData['article'][i].PIECE_DESIGNATION

                        if (that.listeShortData['article'][i].SS_FAMILLE)
                            key = that.listeShortData['article'][i].SS_FAMILLE + ' - ' + key
                        if (that.listeShortData['article'][i].FAMILLE)
                            key = that.listeShortData['article'][i].FAMILLE + ' - ' + key

                        key = that.listeShortData['article'][i].PIECE_REFERENCE + ' - ' + key
                        that.listeShortData['article'][i]['title'] = key
                    }
                } else {
                    that.listeShortData['article'] = []
                }
            })
        },
        async preloadData() {
            let that = this

            await engine.get_client_by_id(this.itemsData.ID_client, function (clientData) {
                if (clientData.code == 0) {
                    that.form["client"] = clientData.data.data[0].fieldData

                    that.form["client"]['ADRESSE_LIV'] = that.itemsData['adliv']
                    that.form["client"]['COMP_ADRESSE_LIV'] = that.itemsData['compadliv']
                    that.form["client"]['VILLE_LIV'] = that.itemsData['villeliv']
                    that.form["client"]['CODE_POSTAL_LIV'] = parseInt(that.itemsData['dptliv'] + that.itemsData['cpliv'])
                }
            })

            if (this.itemsData.equipement && this.itemsData.equipement.length > 0) {
                this.form['equipement'] = JSON.parse(this.itemsData.equipement)
            }

            if (this.itemsData['DATE RAPPORT'] && this.itemsData['DATE RAPPORT'].length > 0)
                this.form['DATE RAPPORT'] = this.formatDate(this.itemsData['DATE RAPPORT'], 'YYYY-MM-DD')
            else
                this.form['DATE RAPPORT'] = moment().format('YYYY-MM-DD')


            this.loadPicturesfromDropBox()
            this.form["id"] = this.itemsData['id']
            this.form["link"] = this.itemsData['_LINK MOTEUR']
            this.form["Horametre"] = this.itemsData['Horametre']
            this.form["Kilometrage"] = this.itemsData['Kilometrage']
            this.form["themeDevis"] = this.itemsData['Theme devis']
            this.form["facturable"] = this.itemsData['facturable']
            this.form["travauxExe"] = this.itemsData['Travaux exe']
            this.form["observation"] = this.itemsData['Observ']
            this.form["Message_Interne"] = this.itemsData['Message_Interne']
            this.form["type_travaux"] = this.itemsData['type_travaux']
            this.form['LIEU_INTERV'] = this.itemsData['LIEU_INTERV']
            this.form["time"] = this.itemsData['time_inter']
            this.form["num_contrat"] = this.itemsData['num_contrat']
            this.form["old_Horametre"] = this.itemsData['old_Horametre']
            this.form["old_Observ"] = this.itemsData['old_Observ']
            this.form["CARA_MATERIEL"] = this.itemsData["CARA_MATERIEL"]
            this.form["date_debut_location_TIMESTAMP"] = this.itemsData['date_debut_location_TIMESTAMP']
            this.form["date_fin_location_TIMESTAMP"] = this.itemsData['date_fin_location_TIMESTAMP']

            this.slotHoraire['technicien'] = this.user.user_name
            this.slotHoraire["date"] = this.form['DATE RAPPORT']

            this.form['photos'] = []

            engine.get_matricul_by_name(this.itemsData["N° MATR INT"], function (matData) {
                if (matData.code == 0) {
                    that.form["materiel"] = matData.data.data[0].fieldData
                }
                else {
                    that.form['materielMatricul'] = that.itemsData["N° MATR INT"]
                    that.form['materielUnknow'] = true
                }
            })



            if (this.itemsData['selection_specifique_client'] && this.itemsData['selection_specifique_client'].length != 0)
                this.selectionSpecifiqueClient = JSON.parse(this.itemsData['selection_specifique_client'])

            if (this.itemsData['Horaire_liste'] && this.itemsData['Horaire_liste'].length != 0)
                this.slotHoraireListe = JSON.parse(this.itemsData['Horaire_liste'])
            this.form["signaturePad"] = this.itemsData['Signature_web']

            if (this.itemsData['Check_liste'] && this.itemsData['Check_liste'].length != 0) {
                let liste = JSON.parse(this.itemsData['Check_liste'])
                if (liste.fieldata)
                    this.form["Check_liste"] = JSON.parse(this.itemsData['Check_liste']).fieldata
                else
                    this.form["Check_liste"] = JSON.parse(this.itemsData['Check_liste'])
            }


            this.form['technicien'] = this.itemsData['Techniciens_Nom(1)']
            let orTable

            if (this.itemsData['Table'] == 'SAV_FICHE') {
                orTable = 'get_or_1_Liste_byLink'

                if (moment(this.itemsData['Debut']).format('hh:mm') != 'Invalid date' &&
                    moment(this.itemsData['Fin']).format('hh:mm') != 'Invalid date' &&
                    moment(this.itemsData['Debut']).format('YYYY-MM-DD') != 'Invalid date'
                ) {
                    this.form["timeStart"] = moment(this.itemsData['Debut']).format('hh:mm')
                    this.form["timeEnd"] = moment(this.itemsData['Fin']).format('hh:mm')
                    this.form["date"] = moment(this.itemsData['Debut']).format('YYYY-MM-DD')
                }
            } else if (this.itemsData['Table'] == 'SAV_2') {
                orTable = 'get_or_2_Liste_byLink'
                // if (this.slotHoraireListe.length == 0) {
                //     if (this.form['DATE RAPPORT']) {
                //         let dateFormated = this.form['DATE RAPPORT'].split('/')
                //         console.log("dateFormated", dateFormated);
                //         this.form["date"] = dateFormated[2] + '-' + dateFormated[0] + '-' + dateFormated[1]
                //     }
                //     this.form["timeStart"] = this.itemsData['Heure Arriv(1)']
                //     this.form["timeEnd"] = this.itemsData['Heure dep(1)']
                //     this.slotHoraireListe.push(
                //         { 'date': this.form["date"], 'timeStart': this.form["timeStart"], 'timeEnd': this.form["timeEnd"], 'technicien': this.itemsData["Techniciens_Nom(1)"] }
                //     )
                // } else {
                //     this.form["date"] = this.slotHoraireListe[0].date
                //     this.form["timeStart"] = this.slotHoraireListe[0].timeStart
                //     this.form["timeEnd"] = this.slotHoraireListe[0].timeEnd
                // }
            }

            engine[orTable](this.itemsData['_LINK MOTEUR'], function (data) {
                if (data.code == 0) {
                    that.items = []
                    let moteurListe = data.data.data.map((val) => val.fieldData)

                    for (let i = 0; i < moteurListe.length; i++) {
                        that.items.push({
                            'PIECE_DESIGNATION': moteurListe[i]["SAV DESIGNATION"],
                            'PIECE_REFERENCE': moteurListe[i]["SAV REF PRODUIT"],
                            'IS_FROM_WEB': moteurListe[i]["IS_FROM_WEB"],
                            'id': moteurListe[i]["id"],
                            'qte': moteurListe[i]["SAV QTE PROD"],
                            'PM': moteurListe[i]["PM"],
                        })
                        // }else {

                        // }
                    }
                }
            })

        },
        verifScroll(elem) {
            if (elem) {
                return true
            } else {
                return false
            }
        },
        async validForm() {

            let formIsValid = true
            let errorForm = ""

            let keyToCkeck = [
                { key: 'client', label: 'La séléction d\'un client est obligatoire VOIR : INFORMATION CLIENT' },
                { key: 'technicien', label: 'La séléction d\'un technicien est obligatoire VOIR : TECHNICIEN PRINCIPAL' },
                { key: 'signaturePad', label: 'La signature est obligatoire VOIR : Signature' },
            ]

            // form['materielUnknow']
            if (this.form['materielUnknow'] == false || this.form['materielUnknow'] == undefined) {
                keyToCkeck.push({ key: 'materiel', label: 'La séléction d\'un materiel est obligatoire VOIR : INFORMATION MACHINE' })
            }

            if (this.optionsObj["Mandatory_keys"] && JSON.parse(this.optionsObj["Mandatory_keys"]))
                keyToCkeck = keyToCkeck.concat(JSON.parse(this.optionsObj["Mandatory_keys"]));

            if (this.form['materielUnknow'] && this.form['materielUnknow'] == true) {
                let index = keyToCkeck.findIndex((val) => val.key == "Horametre")
                if (index) {
                    keyToCkeck.splice(index, 1)
                }
            }

            for (let i = 0; i < keyToCkeck.length; i++) {

                // if (this.form[keyToCkeck[i].key] && typeof this.form[keyToCkeck[i].key] !== 'object') {
                if (this.form[keyToCkeck[i].key] == undefined || this.form[keyToCkeck[i].key] == '') {
                    // if (this.form[keyToCkeck[i].key] == undefined || JSON.stringify(this.form[keyToCkeck[i].key]).trim().length == 0) {
                    errorForm = keyToCkeck[i].label
                    formIsValid = false
                    break
                }
                // }
            }


            // if (this.slotHoraireListe && this.slotHoraireListe.length == 0) {
            //     errorForm = "L'ajout de minimum une horaire d'intervention est obligatoire VOIR : LISTE DATE / HORAIRE D'INTERVENTION"
            //     formIsValid = false
            // }
            let that = this
            if (formIsValid) {
                this.isUsed = true
                await this.save_html_into_pdf(false, true)
                if (this.itemsData == undefined) {
                    this.creatOrSAV_FICHE()
                } else {
                    this.editOrSAV_FICHE()
                }
            } else {
                this.error.message = errorForm
                this.error.color = 'red'
                this.error.show = true
                setInterval(() => {
                    that.error = {}
                }, 3000);
            }


        },
        creatOrSAV2(orNum, id) {
            console.log("creatOrSAV2", this.form);

            let link = uuid.v4()
            let that = this
            let finalObj = {
                id,
                'N° RAP COMP': orNum,

                'retour': this.isRetour == true ? 'oui' : '',

                'Telephone': that.form['client']['TELEPHONE'],
                'ID_client': that.form['client']['id'],
                'societeliv': that.form['client']['SOCIETE'],
                'villeliv': that.form['client']['VILLE_LIV'],
                'adliv': that.form['client']['ADRESSE_LIV'],
                // 'cpliv': that.form['client']['CODE_POSTAL_LIV'],
                'compadliv': that.form['client']['COMP_ADRESSE'],

                '_LINK MOTEUR': link,
                'Message_Interne': that.form['Message_Interne'],
                'Check_liste': JSON.stringify({ 'fieldata': this.form['Check_liste'] }),
                'selection_specifique_client': JSON.stringify(that.selectionSpecifiqueClient),

                'DATE RAPPORT': that.formatDate(that.form['DATE RAPPORT'], 'MM/DD/YYYY'),

                'Horametre': that.form['Horametre'],
                'Kilometrage': that.form['Kilometrage'],
                'Theme devis': that.form['themeDevis'],
                'Observ': that.form['observation'],
                'Travaux exe': that.form['travauxExe'],
                'LIEU_INTERV': that.form['LIEU_INTERV'],
                "CARA_MATERIEL": that.form["CARA_MATERIEL"],

                'old_Horametre': that.form['old_Horametre'],
                'old_Observ': that.form['old_Observ'],
                'num_contrat': that.form['num_contrat'],

                'date_debut_location_TIMESTAMP': that.form['date_debut_location_TIMESTAMP'],
                'date_fin_location_TIMESTAMP': that.form['date_fin_location_TIMESTAMP'],

                // 'Horaire_liste': JSON.stringify(that.slotHoraireListe),
                // 'DATE RAPPORT': moment(that.slotHoraireListe[0]['date']).format('MM/DD/YYYY'),
                // 'Heure Arriv': that.slotHoraireListe[0]['timeStart'],
                // 'Heure dep': that.slotHoraireListe[0]['timeEnd'],
                'Signature_web': that.form['signaturePad'],
                'type_travaux': that.form['type_travaux'],
                'equipement': JSON.stringify(that.form['equipement']),

                "ID_Agence": that.user.user_id_agence,
                "ID_Societe": that.user.user_id_societe,

                // "type_travaux": 'réparation',
                "Table": 'SAV_2',
                "Status devis": 'Signé',
                "Statut": '140',
            }
            this.form["client"]['CODE_POSTAL_LIV'] = JSON.stringify(this.form["client"]['CODE_POSTAL_LIV'])
            if (this.form["client"]['CODE_POSTAL_LIV'] && this.form["client"]['CODE_POSTAL_LIV'].length >= 3) {
                finalObj['dptliv'] = this.form["client"]['CODE_POSTAL_LIV'].slice(0, 2)
                finalObj['cpliv'] = this.form["client"]['CODE_POSTAL_LIV'].substring(2)
            }

            if (that.form['materiel'] && that.form['materielUnknow'] != true) {
                finalObj['N° MATR INT'] = that.form['materiel']['MATRICULE']
                finalObj['MARQUE MACHINE'] = that.form['materiel']['MARQUE']
                finalObj['TYPE MACHINE'] = that.form['materiel']['TYPE']
                finalObj['sous_famille'] = that.form['materiel']['SOUS_FAMILLE']
                // finalObj['N° serie constr.'] = that.form['materiel']['NUMERO_SERIE']
                finalObj['N° serie constr'] = that.form['materiel']['NUMERO_SERIE']
            } else {
                finalObj['N° MATR INT'] = that.form['materielMatricul']
            }

            if (that.slotHoraireListe && that.slotHoraireListe[0]) {
                finalObj['Horaire_liste'] = JSON.stringify(that.slotHoraireListe)
                finalObj['DATE RAPPORT'] = moment(that.slotHoraireListe[0]['date']).format('MM/DD/YYYY')
                if (that.slotHoraireListe[0]['timeStart'])
                    finalObj['Heure Arriv'] = that.slotHoraireListe[0]['timeStart']
                if (that.slotHoraireListe[0]['timeEnd'])
                    finalObj['Heure dep'] = that.slotHoraireListe[0]['timeEnd']
                finalObj['time_inter'] = that.slotHoraireListe[0]['time']
            }

            if (that.form.technicien.NOM_TECHNICIEN)
                finalObj['Techniciens_Nom(1)'] = that.form.technicien.NOM_TECHNICIEN
            else
                finalObj['Techniciens_Nom(1)'] = that.form.technicien

            let localId = id

            console.log(0);
            
            if (this.itemsData && this.itemsData.id)
                localId = this.itemsData['id']

            let key = localId + finalObj['N° MATR INT']
            console.log(1);

            console.log("key2", key);
            console.log("key2 val1", JSON.stringify(this.form.photos));

            this.storeImageInBdd(key, JSON.stringify(this.form.photos))
            this.form.photos = []

            let orData = { data: { finalObj, items: that.items, itemsData: this.itemsData, form: this.form, optionsObj: this.optionsObj, pdfLink: this.pdfLink }, from: "creatOrSAV2" }


            // if (that.$store.state.oRstoredData == undefined)
            //     that.$store.state.oRstoredData = []
            // that.$store.state.oRstoredData.push(orData)

            this.$store.commit("setOrStoredData", orData)
        },
        creatOrSAV_FICHE() {
            let id = uuid.v4()
            let link
            let signed = false
            let that = this

            let finalObj = this.getOrDataBasic
            finalObj['id'] = id
            finalObj['ID_Agence'] = String(this.agency.ID_Agence)

            // let finalObj = {
            //     id,
            // 'Horaire_liste': JSON.stringify(this.slotHoraireListe),
            // 'Signature_web': this.form['signaturePad'],
            // 'Kilometrage': this.form['Kilometrage'],

            // 'Telephone': this.form['client']['TELEPHONE'],
            // 'ID_client': this.form['client']['id'],
            // 'societeliv': this.form['client']['SOCIETE'],
            // 'villeliv': this.form['client']['VILLE_LIV'],
            // 'adliv': this.form['client']['ADRESSE_LIV'],
            // 'compadliv': this.form['client']['COMP_ADRESSE'],

            // 'DATE RAPPORT': this.formatDate(this.form['DATE RAPPORT'], 'MM/DD/YYYY'),

            // 'Horametre': this.form['Horametre'],
            // 'Check_liste': JSON.stringify({ 'fieldata': this.form['Check_liste'] }),
            // 'selection_specifique_client': JSON.stringify(this.selectionSpecifiqueClient),

            // 'LIEU_INTERV': this.form['LIEU_INTERV'],

            // 'Theme devis': this.form['themeDevis'],
            // 'Observ': this.form['observation'],
            // 'Travaux exe': this.form['travauxExe'],
            // 'Message_Interne': this.form['Message_Interne'],

            // "ID_Agence": this.user.user_id_agence,
            // "ID_Societe": this.user.user_id_societe,

            // 'type_travaux': this.form['type_travaux'],
            // "Table": 'SAV_FICHE',

            // "STATUS_DEVIS": 'En cours',
            // 'Statut': 130
            // }

            finalObj['date_debut_location_TIMESTAMP'] = this.form["date_debut_location_TIMESTAMP"]
            finalObj['date_fin_location_TIMESTAMP'] = this.form["date_fin_location_TIMESTAMP"]

            this.form["client"]['CODE_POSTAL_LIV'] = JSON.stringify(this.form["client"]['CODE_POSTAL_LIV'])
            if (this.form["client"]['CODE_POSTAL_LIV'] && this.form["client"]['CODE_POSTAL_LIV'].length >= 3) {
                finalObj['dptliv'] = this.form["client"]['CODE_POSTAL_LIV'].slice(0, 2)
                finalObj['cpliv'] = this.form["client"]['CODE_POSTAL_LIV'].substring(2)
            }

            if (that.form['materiel'] && that.form['materielUnknow'] != true) {

                finalObj['N° MATR INT'] = that.form['materiel']['MATRICULE']
                finalObj['MARQUE MACHINE'] = that.form['materiel']['MARQUE']
                finalObj['TYPE MACHINE'] = that.form['materiel']['TYPE']
                finalObj['sous_famille'] = that.form['materiel']['SOUS_FAMILLE']
                // finalObj['N° serie constr.'] = that.form['materiel']['NUMERO_SERIE']
                finalObj['N° serie constr'] = that.form['materiel']['NUMERO_SERIE']
            } else {
                finalObj['N° MATR INT'] = that.form['materielMatricul']
            }

            if (this.slotHoraireListe && this.slotHoraireListe[0]) {
                let date = this.slotHoraireListe[0].date

                if (this.slotHoraireListe[0] && this.slotHoraireListe[0].date.includes('-')) {
                    let tmpTab = this.slotHoraireListe[0].date.split('-')
                    date = tmpTab[1] + '/' + tmpTab[2] + '/' + tmpTab[0]
                }
                if (this.slotHoraireListe[0]['timeEnd'])
                    finalObj['Fin'] = date + ' ' + this.slotHoraireListe[0]['timeEnd']
                if (this.slotHoraireListe[0]['timeStart'])
                    finalObj['Debut'] = date + ' ' + this.slotHoraireListe[0]['timeStart']
                finalObj['time_inter'] = this.slotHoraireListe[0]['time']
            }


            link = uuid.v4()

            finalObj['_LINK MOTEUR'] = link

            if (this.form.signaturePad && this.form.signaturePad.length > 0) {
                signed = true
                finalObj['Statut'] = '140'
            }

            if (this.form.technicien.NOM_TECHNICIEN)
                finalObj['Techniciens_Nom(1)'] = this.form.technicien.NOM_TECHNICIEN
            else
                finalObj['Techniciens_Nom(1)'] = this.form.technicien

            console.log("finalObj", finalObj)
            console.log("form", this.form)

            let key = finalObj['id'] + finalObj['N° MATR INT']
            console.log("key", key);

            this.storeImageInBdd(key, JSON.stringify(this.form.photos))
            this.form.photos = []

            let storeData = { data: { finalObj, items: that.items, signed, optionsObj: this.optionsObj, itemsData: this.itemsData, form: this.form, pdfLink: this.pdfLink }, from: "creatOrSAV_FICHE" }

            this.$store.commit("setOrStoredData", storeData)
            that.closeModal()
        },
        editOrSAV_FICHE() {
            console.log("editOrSAV_FICHE", this.form);

            let that = this
            // let id = uuid.v4()
            let link

            let finalObj = this.getOrDataBasic

            this.form["client"]['CODE_POSTAL_LIV'] = JSON.stringify(this.form["client"]['CODE_POSTAL_LIV'])
            if (this.form["client"]['CODE_POSTAL_LIV'] && this.form["client"]['CODE_POSTAL_LIV'].length >= 3) {
                finalObj['dptliv'] = this.form["client"]['CODE_POSTAL_LIV'].slice(0, 2)
                finalObj['cpliv'] = this.form["client"]['CODE_POSTAL_LIV'].substring(2)
            }

            if (this.form["postalCode"] && this.form["postalCode"].length > 3) {
                finalObj['dptliv'] = this.form["postalCode"].slice(0, 2)
                finalObj['cpliv'] = this.form["postalCode"].substring(2);
            }

            if (that.form['materiel'] && that.form['materielUnknow'] != true) {
                finalObj['N° MATR INT'] = that.form['materiel']['MATRICULE']
                finalObj['MARQUE MACHINE'] = that.form['materiel']['MARQUE']
                finalObj['TYPE MACHINE'] = that.form['materiel']['TYPE']
                finalObj['sous_famille'] = that.form['materiel']['SOUS_FAMILLE']
                // finalObj['N° serie constr.'] = that.form['materiel']['NUMERO_SERIE']
                finalObj['N° serie constr'] = that.form['materiel']['NUMERO_SERIE']
            } else {
                finalObj['N° MATR INT'] = that.form['materielMatricul']
            }

            if (this.slotHoraireListe && this.slotHoraireListe[0]) {
                let date = this.slotHoraireListe[0].date
                if (this.slotHoraireListe[0].date.includes('-')) {
                    let tmpTab = this.slotHoraireListe[0].date.split('-')
                    date = tmpTab[1] + '/' + tmpTab[2] + '/' + tmpTab[0]
                }

                if (this.slotHoraireListe[0]['timeEnd'])
                    finalObj["Fin"] = date + ' ' + this.slotHoraireListe[0]['timeEnd']
                if (this.slotHoraireListe[0]['timeStart'])
                    finalObj["Debut"] = date + ' ' + this.slotHoraireListe[0]['timeStart']
                finalObj['time_inter'] = this.slotHoraireListe[0]['time']
            }

            if (this.itemsData) {
                link = this.itemsData['_LINK MOTEUR']
                finalObj['N° RAP COMP'] = this.itemsData['N° RAP COMP']
            } else {
                link = uuid.v4()
            }

            finalObj['_LINK MOTEUR'] = link

            if (this.form.signaturePad && this.form.signaturePad.length > 0) {
                finalObj['Statut'] = '140'
                this.creatOrSAV2(this.itemsData['N° RAP COMP'], this.itemsData['id'])
            }

            if (this.form.technicien.NOM_TECHNICIEN)
                finalObj['Techniciens_Nom(1)'] = this.form.technicien.NOM_TECHNICIEN
            else
                finalObj['Techniciens_Nom(1)'] = this.form.technicien

            // let key = this.itemsData['id'] + finalObj['N° MATR INT']
            // console.log("key1", key);
            // console.log("key1 val1", JSON.stringify(this.form.photos));

            // this.storeImageInBdd(key, JSON.stringify(this.form.photos))
            this.form.photos = []

            let orData = { data: { finalObj, items: that.items, itemsData: this.itemsData, form: this.form, pdfLink: this.pdfLink }, from: "editOrSAV_FICHE" }
            this.$store.commit("setOrStoredData", orData)
            that.closeModal()
        },
        addAcc() {
            if (this.form['isSelectedAccessoirePerso'] == true) {
                // { text: "Référence", value: "PIECE_REFERENCE", sortable: true },
                // { text: "Désignation", value: "PIECE_DESIGNATION", sortable: true },
                // { text: "Quantitée", value: "qte", sortable: true, width: '200' },
                // { text: "", value: "delete", sortable: false, width: '0' },

                this.items.push({ 'PIECE_REFERENCE': '', 'PIECE_DESIGNATION': this.selectedAccessoire, 'qte': 1, 'IS_FROM_WEB': 'OUI', id: uuid.v4() })
                this.selectedAccessoire = undefined
            } else {
                this.selectedAccessoire['id'] = uuid.v4()
                this.selectedAccessoire['qte'] = 1
                this.selectedAccessoire['IS_FROM_WEB'] = 'OUI'
                this.items.push(this.selectedAccessoire)
                this.selectedAccessoire = undefined
            }
        },
        clickRow(data) {
            console.log("clickRow", data);
        }
    },
    computed: {
        headers() {
            let header = []

            header.push({ text: "Référence", value: "PIECE_REFERENCE", sortable: true })
            header.push({ text: "Désignation", value: "PIECE_DESIGNATION", sortable: true })
            header.push({ text: "Quantitée", value: "qte", sortable: true, width: '200' })

            if (this.optionsObj.or_stock && this.optionsObj.or_stock.length != 0) {
                let liste = JSON.parse(this.optionsObj.or_stock)
                for (let i = 0; i < liste.length; i++) {
                    header.push(liste[i])
                }
            }


            header.push({ text: "", value: "delete", sortable: false, width: '0' })



            return header
        },
        getComplementInfo() {
            let info = [];

            let tabPersonalizedData = Object.values(this.personalizedData)
            for (let i = 0; i < tabPersonalizedData.length; i++) {
                if (tabPersonalizedData[i].show == 1) {
                    info.push(
                        {
                            key: tabPersonalizedData[i].key_depart,
                            label: tabPersonalizedData[i].label_depart,
                            // class: "sortie",
                            type: tabPersonalizedData[i].type,
                            disabled: this.sortieDisabled,
                        },
                        // {
                        //     key: tabPersonalizedData[i].key_retour,
                        //     label: tabPersonalizedData[i].label_retour,
                        //     class: "entrer",
                        //     type: tabPersonalizedData[i].type,
                        //     disabled: this.entrerDisabled,
                        // }
                    );
                }
            }
            return info;
        },
        getOrDataBasic() {
            let data = {
                'Horaire_liste': JSON.stringify(this.slotHoraireListe),
                'Signature_web': this.form['signaturePad'],
                'Kilometrage': this.form['Kilometrage'],
                'facturable': this.form['facturable'],
                'Telephone': this.form['client']['TELEPHONE'],
                'ID_client': this.form['client']['id'],
                'societeliv': this.form['client']['SOCIETE'],
                'villeliv': this.form['client']['VILLE_LIV'],
                'adliv': this.form['client']['ADRESSE_LIV'],
                'compadliv': this.form['client']['COMP_ADRESSE'],

                'old_Horametre': this.form['old_Horametre'],
                'old_Observ': this.form['old_Observ'],

                'LIEU_INTERV': this.form['LIEU_INTERV'],
                "CARA_MATERIEL": this.form["CARA_MATERIEL"],

                'DATE RAPPORT': this.formatDate(this.form['DATE RAPPORT'], 'MM/DD/YYYY'),
                "Table": 'SAV_FICHE',

                'Horametre': this.form['Horametre'],
                'Check_liste': JSON.stringify({ 'fieldata': this.form['Check_liste'] }),
                'selection_specifique_client': JSON.stringify(this.selectionSpecifiqueClient),
                'equipement': JSON.stringify(this.form['equipement']),

                "ID_Agence": this.user.user_id_agence,
                "ID_Societe": this.user.user_id_societe,


                'Theme devis': this.form['themeDevis'],
                'Observ': this.form['observation'],
                'Travaux exe': this.form['travauxExe'],
                'Message_Interne': this.form['Message_Interne'],
                'type_travaux': this.form['type_travaux'],

                "STATUS_DEVIS": 'En cours',
                'Statut': 130
            }

            return data
        },
        headers2() {
            let header = [
                { text: "Equipement", value: "CH_DESIGNATION", sortable: false },
                { text: "Valeur", value: "CH_ETAT", sortable: false },
            ]

            if (this.form['Check_liste'][0] && this.form['Check_liste'][0].CH_OLD_ETAT != undefined)
                header.push({ text: "Ancienne valeur", value: "CH_OLD_ETAT", sortable: false })

            return header
        },
        // getClientListe() {
        //     let client
        //     let that = this

        //     if (that.filtreNomtech && that.filtreNomtech.length > 0) {
        //         client = that.clientListe.filter((val) => val.SOCIETE.toLocaleLowerCase().includes(that.filtreNomtech.toLocaleLowerCase()))
        //         console.log("eee", client);
        //     } else {
        //         client = that.clientListe
        //     }

        //     console.log("getClientListe", client);
        //     return client
        // },
        getMandatoryItems() {

            let keyToCkeck = [
                { key: 'client', label: 'La séléction d\'un client est obligatoire VOIR : INFORMATION CLIENT' },
                { key: 'materiel', label: 'La séléction d\'un materiel est obligatoire VOIR : INFORMATION MACHINE' },
                { key: 'technicien', label: 'La séléction d\'un technicien est obligatoire VOIR : TECHNICIEN PRINCIPAL' },
            ]

            if (this.optionsObj["Mandatory_keys"] && JSON.parse(this.optionsObj["Mandatory_keys"]))
                keyToCkeck = keyToCkeck.concat(JSON.parse(this.optionsObj["Mandatory_keys"]));
            return keyToCkeck
        },
        materielElemToShow() {
            if (this.form.materiel == undefined)
                return []
            return [{
                key: "FAMILLE",
                label: "Famille"
            },
            {
                key: "SOUS_FAMILLE",
                label: "Sous famille"
            }, {
                key: "NUMERO_SERIE",
                label: "Numéro de série"
            }, {
                key: "MARQUE",
                label: "Marque"
            }, {
                key: "TYPE",
                label: "Type"
            }
            ]
        }
    }
}
</script>

<style>
.edition {
    background-color: transparent;
    color: black;
    font-weight: bold !important;
    border: none !important;
    padding: 10px;
}

.disabledFormData {
    background-color: white !important;
}

.stepContainer {
    /* background-color: #E0E0E0; */
    height: 100%;
    color: white;
    background-color: #546E7A;
    padding: 15px;
    border-radius: 10px;
    border: solid 1px #BDBDBD;
}

/* .sav-form table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.sav-form td,
.sav-form th {
    border: 1px solid #ECEFF1;
    text-align: left;
    padding: 8px;
} */

.sav-form {
    font-weight: bold;
}

.sav-form .table {
    border-collapse: collapse;
    width: 100%;
    color: black;
    background-color: white;
}

.sav-form .table-line {
    border: solid 1px black;
    padding: 10px;
    color: black;
    background-color: white;
}

/* .sav-form tr:nth-child(even) {
    background-color: #ECEFF1;
} */
</style>