<template>
    <v-dialog v-model="showPlanningDispo.model" fullscreen style="background-color: white;">
        <div>
            <iframe style="height: calc(100vh - 45px);width: 100%; visibility:visible;  border: none; overflow: hidden;"
                :src="showPlanningDispo.url"></iframe>
            <v-btn @click="showPlanningDispo.model = false" color="red"
                style="color: white; width: 90vw; position: absolute; bottom: 5px; left:5vw; height: 30px">
                Fermer
            </v-btn>
        </div>
    </v-dialog>
    <v-dialog v-model="isReplaceMaterielOpenVerif" max-width="300">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;" align=center>
                Valider ma modification
            </v-col>
            <v-col>
                <v-row justify="center" align="center">
                    <v-col cols="6" align="center">
                        <v-btn @click="function () {
                            isReplaceMaterielOpen = true
                            isReplaceMaterielOpenVerif = false
                        }" color="#F44336" style="color: white;">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col cols="6" align="center">
                        <v-btn @click="changeMatricule" color="#4CAF50" style="color: white;">
                            Oui
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isReplaceMaterielOpen" max-width="500">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;">
                Sélectionner un nouveau matricule
            </v-col>
            <v-col>
                <v-autocomplete hide-details no-data-text="Aucun matricule trouvé" label="Matricule sélectionné"
                    v-model="selectedMatricule" :items="parcListe" item-value="MATRICULE" item-title="MATRICULE">
                </v-autocomplete>
            </v-col>
            <v-col class="pt-1" align="center">
                <v-btn color="#4CAF50" style="color: white;" @click="function () {
                    isReplaceMaterielOpen = false
                    isReplaceMaterielOpenVerif = true
                }">
                    Valider la modification
                </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isClotureValidation" width="auto">
        <v-card>
            <v-col class="pb-0">
                <div class="pa-2" style="font-weight: bold;">
                    Valider la clôture de mon contrat ?
                </div>
            </v-col>
            <v-col class="pt-0">
                <v-row justify="center" align="center">
                    <v-col cols="auto">
                        <v-btn color="green" @click="cloturerContrat">
                            Oui
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="red" @click="isClotureValidation = false">
                            Non
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="showAllOptions" max-width="800" width="99%" persistent>
        <v-card>
            <v-col style="font-weight: bold" class="pb-2">
                Options avancées
                <v-btn icon density="compact" style="position: absolute;right: 10px;top:5px" color="red"
                    @click="showAllOptions = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col>
                <v-row justify="center" align=center>
                    <v-col v-if="getSortieState.nbrSortie > 0" class="" cols="6">
                        <v-btn color="#4CAF50" style="color:white;width: 100%;" variant="flat" @click="renewContrat">
                            renouveler contrat
                        </v-btn>
                    </v-col>
                    <v-col class="" cols="6">
                        <v-btn color="#D32F2F" style="color:white;width: 100%;" variant="flat"
                            @click="function () { isClotureValidation = true }">
                            Clôturer le contrat
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>

    <v-card style="height: 100vh; overflow: auto;">
        <v-col v-if="!isLoading" style=" font-weight:bold !important"
            class="sav-contrat">
            <v-col class="px-0">
                <v-card variant="flat" style="border: solid 1px;"
                    :style="{ 'border-color': isEdited ? 'red' : 'black' }" color="#FAFAFA">
                    <v-chip v-if="isEdited"
                        style="position: fixed; top: 32px; right: 50px; z-index: 2; background-color: rgb(255, 0, 0,0.8); color: white; font-weight: bold; font-size: 10px;">
                        Des modifications ont été apportées pensez à les valider
                    </v-chip>
                    <v-chip v-if="!isEditable"
                        style="position: fixed; top: 32px; right: 50px; z-index: 2; background-color: rgb(255, 0, 0,0.8); color: white; font-weight: bold; font-size: 10px;">
                        Contrat facturé et plus modifiable
                    </v-chip>
                    <v-col>
                        <v-row>
                            <v-col cols="auto">
                                <div>Contrat : <span style="font-weight: bold;">{{ localContrat['N° Contrat Comp']
                                        }}</span>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                <div>Code client : <span style="font-weight: bold;">{{ localContrat['Code client']
                                        }}</span></div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row>
                            <v-col align=center>

                                <table class="table" style="width: 100%;">
                                    <tr v-for="(elem, idx) in dataToShow" :key="idx">
                                        <td class="table-line"
                                            :style="{ 'background-color': idx % 2 != 0 ? 'white' : 'rgb(236, 239, 241)' }">
                                            {{
                                                elem.label }}</td>
                                        <td class="table-line"
                                            :style="{ 'background-color': idx % 2 != 0 ? 'white' : 'rgb(236, 239, 241)' }">
                                            <div v-if="elem.key == 'LocdataS_::Date Debut Loc'">
                                                <div v-if="elem.editable">
                                                    <v-card variant="flat">
                                                        <input @change="function () { isEdited = true }" type="date"
                                                            v-model="localContrat['LocdataS_::Date Debut Loc']"
                                                            style="width: 100%; padding: 15px;border: solid 1px lightgrey;border-radius: 5px;">
                                                    </v-card>
                                                </div>
                                                <div v-else>
                                                    {{ formatDate(localContrat[elem.key], 'DD/MM/YYYY') }}
                                                </div>
                                            </div>
                                            <div v-else-if="elem.key == 'LocdataS_::Date retour'">
                                                {{ formatDate(localContrat[elem.key], 'DD/MM/YYYY') }}
                                            </div>
                                            <div v-else>
                                                <v-select variant="outlined" bg-color="white" hide-details
                                                    v-if="elem.key == 'Lieu_retrait_nom' && elem.editable"
                                                    item-value="PR_NOM_AG"
                                                    @update:modelValue="function () { isEdited = true }"
                                                    v-model="localContrat.Lieu_retrait_nom" :items="retraitListe"
                                                    item-title="PR_NOM_AG"></v-select>
                                                <div v-else>
                                                    {{ localContrat[elem.key] }}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-col v-if="options && options.find((val) => val.name == 'paiement_proposition')" class="px-0">
                            <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                                <v-col cols="12">
                                    Paiement effectué par...
                                </v-col>
                                <v-select :readonly="!isEditable" @update:modelValue="function () { isEdited = true }"
                                    hide-details variant="outlined" elevation="0"
                                    v-model="localContrat.reglement_mode_prevu"
                                    :bg-color="isEditable ? 'rgb(239, 154, 154)' : 'white'"
                                    :items="[{ title: 'En attente de paiement' }].concat(options.find((val) => val.name == 'paiement_proposition').value)">
                                </v-select>
                            </v-card>
                        </v-col>
                        <v-col v-if="options && options.find((val) => val.name == 'caution_proposition')" class="px-0">
                            <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                                <v-col cols="12">
                                    Caution effectué par...
                                </v-col>
                                <v-select :readonly="!isEditable" @update:modelValue="function () { isEdited = true }"
                                    hide-details variant="outlined"
                                    v-model="localContrat['caution_multiple::type_caution']" elevation="0"
                                    :bg-color="isEditable ? 'rgb(239, 154, 154)' : 'white'"
                                    :items="[{ title: 'En attente de caution' }].concat(options.find((val) => val.name == 'caution_proposition').value)">
                                </v-select>
                            </v-card>
                        </v-col>
                        <v-col class="px-0">
                            <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                                <v-col cols="12">
                                    Location(s)
                                </v-col>
                                <v-col v-for="(elem, idx) in matriculListe" :key="idx">
                                    <v-card class="pa-2" variant="outlined">
                                        <!-- :items="parcListe.filter((val) => val.SOUS_FAMILLE == elem.CLD_SOUS_FAMILLE)" -->
                                        <v-select :readonly="!isEditable" class="edition" item-value="MATRICULE"
                                            item-title="MATRICULE" :items="parcListe" hide-details
                                            :label="'Matricule - ' + elem.CLD_SOUS_FAMILLE" v-model="elem.CLD_MATRICULE"
                                            @update:modelValue="matriculeChange(elem)"
                                            no-data-text="Aucun matricule trouvé"
                                            :bg-color="isEditable ? 'rgb(239, 154, 154)' : 'white'">
                                        </v-select>
                                        <v-col v-if="matriculIsFree[elem.CLD_MATRICULE]" class="pb-0" align=center>
                                            <div v-if="matriculIsFree[elem.CLD_MATRICULE].data.disponible == 'false'">
                                                <v-chip color="red" style="font-weight: bold;">
                                                    Le {{ elem.CLD_MATRICULE }}
                                                    n'est pas disponible
                                                </v-chip>
                                            </div>
                                            <div v-if="matriculIsFree[elem.CLD_MATRICULE].data.disponible == 'true'">
                                                <v-chip color="green" style="font-weight: bold;">
                                                    Le
                                                    {{ elem.CLD_MATRICULE }}
                                                    est disponible
                                                </v-chip>
                                            </div>
                                        </v-col>
                                        <v-col class="px-0 pb-0" align=center>
                                            <v-row>
                                                <v-col>
                                                    <v-card variant="flat"
                                                        @click="setAction(elem, { title: 'Annuler la sortie', value: 'attenteMateriel' })"
                                                        :style="elem.CLD_STATUT == 'En attente' ? 'color: white;background-color: #1A237E;' : 'border:solid 1px lightgrey'">En
                                                        attente</v-card>
                                                </v-col>
                                                <v-col>
                                                    <v-card variant="flat"
                                                        @click="setAction(elem, { title: 'Sortir le materiel', value: 'sortirMateriel' })"
                                                        :style="elem.CLD_STATUT == 'Sortie' ? 'color: white;background-color: #1B5E20;' : 'border:solid 1px lightgrey'"
                                                        value="Sortie"> Sortie</v-card>
                                                </v-col>
                                                <v-col>
                                                    <v-card variant="flat" @click="setAction(elem, { title: 'Rentrer le materiel', value: 'rentrerMateriel' },
                                                    )" :style="elem.CLD_STATUT == 'Retour' ? 'color: white;background-color: #B71C1C;' : 'border:solid 1px lightgrey'"
                                                        value="Retour"> Rentrer</v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="px-0 pb-1 pt-4" align=center>
                                            <v-chip style="font-weight: bold;">
                                                {{
                                                    getContartDureById[elem.CLD_ID_DUREE_LOCATION]['CAT_Tarif_GM_id::Nom_Grille']
                                                }}
                                            </v-chip>
                                        </v-col>
                                        <v-col class="px-0">
                                            <v-row justify="center" align=center>
                                                <!-- <v-col cols="">
                                                    <v-autocomplete variant="outlined" label="Sélectionner une action"
                                                        class="edition" return-object="" hide-details
                                                        v-model="actionData[elem.CLD_MATRICULE]" :items="actionListe">
                                                    </v-autocomplete>
                                                </v-col> -->
                                                <v-col cols="auto" align=center>
                                                    <v-btn variant="tonal"
                                                        style="color: green;"
                                                        @click="envoyerMatEtadtdl(elem.CLD_MATRICULE)">
                                                        Envoyer vers etat des lieux
                                                        <!-- {{ actionData.function.title }} - {{
                                                    actionData.matricul.CLD_MATRICULE }} -->
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card>
                                </v-col>
                            </v-card>
                        </v-col>
                        <v-col cols="12" align=center>
                                                    <v-btn variant="tonal"
                                                        style="color: purple;"
                                                        @click="envoyerMatSignature(elem.CLD_MATRICULE)">
                                                        Envoyer pour signature
                                                        <!-- {{ actionData.function.title }} - {{
                                                    actionData.matricul.CLD_MATRICULE }} -->
                                                    </v-btn>
                                                </v-col>
<v-col class="pa-0">
    <v-card class="pa-2"
                                style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat">
                                <div class="mb-2 pt-1">
                                    Ouverture rapide :
                                </div>
                                <v-row class="" align="center" justify="center">
                                    <v-col cols="6">
                                        <v-btn
                                            @click="setIframe('/#/etat-des-lieux-materiel?search=' + localContrat['N° Contrat Comp'])"
                                            variant="tonal" target="_blank" color="#1A237E" width="100%">État
                                            des lieux</v-btn>
                                    </v-col>
                                    <!-- <v-col cols="auto">
                                <v-btn @click="setIframe('/#/sav-gestion?search=' + localContrat['N° Contrat Comp'])"
                                    color="#1B5E20" variant="tonal" target="_blank">Sav</v-btn>
                            </v-col> -->
                                    <v-col cols="6">
                                        <v-btn
                                            @click="setIframe('/#/signature-en-ligne?search=' + localContrat['Code client'])"
                                            color="#B71C1C" style="" variant="tonal" target="_blank"
                                            width="100%">Signature des
                                            documents</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
</v-col>
                        <v-col align=center v-if="isEditable">
                            <v-btn color="#4CAF50" :disabled="!isEdited" style="color:white" variant="flat"
                                @click="updateContrat">
                                Valider mes modifications
                            </v-btn>
                        </v-col>
                    </v-col>
                </v-card>
            </v-col>
            <!-- <v-col class="px-0" align=right>
            <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                <div class="mt-2 pa-2" align=center>
                    <v-btn @click="sendToFacturation" variant="flat" color="#E57373" style="color:white;width: 100%;">
                        Facturer
                    </v-btn>
                </div>
            </v-card>
        </v-col> -->
            <v-col align="right" v-if="isEditable && getSortieState.allSortie
                && (localContrat.reglement_mode_prevu && localContrat.reglement_mode_prevu.length > 0)
                && (localContrat['caution_multiple::type_caution'] && localContrat['caution_multiple::type_caution'].length > 0)
            ">
                <v-btn variant="flat" @click="sendToFacturation" color="#E57373" style="color:white">
                    Facturer
                </v-btn>
            </v-col>
            <v-btn variant="flat" @click="showAllOptions = true" color="#5C6BC0" style="color:white">
                Options avancées
            </v-btn>
        </v-col>
        <v-col v-else align=center>
            <v-progress-circular :size="150" :width="4" color="primary" indeterminate>
                Chargement
            </v-progress-circular>
        </v-col>
    </v-card>
</template>

<script>
import moment from 'moment'
import engine from '../core/DataApi'

export default {
    props: {
        agencyListe: {},
        seletedContrat: {},
        parcListe: {},
        options: {},
        contrat_informations: {},
        retraitListe: {},
        setMessage: { type: Function },
        getContratsListe: { type: Function },
        closeEdition: { type: Function },
    },
    watch: {
        selectedCaution() {
            engine.update_caution(this.localContrat.ID, 'modeDepot', 'montant', function (data) {
                console.log("update_caution", data);
            })

        }
    },
    data() {
        return {
            showAllOptions: false,
            isClotureValidation: false,
            actionData: {},
            actionListe: [
                // { title: 'Sortir le materiel', value: 'sortirMateriel' },
                // { title: 'Annuler la sortie', value: 'attenteMateriel' },
                // { title: 'Rentrer le materiel', value: 'rentrerMateriel' },
                { title: 'Envoyer vers etat des lieux', value: 'envoyerMatEtadtdl' },
                { title: 'Envoyer pour signature', value: 'envoyerMatSignature' },
                // { title: 'Envoyer pour OR/OP', value: 'envoyerMatOr' },
            ],
            isLoading: false,
            isEdited: false,
            matriculListe: undefined,
            savedMatriculListe: undefined,
            localContrat: {},
            selectedCaution: undefined,
            showPlanningDispo: {},
            matriculIsFree: {},
            isReplaceMaterielOpenVerif: false,
            isReplaceMaterielOpen: false,
            editableKey: ['LocdataS_::N° Mat interne'],
            editableData: {},
        }
    },
    methods: {
        getMatriculListeData(callback) {
            let that = this
            engine.get_contrat_matricul_liste(this.localContrat['N° Contrat Comp'], function (data) {
                if (data.code == 0) {
                    that.matriculListe = JSON.parse(JSON.stringify(data.data.data.map((val) => val.fieldData)))
                    that.savedMatriculListe = JSON.parse(JSON.stringify(data.data.data.map((val) => val.fieldData)))
                }
                if (callback)
                    callback()
            })
        },
        setAction(mat, funct) {
            this.actionData[mat.CLD_MATRICULE] = funct
            this.runAction(mat)
        },
        setData() {
            this.localContrat = JSON.parse(JSON.stringify(this.seletedContrat))
            if (this.localContrat['LocdataS_::N° Mat interne'])
                this.selectedMatricule = JSON.parse(JSON.stringify(this.localContrat['LocdataS_::N° Mat interne']))
            if (this.localContrat['LocdataS_::Date Debut Loc'])
                this.localContrat['LocdataS_::Date Debut Loc'] = moment(this.localContrat['LocdataS_::Date Debut Loc']).format('yyyy-MM-DD')
        },
        runAction(mat) {

            let that = this
            that.isLoading = true
            that.showAllOptions = false

            this[this.actionData[mat.CLD_MATRICULE].value](mat, function () {
                that.getContratsListe(function () {
                    that.getMatriculListeData(function () {
                        that.isLoading = false
                    })
                })
            })
        },
        testFunction(mat, callback) {
            console.log("testFunction", mat);
            callback()
        },
        sortirMateriel(mat, callback) {
            let that = this
            engine.sortie_mat(mat.CLD_MATRICULE_UID, mat.CLD_NUM_CONTRAT, function (data) {
                if (data.message) {
                    let message = JSON.parse(data.message)
                    if (message.resultat) {
                        that.setMessage(message.resultat, 'red')
                    }
                }
                callback()
            })
        },
        rentrerMateriel(mat, callback) {
            // let that = this
            console.log("rentrerMateriel", mat);
            // engine.sortie_mat(mat.CLD_MATRICULE_UID, mat.CLD_NUM_CONTRAT, function (data) {
            //     console.log("rentrerMateriel", data);
            //     if (data.message) {
            //         let message = JSON.parse(data.message)
            //         if (message.resultat) {
            //             that.setMessage(message.resultat, 'red')
            //         }
            //     }
            callback()
            // })
        },
        cloturerContrat() {
            let that = this
            that.isLoading = true
            that.isClotureValidation = false
            that.showAllOptions = false
            engine.cloture_contrat(this.localContrat['N° Contrat Comp'], function () {
                that.getContratsListe(function () {
                    that.closeEdition()
                })
            })
        },
        attenteMateriel(mat, callback) {
            let that = this
            engine.retour_mat(mat.CLD_MATRICULE_UID, mat.CLD_NUM_CONTRAT, function (data) {
                if (data.message) {
                    let message = JSON.parse(data.message)
                    if (message.resultat) {
                        that.setMessage(message.resultat, 'red')
                    }
                }
                callback()
            })
        },
        envoyerMatEtadtdl(mat, callback) {
            engine.send_mat_etadl(mat.CLD_MATRICULE_UID, mat.CLD_NUM_CONTRAT, '', function () {
                console.log("envoyerMatEtadtdl good");
                
                callback()
            })
        },
        envoyerMatSignature(mat, callback) {
            engine.send_to_signe(mat.CLD_ID_WEB, mat.CLD_MATRICULE_UID, function () {
                console.log("Envoyer pour signature");                
                callback()
            })
        },

        envoyerMatOr(mat, callback) {
            engine.send_mat_or(mat.CLD_MATRICULE, mat.CLD_NUM_CONTRAT, mat.CLD_ID_CONTRAT_LOC_DATA, '', function () {
                callback()
            })
        },
        sendToFacturation() {
            console.log("sendToFacturation", this.localContrat);
            console.log("matriculListe", this.matriculListe);
            let that = this

            that.isLoading = true
            that.showAllOptions = false
            // WebApp_creation_facture_reglement

            for (let i = 0; i < this.matriculListe.length; i++) {
                let mat = this.matriculListe[i]

                engine.facturation(mat.CLD_ID_CONTRAT_LOC_DATA, mat.CLD_NUM_CONTRAT, this.seletedContrat['reglement_mode_prevu'],
                    function (data) {
                        console.log("sendToFacturation", data);
                        that.getContratsListe(function () {
                            that.isLoading = false
                        })
                    }
                )

            }

        },
        updateContrat() {
            console.log("updateContrat");

            let that = this
            this.isLoading = true
            this.localContrat['LocdataS_::Date Debut Loc'] = moment(this.localContrat['LocdataS_::Date Debut Loc']).format('MM/DD/YYYY')

            for (let i = 0; i < that.savedMatriculListe.length; i++) {
                if (that.savedMatriculListe[i].CLD_MATRICULE != that.matriculListe[i].CLD_MATRICULE) {
                    engine.change_matricule(that.savedMatriculListe[i]['CLD_NUM_CONTRAT'], that.savedMatriculListe[i]['CLD_ID_CONTRAT_LOC_DATA'],
                        that.savedMatriculListe[i].CLD_MATRICULE, that.matriculListe[i].CLD_MATRICULE, function () {
                        }
                    )
                }
            }

            console.log("update local contrat", that.localContrat);

            engine.update_contrat(that.localContrat, function (data) {
                console.log("update_contrat", data);
                // that.closeEdition()
                that.getContratsListe(function () {
                    // that.setData()
                    that.isEdited = false
                    that.isLoading = false
                })
            })
        },
        renewContrat() {
            let that = this

            that.isLoading = true
            that.showAllOptions = false
            engine.renew_contrat(this.localContrat['N° Contrat Comp'], function (data) {
                console.log("renew_contrat", data);
                that.getContratsListe(function () {
                    that.isLoading = false
                })
            })
        },
        setIframe(url) {
            let finalUrl = window.location.href.split('/#/')[0] + url + '&header=hide'

            this.showPlanningDispo = {
                model: true,
                url: finalUrl
            }
        },
        matriculeChange(mat) {
            let that = this
            let uuid = this.parcListe.find((val) => val.MATRICULE == mat.CLD_MATRICULE).MATRICULE_UID

            this.isEdited = true

            engine.is_materiel_free({ UID: uuid }, function (data) {
                that.matriculIsFree[mat.CLD_MATRICULE] = data
            })
        },
        formatDate(date, format) {
            return moment(date).format(format)
        },
        changeMatricule() {
            this.isReplaceMaterielOpenVerif = false
        }
    },
    computed: {
        getSortieState() {
            let nbrTotal = 0
            let nbrSortie = 0
            let allSortie = true

            for (let i = 0; i < this.matriculListe.length; i++) {
                let elem = this.matriculListe[i]
                if (elem.CLD_STATUT != 'Sortie') {
                    allSortie = false
                }
                if (elem.CLD_STATUT == 'Sortie') {
                    nbrSortie += 1
                }
                nbrTotal += 1
            }

            return { nbrTotal, nbrSortie, allSortie }
        },
        dataToShow() {
            let data = [
                {
                    label: 'Client',
                    key: 'Societe',
                    type: 'text',
                    editable: false,
                },
                // {
                //     label: 'Type de contrat',
                //     key: 'LocdataS_::ID_Grille_nom',
                //     type: 'text',
                //     editable: true,
                // },
                {
                    label: 'Agence de retrait',
                    key: 'Lieu_retrait_nom',
                    type: 'text',
                    editable: this.isEditable,
                },
                {
                    label: 'Date de sortie',
                    key: 'LocdataS_::Date Debut Loc',
                    type: 'date',
                    format: 'DD/MM/YYYY',
                    editable: this.isEditable,
                },
                {
                    label: 'Date de retour',
                    key: 'LocdataS_::Date retour',
                    type: 'date',
                    format: 'DD/MM/YYYY',
                    editable: false,
                },
                {
                    label: 'Prix location TTC (€)',
                    key: 'Prix Location Total_TTC',
                    type: 'text',
                    editable: false,
                },
                {
                    label: 'Acompte (€)',
                    key: 'Acompte_final',
                    type: 'text',
                    editable: false,
                },
                {
                    label: 'Caution (€)',
                    key: 'caution_multiple::caution_texte',
                    type: 'text',
                    editable: false,
                },
                {
                    label: 'Etat de la caution',
                    key: 'caution_multiple::etat_caution',
                    type: 'text',
                    editable: false
                },
            ]

            return data
        },
        isEditable() {
            if ((this.seletedContrat['N° FACT Comp'] && this.seletedContrat['N° FACT Comp'].length > 0))
                return false
            return true
        },
        getContratSelectedInformations() {
            console.log("getContratSelectedInformations");

            let veloSub = {}

            for (let i = 0; i < this.contrat_informations.length; i++) {
                let key = this.contrat_informations[i].fieldData.Nom_Genre_Machine
                let value = this.contrat_informations[i].portalData.CAT_Tarif_GM_id.map((val) => val)

                console.log("value", value);

                for (let a = 0; a < value.length; a++) {
                    console.log("test", value[a]);

                }

                veloSub[key] = value
            }

            return veloSub
        },
        getContartDureById() {
            let veloSub = {}

            for (let i = 0; i < this.contrat_informations.length; i++) {
                // let key = this.contrat_informations[i].fieldData.Nom_Genre_Machine
                let value = this.contrat_informations[i].portalData.CAT_Tarif_GM_id.map((val) => val)

                console.log("value", value);

                for (let a = 0; a < value.length; a++) {
                    veloSub[value[a]['CAT_Tarif_GM_id::ID_Grille']] = value[a]
                }
            }

            return veloSub
        },

    },
    mounted() {
        console.log("seletedContrat", this.seletedContrat);
        console.log(0)
        this.setData()
        console.log(1)
        this.getMatriculListeData()
        console.log("localContrat", this.localContrat);
    }
}
</script>

<style>
.sav-contrat .table {
    border-collapse: collapse;
    width: 100%;
    color: black;
    background-color: white;
}

.sav-contrat .table-line {
    border: solid 1px black;
    padding: 10px;
    color: black;
    background-color: white;
}
</style>