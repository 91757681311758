import { Chart, Tooltip, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { loadFonts } from './plugins/webfontloader'
import vuetify from './plugins/vuetify'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
// import QrcodeReaderVue3 from "qrcode-reader-vue3";
import VueExcelEditor from 'vue3-excel-editor'
import VueRouter from 'vue-router'

import { createApp } from 'vue'

import router from './router'

import store from './store/index.js'
import App from './App.vue'

// import { createStore } from 'vuex';
// import Vuex from 'vuex'
// import VuexPersistence from 'vuex-persist'

Chart.register(Tooltip, ChartDataLabels, ...registerables);

loadFonts()


// const vuexLocal = new VuexPersistence({
//     storage: window.localStorage // Utilise localStorage pour la persistance
// });

// const store = createStore({
//     state: {
//         user: { name: 'Arnav' },
//         navigation: { path: '/home' }
//     },
//     mutations: {
//         setUser(state, user) {
//             state.user = user;
//         },
//         setNavigation(state, path) {
//             state.navigation.path = path;
//         }
//     },
//     plugins: [vuexLocal.plugin] // Ajout du plugin pour persister les données
// });

const app = createApp(App)

app.use(store)
app.config.errorHandler = () => null;
app.config.warnHandler = () => null;
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('DatePicker', Datepicker);
// app.use(i18n)
app.use(router)
app.use(vuetify)
app.use(VueRouter)
app.use(VueExcelEditor)

// app.use(QrcodeReaderVue3);

app.mount('#app')