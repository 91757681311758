import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/pdf-signer";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_client_data_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_client_data_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_client_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_client_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_files_liste_from_path(path, callback) {
        let datas = { dropBoxPath: path }
        axios.post(this.URL + '/v1/get_files_liste_from_path', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_file_from_path(path, callback) {
        let datas = { path }
        axios.post(this.URL + '/v1/get_file_from_path', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_client_files_by_code(code, callback) {
        let datas = { code }
        axios.post(this.URL + '/v1/get_client_files_by_code', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    signe_and_change_file_name(fileData, dropBoxPath, contents, callback) {
        let datas = { dropBoxPath, contents, fileData }
        axios.post(this.URL + '/v1/signe_and_change_file_name', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_files_by_mail(email, files, callback) {
        let datas = { email, files }
        axios.post(this.URL + '/v1/send_files_by_mail', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_double_verif_by_mail(clientData, files, callback) {
        let datas = { clientData, files }
        axios.post(this.URL + '/v1/send_double_verif_by_mail', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_double_authentification_data_from_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/get_double_authentification_data_from_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    send_sms_double_authent(id, email, tel, callback) {
        let datas = { id, email, tel, }
        axios.post(this.URL + '/v1/send_sms_double_authent', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new DataApi()