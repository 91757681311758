import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import momentPlugin from '@fullcalendar/moment'
import interactionPlugin from "@fullcalendar/interaction";

// import { Calendar } from "@fullcalendar/core";

import fr from "@fullcalendar/core/locales/fr";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useStore } from 'vuex'

import moment from 'moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { onMounted } from "vue";
import engine from "../core/index";

export default function calendarData(
    handleEventClick,
    handleEventDrop,
    handleEventDragTime,
    handleEventReceive,
) {
    const store = useStore()
    const eventList = ref([]);
    const technicienList = ref([]);
    const technicienListToShow = ref([]);
    const userType = ref(undefined); // dayGridMonth dayGridWeek resourceTimelineWeek
    const userVue = ref(undefined)
    const daysToHide = ref([]);
    // const calendarType = ref(undefined)
    // const currentCalendarVew = ref(undefined)
    const calendarDates = ref({ start: undefined, end: undefined });
    const isModalChauffeurSettingsOpen = ref(false);
    const isModalSettingsOpen = ref(false);
    const eventColorByType = ref({
        LIVRAISON: { label: 'Livraison', color: '#4CAF50' },
        // RETOUR: { label: 'Retour', color: '#3F51B5' },
    })

    const calendarOptions = computed(function() {
        let option = {
            // editable: true,
            schedulerLicenseKey: '0359429366-fcs-1650894843',
            nowIndicator: true,
            plugins: [momentPlugin, dayGridPlugin, timeGridPlugin, resourceTimelinePlugin, interactionPlugin],
            // initialView: userVue.value === 'Admin' ? 'resourceTimelineWeek' : 'dayGridWeek',
            initialView: 'resourceTimelineWeek',
            resourceAreaWidth: "180px",
            hiddenDays: daysToHide.value,
            height: "91.4vh",
            locale: fr,
            datesSet: updateCalendarView,
            eventClick: handleEventClick,
            eventDrop: handleEventDrop,
            eventResize: handleEventDragTime,
            eventReceive: handleEventReceive,
            resourceAreaHeaderContent: 'Chauffeur',
            resources: technicienListToShow.value.map((val) => ({ id: val, title: val })),
            customButtons: {
                parameter: {
                    text: 'Paramètre',
                    click: openParametrage
                },
                chauffeur: {
                    text: 'Chauffeur',
                    click: openChauffeurParametrage
                },
                reload: {
                    text: 'Actualiser ↺',
                    click: refreshCalendarFromButton
                },
            },
            events: eventList.value,

            slotMinTime: "06:00:00",
            slotMaxTime: "22:00:00",
            views: {
                day: {
                    titleFormat: 'dddd D MMMM YYYY',
                },
            },
            headerToolbar: {
                left: getHeaderToolbarLeft.value,
                center: 'title',
                right: getHeaderToolbarRight.value
            },
            allDaySlot: false,
            // slotDuration: "02:00:00",
            // editable: true,
            // droppable: true,
        };
        return option;
    });

    const updateCalendarColors = function() {
        engine.get_calendar_colors(function(data) {
            if (data.code == 0 && data.data.data[0].fieldData.Value != "" && data.data.data[0].fieldData.Value != undefined) {
                eventColorByType.value = {...eventColorByType.value, ...JSON.parse(data.data.data[0].fieldData.Value) }
                refreshCalendarFromButton()
            }
        })
    }

    const updateCalendarDaysToShow = function() {
        engine.get_day_to_hide(function(data) {
            if (data.code == 0) {
                daysToHide.value = data.data.data[0].fieldData.Value.split(',').map((val) => parseInt(val))
            }
        })
    }

    const getHeaderToolbarLeft = computed(function() {
        if (userType.value == "Admin") {
            return 'today chauffeur reload parameter'
        } else return 'today reload'
    })
    const getHeaderToolbarRight = computed(function() {
        // if (userType.value == "Admin") {
        //     return 'resourceTimelineWeek dayGridWeek prev,next'
        // } else 
        return 'prev,next'

    })

    const refreshCalendarFromButton = function() {
        updateCalendar(calendarDates.value.start, calendarDates.value.end)
    }

    const openChauffeurParametrage = function() {
        isModalChauffeurSettingsOpen.value = true
    }

    const openParametrage = function() {
        isModalSettingsOpen.value = true
    }

    const updateCalendar = function(start, end) {
        engine.get_materials(parseFloat(start), parseFloat(end), function(data) {
            calendarDates.value = { start: parseInt(start), end: parseInt(end) }
            if (data.code == 0) {
                let tmpEvent = data.data.data
                let events = []
                for (let i = 0; i < tmpEvent.length; i++) {
                    let title = tmpEvent[i].fieldData.description.replaceAll('|', '-')
                    let event = {
                        resourceIds: [tmpEvent[i].fieldData.chauffeur],
                        title: title,
                        start: tmpEvent[i].fieldData.debut_timestamp,
                        end: tmpEvent[i].fieldData.fin_timestamp,
                        color: eventColorByType.value.LIVRAISON.color,
                        data: tmpEvent[i].fieldData,
                        'type': 'LIVRAISON',
                        // data: tmpEvent[i].fieldData,
                    }
                    events.push(event)
                }
                eventList.value = events
            }
            console.log("eventList", eventList.value)
        });
    }

    const updateCalendarView = function(calendarData) {
        // if (userType.value == 'Admin') {
        // document.getElementsByClassName('fc-dayGridWeek-button')[0].innerText = 'Vue chauffeur'
        // document.getElementsByClassName('fc-resourceTimelineWeek-button')[0].innerText = 'Vue Administrateur'
        // if (currentCalendarVew.value != calendarData.view.type) {
        //     refreshCalendarFromButton()
        // }
        // if (calendarData.view.type == 'resourceTimelineWeek') {
        //     userVue.value = 'Admin'
        //     currentCalendarVew.value = 'resourceTimelineWeek'
        // } else if (calendarData.view.type == 'dayGridWeek') {
        //     userVue.value = 'Chauffeur'
        //     currentCalendarVew.value = 'dayGridWeek'
        // }
        // }
        let start = parseInt(moment(calendarData.start).format("x"))
        let end = parseInt(moment(calendarData.end).format("x"))
        if (calendarDates.value.start == start && calendarDates.value.end == end) return
        else {
            if (eventList.value.length == 0) {
                updateCalendar(start, end)
            } else {
                eventList.value = []
            }
        }
    }

    onMounted(() => {
        updateCalendarColors()
        updateCalendarDaysToShow()
        engine.get_technicien_liste(function(data) {
            if (data.code == 0) {
                technicienList.value = data.data.data.map((val) => ({ id: val.fieldData.NOM_TECHNICIEN, title: val.fieldData.NOM_TECHNICIEN }))
            }
        })
        technicienListToShow.value = store.state.user.tech_to_show_planning_livraison.split(',')
        userType.value = store.state.user.admin == 1 ? 'Admin' : 'Chauffeur'
        userVue.value = userType.value
            // calendarType.value = userType.value == 'Admin' ? 'resourceTimelineWeek' : 'dayGridWeek'
    })

    return {
        calendarOptions,
        isModalChauffeurSettingsOpen,
        isModalSettingsOpen,
        technicienList,
        eventColorByType,
        technicienListToShow,
        updateCalendarColors,
        updateCalendarDaysToShow,
        daysToHide
    };
}