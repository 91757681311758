<template>
  <v-dialog v-model="showPlanningDispo" fullscreen style="background-color: rgb(144, 164, 174,0.5);">
    <div>
      <iframe style="height: 100vh; width: 100%; visibility:visible;  border: none; overflow: hidden;"
        :src="getPlanningDispoUrl"></iframe>
      <v-btn @click="showPlanningDispo = false" color="red"
        style="color: white; width: 90vw; position: absolute; bottom: 10px; left:5vw;">
        Fermer
      </v-btn>
    </div>
  </v-dialog>
  <v-dialog v-model="closeContratEdition" width="99%" max-width="300" style="background-color: rgb(144, 164, 174,0.5);">
    <div>
      <v-card>
        <v-col align="center" style="font-weight:bold">
          Valier la fermeture ? 
          <div style="font-size: 12px; color:red">
            les données non enregistrées seront perdus
          </div>
        </v-col>
        <v-col>
          <v-row align="center" justify="center">
            <v-col align="center">
              <v-btn color="red" style="color:white" @click="closeContratEdition = false">
                Non
              </v-btn>
            </v-col>
            <v-col align="center">
              <v-btn color="green" style="color:white" @click="isModalContratOpen = false; closeContratEdition = false">
                Oui
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>
  </v-dialog>
  <v-dialog v-if="get_contratListe_filtered != undefined" v-model="isModalContratOpen"
    persistent fullscreen>      
      <v-card style="position: relative;">
        <v-btn icon style="position: absolute;z-index: 3; top:5px;right:15px" density="compact" color="red"
          @click="closeContratEdition = true">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <edit-contrat-form :setMessage="setMessage" :getContratsListe="getContratsListe"
          :seletedContrat="seletedContrat" :agenceListe="agenceListe" :retraitListe="retraitListe"
          :parcListe="parcListe" :options="options" :contrat_informations="contrat_informations"
          :closeEdition="closeEdition"></edit-contrat-form>
      </v-card>
  </v-dialog>
  <v-dialog v-model="isOrModalOpen" width="98%" max-width="900" persistent>
    <v-col>
      <v-card style="overflow: auto; max-height: 90vh;">
        <v-col>
          <contrat-form :getClientListe="getClientListe" :agencyListe="agencyListe"
            :get_user_information="get_user_information"
            :setShowPlanningDispo="function () { showPlanningDispo = true }" :close="closeModal" :itemsData="itemsData"
            :technicienListe="technicienListe" :agenceListe="agenceListe" :retraitListe="retraitListe"
            :clientListe="clientListe" :parcListe="parcListe" :stockListe="stockListe" :FormData="savForm"
            :subFamilyListe="subFamilyListe" :familyListe="familyListe"
            :contrat_informations="contrat_informations"></contrat-form>
        </v-col>
      </v-card>
    </v-col>
  </v-dialog>
  <div>
    <v-card class="pa-1 mx-1">
      <v-col>
        <v-row align="center">
          <v-col class="">
            <h3>Liste des contrats</h3>
          </v-col>
          <v-col cols="auto">
            <v-btn class="mr-3" icon="mdi-calendar" color="rgb(56, 142, 60)" style="color:white"
              @click="showPlanningDispo = true">
            </v-btn>
            <v-btn variant="flat" color="rgb(92, 107, 192)" style="color:white" @click="openDialog(undefined)">Créer un
              contrat
            </v-btn>
            <v-btn icon="mdi-refresh" density="compact" style="font-size:20px" variant="text" size="40"
              @click="getContratsListe" class="ml-3"></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field hide-details v-model="search" variant="outlined" label="Recherche" clearable></v-text-field>
      </v-col>
      <v-col>
        <Vue3EasyDataTable table-class-name="customize-table" ref="dataTable" :headers="homeHeader"
          :items="get_contratListe_filtered" style="overflow: auto" :table-height="windowHeight - 300"
          rows-per-page-message="Ligne par colonne" rows-of-page-separator-message="total" rows-per-page="50"
          empty-message="Aucune contrat trouvé" @click-row="setSelectedContrat">
          <template #item-sortie="item">
            <div>
              {{ formatDate(item['LocdataS_::Date Debut Loc'], 'DD/MM/YYYY') }}
            </div>
          </template>
          <template #item-retour="item">
            <div>
              {{ formatDate(item['LocdataS_::Date retour'], 'DD/MM/YYYY') }}
            </div>
          </template>
          <template #item-facturation="item">
            <div>
              <v-icon v-if="item['N° FACT Comp'] && item['N° FACT Comp'].length > 0" color="#4CAF50"
                size="30">mdi-check-decagram</v-icon>
            </div>
          </template>
          <!-- <template #item-selectionner="item">
            <div>
              <v-btn @click="setSelectedContrat(item)" color="#4CAF50" style="color:white">
                <v-icon size="25">
                  mdi-open-in-app
                </v-icon>
              </v-btn>
            </div>
          </template> -->
        </Vue3EasyDataTable>
      </v-col>
    </v-card>
  </div>
  <v-snackbar v-model="messageData.value" :color="messageData.color" style="color:white">
    {{ messageData.text }}
  </v-snackbar>
</template>

<script>

import Vue3EasyDataTable from "vue3-easy-data-table";

// edit-contrat-form
import contartHooks from '../hooks/contrat';
import editContratForm from './edit-contrat-form';
import ContratForm from './contrat-form.vue'
import moment from 'moment'
import engine from '../core/DataApi'

export default {
  data() {
    return {
      messageData: {},
      closeContratEdition: false,
      itemsData: undefined,
      isOrModalOpen: false,
      windowHeight: window.innerHeight,
      seletedContrat: undefined,
      isModalContratOpen: false,
      showPlanningDispo: false
    }
  },
  setup() {
    const { getClientListe, getContratsListe, agencyListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, retraitListe, contrat_informations, get_user_information, search, get_contratListe_filtered, options } = contartHooks()

    return { getClientListe, getContratsListe, agencyListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, retraitListe, contrat_informations, get_user_information, search, get_contratListe_filtered, options }
  },
  mounted() {
    // engine.is_materiel_free({ UID: '30A19C6A-0FFA-9042-94AE-77F24040410A' }, function (data) {
    // engine.is_materiel_free({ UID: 'F893548F-92F3-474B-A88B-B1EB3C781E0B ddddd' }, function (data) {
    //   console.log("is_materiel_free", data);
    // })

    // engine.destock_materiel({"ID_WEB" :"caaac954-5941-4505-b6ab-7b851df955c1", "UID": "30A19C6A-0FFA-9042-94AE-77F24040410A"}, function (data) {
    //   console.log("destock_materiel", data);
    // })

    this.onResize()
  },
  watch: {
    get_contratListe_filtered(val, oldVal) {
      if (oldVal == undefined && this.seletedContrat != undefined) {
        this.setSelectedContrat(val.find((val) => val['N° Contrat Comp'] == this.seletedContrat['N° Contrat Comp']))
      }
    }
  },
  methods: {
    setMessage(text, color) {
      if (color == undefined)
        color = 'red'

      this.messageData = {
        color,
        value: true,
        text
      }
    },
    closeEdition() {
      // if (update && update == true)
      //   this.getContratsListe()
      this.isModalContratOpen = false
    },
    sendToFacturation(item) {
      engine.send_to_facturation(item.ID, item['N° Contrat Comp'], '', function () {
      })
    },
    formatDate(date, format) {
      return moment(date).format(format)
    },
    closeModal() {
      this.getContratsListe()
      this.isOrModalOpen = false
    },
    setSelectedContrat(item) {
      this.seletedContrat = item
      this.isModalContratOpen = true
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    openDialog(data) {
      this.itemsData = data
      this.isOrModalOpen = true
    }
  },
  computed: {
    getPlanningDispoUrl() {
      return window.location.href.split('/#/')[0] + '/#/planning-disponibilite?header=hide'
    },
  },
  components: {
    ContratForm,
    editContratForm,
    Vue3EasyDataTable
  }
}
</script>

<style></style>