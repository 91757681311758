<template>
  <div v-if="showApp">
    <page-calendar :windowSize="windowSize"></page-calendar>
  </div>
</template>

<script>
import PageCalendar from "@/projects/planning-technicien/components/page-calendar.vue";

export default {
  data() {
    return {
      showApp: false,
      windowSize: undefined,
    };
  },
  mounted() {
    this.updateWindowSize()
    window.addEventListener("resize", this.updateWindowSize);

    let appsToShow = this.$store.state.webApplicationToShow;
    let currentApp = this.$route.fullPath.replace("/", "");
    if (appsToShow.includes(currentApp)) this.showApp = true;
  },
  methods: {
    updateWindowSize() {
      let windowHeight = window.innerHeight
      let windowWidth = window.innerWidth

      this.windowSize = { height: windowHeight, width: windowWidth }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  },
  components: {
    PageCalendar,
  },
};
</script>

<style></style>