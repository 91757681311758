<template>
  <div v-if="showApp">
    <etat-des-lieux-materiel :windowSize="windowSize"></etat-des-lieux-materiel>
  </div>
</template>

<script>
import EtatDesLieuxMateriel from "@/projects/etat-des-lieux-materiel/component/page-home.vue";

export default {
  data() {
    return {
      showApp: false,
      windowSize: undefined,
    };
  },
  mounted() {
    this.updateWindowSize()
    window.addEventListener("resize", this.updateWindowSize);

    let appsToShow = this.$store.state.webApplicationToShow;
    let currentApp = this.$route.fullPath.replace("/", "").split('?')[0];
    
    if (appsToShow.includes(currentApp)) this.showApp = true;
  },
  methods: {
    updateWindowSize() {
      let windowHeight = window.innerHeight
      let windowWidth = window.innerWidth

      this.windowSize = { height: windowHeight, width: windowWidth }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  },
  components: {
    EtatDesLieuxMateriel,
  },
};
</script>

<style></style>