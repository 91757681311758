<template>
  <div class="planning-technicien">
    <div class="pas-2">
      <div style="font-size: 20px">
        <div v-if="isModalChangeEventStateOpen" class="modal" align="center">
          <div class="modal-content" align="left" style="width: 700px; padding: 20px; margin-top: 20%">
            <div>
              L'intervention {{ modaleChangeEventState.num }} à déja été envoyé
              au technicien(s) êtes vous sur de vouloir la modifier ?
            </div>
            <div style="margin-top: 10px; width: 100%" align="center">
              <button @click="
                (isModalChangeEventStateOpen = false),
                verifEditEventFromModal({
                  e: modaleChangeEventState.e,
                  Techniciens_Nom: modaleChangeEventState.calendarTech,
                })
                " class="modal-btn" style="background-color: #4caf50; width: 40%">
                Oui
              </button>
              <button @click="isModalChangeEventStateOpen = false" class="modal-btn"
                style="background-color: #bf360c; width: 40%">
                Non
              </button>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="isDateSettingOpen" width="99%" max-width="800">
        <v-col>
          <v-card class="pa-2" align="center">
            <v-col class="pt-1">
              <h3>
                Affichage du planning
              </h3>
            </v-col>
            <v-col class="">
              <v-row>
                <v-col cols="6" class="pa-0" style="position: relative;">
                  <div style="position: absolute; right: 15px;top:0px">
                    <v-icon size="26">mdi-menu-down</v-icon>
                  </div>
                  <select v-model="selectedNbrDays"
                    style="background-color: #E0E0E0;border: solid 1px grey; border-radius: 0px; width: 100%;"
                    align="center">
                    <option v-for="(elem, i) in nbrDaysdurationListe" :key="i" :align="isMobile ? 'left' : 'center'"
                      :value="elem" style="position: relative;">Jour a
                      afficher :
                      {{ elem }}j
                      <!-- <span v-if="">🔻</span>  -->
                    </option>
                  </select>
                </v-col>
                <v-col cols="6" class="pa-0" style="position: relative;">
                  <div style="position: absolute; right: 0px;top:0px">
                    <v-icon size="26">mdi-menu-down</v-icon>
                  </div>
                  <select v-model="selectedslotduration"
                    style="background-color: #E0E0E0;border: solid 1px grey;border-radius: 0px;width: 100%"
                    align="center">
                    <option v-for="(elem, i) in slotdurationListe" :key="i" :align="isMobile ? 'left' : 'center'"
                      :value="elem">Slot horaire :
                      {{ elem }}
                    </option>
                  </select>
                </v-col>
                <v-col cols="6" class="pa-0" style="position: relative;">
                  <div style="position: absolute; right: 15px;top:0px">
                    <v-icon size="26">mdi-menu-down</v-icon>
                  </div>
                  <select v-model="selectedStartTime"
                    style="background-color: #E0E0E0;border: solid 1px grey;border-radius: 0px;width: 100%">
                    <option v-for="(elem, i) in startPlanningTime" :key="i" :align="isMobile ? 'left' : 'center'"
                      :value="elem">Début : {{ elem
                      }}
                    </option>
                  </select>
                </v-col>
                <v-col cols="6" class="pa-0" style="position: relative;">
                  <div style="position: absolute; right: 15px;top:0px">
                    <v-icon size="26">mdi-menu-down</v-icon>
                  </div>
                  <select v-model="selectedEndTime"
                    style="background-color: #E0E0E0;border: solid 1px grey;border-radius: 0px;width: 100%"
                    align="center">
                    <option v-for="(elem, i) in endPlanningTime" :key="i" :align="isMobile ? 'left' : 'center'"
                      :value="elem">Fin : {{ elem }}
                    </option>
                  </select>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-dialog>
      <v-dialog v-model="isModalOptionOpen" width="99%" max-width="800">
        <v-card>
          <modal-options :isRoot="isRoot" :technicienList="technicienList" :commentaireListe="commentaireListe"
            :setCommentaires="setCommentaires" @close="isModalOptionOpen = false"></modal-options>
        </v-card>
      </v-dialog>
      <v-dialog v-model="isModalSettingsOpen" persistent>
        <modal-settings :eventColorByType="eventColorByIdFiltered" :daysToHide="daysToHide"
          @closeSettingsModal="closeSettingsModal"></modal-settings>
      </v-dialog>
      <v-dialog v-model="isModalSelectionTechnicienOpen" width="99%" max-width="800">
        <modal-selection-technicien :isRoot="isRoot" :technicienList="technicienList"
          :technicienListAvailable="technicienListAvailable" :setTechnicienAvailable="setTechnicienAvailable"
          :addOrRmvTechnicien="addOrRmvTechnicien" :updateTechToShow="updateTechToShow"
          :setSelectedTechniciens="setSelectedTechniciens" :selectedTechniciens="selectedTechniciens"
          @close="isModalSelectionTechnicienOpen = false"
          @save="editSelectionnedTechnicien($event)"></modal-selection-technicien>
      </v-dialog>
      <v-dialog v-model="isModalTechnicienOpen">
        <modal-technicien :data="modaleTechnicienData" :technicienList="technicienList"
          :planningSettings="planningSettings" @close="closeModalEvent" @save="verifEditEventFromModal($event)">
        </modal-technicien>
      </v-dialog>
      <v-dialog v-model="isModalEventOpen" width="99%" max-width="800">
        <modal-event :event-data="selectedEvent" :optionsPlanning="optionsPlanning" :eventColorById="eventColorById"
          :eventColorByIdFiltered="eventColorByIdFiltered" :isRoot="isRoot" @close="closeModalEvent"
          @save="editEventFromModal"></modal-event>
      </v-dialog>
      <div style="">
        <div id="" class="hideDot" v-if="isDataLoaded">
          <full-calendar class="hideDot" ref="calendarTech" :options="calendarOptions"
            :style="{ height: windowSize.height - 225 + 'px' }" />
        </div>
        <div v-else align="center" style="padding-top: 20%">
          <v-progress-circular size="120" indeterminate color="black">Chargement</v-progress-circular>
        </div>
      </div>
      <v-col cols="12">
        <!-- <v-row justify="center" align="center" :style="{ height: ((windowSize.height * 0.043) * 5) + 'px' }"> -->
        <v-row align="center" :style="isDataLoaded ? 'background-color: #c8e6c9;' : ''">
          <v-col v-if="isDataLoaded" cols="3" md="2" class="" style="background-color: #c8e6c9;border-right: solid 1px white;">
            <v-row justify="center" align="center">
              <v-col cols="6" class="pa-1">
                <v-btn style="cursor: pointer; width:100%;" density="compact" @click="refreshData = true">
                  <v-icon class="">mdi-reload</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-1">
                <v-btn style="cursor: pointer; width:100%;" density="compact" @click="isDateSettingOpen = true">
                  <v-icon class="mr-2">mdi-calendar-month-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-1">
                <v-btn :disabled="!isRoot" density="compact" style="
                width:100%;
                  cursor: pointer;
                " @click="isModalOptionOpen = true">
                  <v-icon>mdi-comment</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-1">
                <v-btn :disabled="!isRoot" density="compact" style="
                width: 100%;
                  cursor: pointer;
                " @click="isModalSelectionTechnicienOpen = true">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="pa-1">
                <v-text-field placeholder="Recherche..." v-model="filter" hide-details density="compact" variant="solo"
                  style="background-color: white"></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-1">
                <v-select clearable class="" hide-details placeholder="Agence" density="compact"
                  v-model="selectedAgency" variant="solo" :items="agencyList" item-value="id" item-title="text">
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col :style="isDataLoaded ? '' : 'visibility:hidden'"
            style="border-radius: 0px; width: 100%; overflow-y: hidden;overflow-x: auto;  white-space: nowrap; background-color: #c8e6c9;"
            class="pa-1" id="external-events">
            <v-card height="100%" v-for="(elem, idx) in getEventsListFiltered" :key="idx"
              @mouseover="setSelectedEvent(elem)" style="border-radius: 3px;padding: 5px; display: inline-block;"
              :style="elem.id == selectedEvent.id
                ? 'border: solid 2px red;background-color:' +
                eventColorById[elem.Statut].color
                : 'border: solid 2px transparent;background-color:' + eventColorById[elem.Statut].color
                " class="mr-1" :class="elem.id == selectedEvent.id ? 'fc-event' : ''">
              <div color="transparent" style="color: white;">
                <div align="right" style="color: red; position: relative">
                  <v-btn density="compact" :disabled="!isRoot" color="red" style="
                      position: absolute;
                      right: 0px;
                      cursor: pointer;
                      z-index: 3;
                    " icon="mdi-close" @click="removeEventFromList(elem)">
                  </v-btn>
                </div>
                <div style="">
                  <div class="">
                    <div class="">
                      <v-menu open-on-hover open-delay="0">
                        <template v-slot:activator="{ props }">
                          <div v-bind="props">
                            <div class="fc-event-main">
                              {{ elem["N° RAP COMP"] }} <br>
                            </div>
                            <div style="font-size: 15px">
                              ({{ elem["Technicien"].join(" - ") }})
                            </div>
                            <div class="fc-event-main" style="color: #e6ee9c;font-size: 16px">
                              {{ elem["societeliv"] }}
                            </div>
                            <div class="">
                              <div
                                style="font-size: 15px; width: 300px; word-wrap: break-word;overflow:auto;  overflow-wrap: break-word; white-space: normal; display: block; height: 50px;">
                                {{ elem["Theme devis"] }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <v-card class="pa-2">
                          <div class="fc-event-main" style="font-size: 12px;">
                            <div>
                              {{ elem["villeliv"] }}
                            </div>
                            <div>
                              {{ elem["adliv"] }}
                            </div>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <div v-if="errorMessage" style="position: absolute; width: 100%; bottom: 2vh; z-index: 3" align="center">
        <div style="
            background-color: #d50000;
            width: 500px;
            padding: 10px;
            border-radius: 7px;
            color: white;
            font-size: 15px;
          ">
          <div v-for="elem in errorMessage" :key="elem">
            {{ elem }}
          </div>
        </div>
      </div>
    </div>
    <v-btn color="#5C6BC0" :style="{ 'top': parseInt(calendarOptions.height.replace('vh', '')) - 4 + 'vh' }"
      style="color:white; margin-left: 10px; position:absolute;right:7px;"
      v-if="fullView == false && showLegende == false" @click="showLegende = true">
      Légende
    </v-btn>
    <div v-if="isDataLoaded"
      :style="{ 'top': fullView ? '' : parseInt(calendarOptions.height.replace('vh', '')) - 22 + 'vh' }" style="position: absolute;
        bottom: 22vh;
        right: 25px;
        z-index: 1;
">
      <v-hover v-if="showLegende == true || fullView" v-slot="{ isHovering, props }" open-delay="0">
        <div @click="showLegende = false" v-bind="props" :style="{ 'opacity': isHovering && fullView ? '0' : '1' }"
          style="
        background-color: #e0e0e0;
        border-style: none;
        padding: 5px;
        margin-left: 10px;
        border-radius: 10px;
        box-shadow: 1px 1px 10px 2px grey;
      ">
          <!-- <button v-for="elem in Object.values(eventColorByIdFiltered).filter((val) =>
            (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 0 && val.key != '120')) || (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 1)"
            :key="elem.key" :style="{ 'background-color': eventColorByIdFiltered[elem.key].color }" style="
          font-size: 15px;
          margin: 5px;
          border: solid 1px grey;
          padding: 5px;
          border-style: none;
          border-radius: 5px;
          color: black;
        ">
            <v-chip style="
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            cursor: pointer;
          ">
              {{ elem.label }}
            </v-chip>
          </button> -->
          <button v-for="elem in (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 1)
            ? Object.values(eventColorByIdFiltered) : Object.values(eventColorByIdFiltered).filter((val) =>
              (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 0 && val.key != '120'))"
            :key="elem.key" :style="{ 'background-color': eventColorByIdFiltered[elem.key].color }" style="
          font-size: 15px;
          margin: 5px;
          border: solid 1px grey;
          padding: 5px;
          border-style: none;
          border-radius: 5px;
          color: black;
        ">
            <v-chip style="
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            cursor: pointer;
          ">
              {{ elem.label }}
            </v-chip>
          </button>
        </div>
      </v-hover>
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import planningTechnicien from "../core/index";

import ModalEvent from "./modal-event.vue";
import ModalTechnicien from "./modal-technicien.vue";
import ModalSelectionTechnicien from "./modal-selection-technicien.vue";
import ModalOptions from "./modal-options.vue";
import ModalSettings from "@/components/modal-planning-settings.vue";

import calendarData from "../hooks/index";
import { ref } from "@vue/reactivity";
import { computed, watch } from "@vue/runtime-core";

import moment from "moment";
import engine from "../core/index";

export default {
  props: {
    windowSize: {}
  },
  components: {
    FullCalendar,
    ModalEvent,
    ModalTechnicien,
    ModalSelectionTechnicien,
    ModalSettings,
    ModalOptions
  },
  setup(props) {
    const showOnlyState2 = ref(false);
    const modaleTechnicienData = ref({});
    const modaleChangeEventState = ref({});
    const isDateSettingOpen = ref(false);
    const isModalTechnicienOpen = ref(false);
    const isModalOptionOpen = ref(false);
    const isModalChangeEventStateOpen = ref(false);
    const isModalSelectionTechnicienOpen = ref(false);
    const connectionError = ref(false);
    const filter = ref("");
    const userMail = ref(undefined);
    const userPassword = ref(undefined);
    const fullView = ref(true);
    const showLegende = ref(false);

    const handleEventReceive = function (e) {
      if (isRoot.value) {
        if (
          isGoodEventSelected(e, selectedEvent) &&
          isEditableEvent(e, selectedEvent.value)
        ) {
          editEventFromCalendar(e, undefined, true, true);
        }
      } else {
        showErrorMessage(
          "ERREUR : Cette action nécessite un compte administrateur"
        );
      }
      e.revert();
    };
    const handleEventDragTime = function (e) {
      if (isRoot.value) {
        let event = e.event.extendedProps.event;
        selectedEvent.value = event;
        if (isEditableEvent(e, selectedEvent.value))
          editEventFromCalendar(e, undefined, false, false);
      } else {
        showErrorMessage(
          "ERREUR : Cette action nécessite un compte administrateur"
        );
        e.revert();
      }
    };
    const handleEventClick = function (e) {
      if (e.event.display != "background") {
        let event = e.event.extendedProps.event;
        selectedEvent.value = event;
        isModalEventOpen.value = true;
      } else {
        if (e.event.extendedProps.TYPE != undefined)
          showErrorMessage(
            "INDISPONIBILITÉ : " + e.event._def.resourceIds,
            moment(e.event.start).lang("fr").format("LLLL") +
            " / " +
            moment(e.event.end).lang("fr").format("LLLL")
          );
      }
    };
    const handleEventDrop = function (e) {

      if (isRoot.value) {
        let event = e.event.extendedProps.event;
        selectedEvent.value = event;

        if (isEditableEvent(e, selectedEvent.value))
          editEventFromCalendar(e, undefined, false, false);
      } else {
        showErrorMessage(
          "ERREUR : Cette action nécessite un compte administrateur"
        );
        e.revert();
      }
    };
    const isGoodEventSelected = function (eventData, selectedEvent) {
      let eventTitle = eventData.event._def.title;
      // let selectedEventTitle =
      //   selectedEvent.value["N° RAP COMP"] +
      //   "( " +
      //   String(selectedEvent.value["Technicien"]).replaceAll(",", " - ") +
      //   " )" +
      //   selectedEvent.value["societeliv"] +
      //   selectedEvent.value["villeliv"] +
      //   selectedEvent.value["adliv"] +
      //   selectedEvent.value["Theme devis"];

      // console.log("selectedEventTitle", selectedEventTitle);
      // console.log("eventTitle", eventTitle);


      if (eventTitle.includes(selectedEvent.value["N° RAP COMP"]) &&
        eventTitle.includes(selectedEvent.value["Technicien"])
        // eventTitle.includes(selectedEvent.value["N° RAP COMP"]) &&
        // eventTitle.includes(selectedEvent.value["N° RAP COMP"])
        // (eventTitle.replaceAll("\n", "").replaceAll(" ", "").replaceAll("\t", "") ==
        //   "✖" + selectedEventTitle.replaceAll("\r", "").replaceAll(" ", "").replaceAll("\t", "")) || (
        //   eventTitle.replaceAll("\n", "").replaceAll(" ", "").replaceAll("\t", "") ==
        //   selectedEventTitle.replaceAll("\r", "").replaceAll(" ", "").replaceAll("\t", "")
        // )
      )
        return true;
      else {
        showErrorMessage("ERREUR : " + selectedEvent.value["N° RAP COMP"]);
        return false;
      }
    };

    const isEditableEvent = function (e, event) {
      if (event.Statut != 140) {
        if (event.Technicien.join("-") != e.event._def.resourceIds.join("-")) {
          if (isTechnicienAvailable(e, event, e.event._def.resourceIds)) {
            isModalTechnicienOpen.value = true;
            modaleTechnicienData.value = {
              e: e,
              num: event["N° RAP COMP"],
              eventId: event.id,
              eventTech: event.Technicien,
              calendarTech: e.event._def.resourceIds,
              technicienListAvailable: technicienListAvailable,
            };
          }
          e.revert();
          return false;
        }
        if (isTechnicienAvailable(e, event, event.Technicien)) {
          if (event.Statut == 120 || event.Statut == 130) {
            isModalChangeEventStateOpen.value = true;
            modaleChangeEventState.value = {
              e: e,
              num: event["N° RAP COMP"],
              calendarTech: e.event._def.resourceIds,
            };
            e.revert();
            return false;
          }
          return true;
        }
        return false;
      } else {
        e.revert();
        showErrorMessage(
          "ERREUR : Intervention " +
          selectedEvent.value["N° RAP COMP"] +
          " est terminé n'est donc plus modifiable."
        );
        return false;
      }
    };

    const removeEventFromList = function (item) {
      editEventFromModal({ id: item.id, Statut: "99", Debut: "", Fin: "" });
    };

    const setSelectedTechniciens = function () {
      if (selectedTechniciens.value.length === technicienList.value.length) {
        selectedTechniciens.value = [];
      } else {
        selectedTechniciens.value = technicienList.value.map((val) => val.id);
      }
    };

    const addOrRmvTechnicien = function (technicienName) {
      if (selectedTechniciens.value.includes(technicienName)) {
        let index = selectedTechniciens.value.indexOf(technicienName);
        selectedTechniciens.value.splice(index, 1);
      } else {
        selectedTechniciens.value.push(technicienName);
      }
    };

    const closeModalEvent = function () {
      isModalEventOpen.value = false;
      isModalTechnicienOpen.value = false;
    };

    const updateTechToShow = function () {
      let token = localStorage.getItem("userToken");
      planningTechnicien.setTechToShow(
        token,
        selectedTechniciens.value.join(","),
        function () { }
      );
    };

    const getTechToShow = function () {
      planningTechnicien.getTechToShow(
        userMail.value,
        userPassword.value,
        function (data) {
          if (data.code == 0) {
            if (data.data == "") {
              selectedTechniciens.value = technicienList.value.map(
                (val) => val.title
              );
            } else selectedTechniciens.value = data.data.split(",");
          }
        }
      );
    };

    const verifEditEventFromModal = function (eventEdited) {
      this.editEventFromCalendar(
        eventEdited.e,
        eventEdited.Techniciens_Nom,
        true,
        false
      );
      isModalTechnicienOpen.value = false;
      isModalEventOpen.value = false;
    };

    const editEventFromModal = function (eventEdited) {
      planningTechnicien.set_event(
        eventEdited.id,
        eventEdited,
        function (data) {
          if (data.code == 0) {
            initEvents();
          }
        }
      );
      isModalTechnicienOpen.value = false;
      isModalEventOpen.value = false;
    };

    const setAgency = function (agencyId) {
      planningTechnicien.setAgency(
        userMail.value,
        userPassword.value,
        agencyId,
        function () { }
      );
    };

    const getAgency = function () {
      planningTechnicien.getAgency(
        userMail.value,
        userPassword.value,
        function (data) {
          if (data.code == 0 && data.data != "") {
            selectedAgency.value = data.data;
          }
        }
      );
    };

    const getEventsListFiltered = computed(function () {
      if (eventsList.value.length == 0) return [];
      let filteredData = eventsList.value.filter((val) =>
        [
          val["Technicien"]
            .filter((val) => val != null)
            .join(" - ")
            .toLowerCase(),
          val["N° RAP COMP"].toLowerCase(),
          val["adliv"].toLowerCase(),
          val["societeliv"].toLowerCase(),
          val["villeliv"].toLowerCase(),
        ]
          .join(" ")
          .includes(filter.value.toLowerCase())
      );
      if (selectedAgency.value)
        filteredData = filteredData.filter(
          (val) => val.ID_Agence == selectedAgency.value
        );
      if (showOnlyState2.value == true) {
        filteredData = filteredData.filter((val) => val.Statut == "120");
      }
      return filteredData;
    });

    watch(filter, (val) => {
      if (val[0] == " ") filter.value = filter.value.trim();
    });

    const {
      eventsList,
      calendarOptions,
      selectedEvent,
      isModalEventOpen,
      eventsCalendar,
      editEventFromCalendar,
      isDataLoaded,
      eventColorById,
      initEvents,
      calendarDate,
      refreshData,
      isConnected,
      selectedAgency,
      agencyList,
      technicienList,
      selectedTechniciens,
      technicienListAvailable,
      setTechnicienAvailable,
      isTechnicienAvailable,
      showErrorMessage,
      errorMessage,
      planningSettings,
      isRoot,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
      isModalSettingsOpen,
      commentaireListe,
      setCommentaires,
      eventColorByIdFiltered,
      optionsPlanning,
      nbrDaysdurationListe,
      slotdurationListe,
      startPlanningTime,
      endPlanningTime,
      selectedNbrDays,
      selectedslotduration,
      selectedStartTime,
      selectedEndTime,
      updatePlanningVue
    } = calendarData(
      handleEventClick,
      handleEventDrop,
      handleEventDragTime,
      handleEventReceive,
      getTechToShow,
      setAgency,
      getAgency,
      fullView,
      props.windowSize,
    );
    return {
      getEventsListFiltered,
      calendarOptions,
      selectedEvent,
      isModalEventOpen,
      editEventFromCalendar,
      eventsCalendar,
      errorMessage,
      isDataLoaded,
      eventColorById,
      filter,
      editEventFromModal,
      closeModalEvent,
      calendarDate,
      isModalTechnicienOpen,
      modaleTechnicienData,
      verifEditEventFromModal,
      refreshData,
      isConnected,
      userPassword,
      userMail,
      connectionError,
      eventsList,
      agencyList,
      selectedAgency,
      isRoot,
      technicienList,
      selectedTechniciens,
      addOrRmvTechnicien,
      isModalSelectionTechnicienOpen,
      setSelectedTechniciens,
      showOnlyState2,
      isModalChangeEventStateOpen,
      modaleChangeEventState,
      removeEventFromList,
      technicienListAvailable,
      setTechnicienAvailable,
      updateTechToShow,
      planningSettings,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
      isModalSettingsOpen,
      isModalOptionOpen,
      commentaireListe,
      setCommentaires,
      fullView,
      showLegende,
      eventColorByIdFiltered,
      optionsPlanning,
      isDateSettingOpen,
      nbrDaysdurationListe,
      slotdurationListe,
      startPlanningTime,
      endPlanningTime,
      selectedNbrDays,
      selectedslotduration,
      selectedStartTime,
      selectedEndTime,
      updatePlanningVue
    };
  },
  mounted() {
    this.setCalendarView()
  },
  watch: {
    isMobile() {
      this.setCalendarView()
    },
    selectedNbrDays() { this.updatePlanningVue() },
    selectedslotduration() { this.updatePlanningVue() },
    selectedStartTime() { this.updatePlanningVue() },
    selectedEndTime() { this.updatePlanningVue() },
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.xs
    }
  },
  methods: {
    setCalendarView() {
      // let that = this
      // let view = ""

      // if (this.$refs && this.$refs.calendarTech) {
      //   if (this.isMobile) {
      //     view = "listWeek"
      //     this.fullView = false
      //   } else {
      //     view = "resourceTimelineWeek"
      //     this.fullView = true
      //   }

      //   let calendarApi = this.$refs.calendarTech.getApi()
      //   calendarApi.changeView(view);
      // } else {
      //   setTimeout(function () {
      //     that.setCalendarView()
      //   }, 250)
      // }
    },
    closeSettingsModal(data) {
      let that = this;
      let days = data.daysListe.filter(
        (val) => !data.daysToShow.includes(val.idx)
      );
      if (days) {
        days = days.map((val) => val.idx);
        engine.save_day_to_hide(days.toString(), function () {
          that.updateCalendarDaysToShow();
        });
      }
      engine.save_calendar_colors(
        JSON.stringify(data.localColortab),
        function () {
          that.updateCalendarColors();
        }
      );
      this.isModalSettingsOpen = false;
    },
    editSelectionnedTechnicien(data) {
      this.selectedTechniciens = data;
    },
    setSelectedEvent(elem) {
      this.selectedEvent = elem;
    },
  },
};
</script>

<style>
@import "../style.css";

.planning-technicien #external-events {
  float: left;
  /* margin: 10px; */
  /* height: 94.8vh; */
  color: black;
  background: #c8e6c9;
  border-radius: 3px;
  border: solid 1px #dcedc8 !important;
}

.planning-technicien #external-events .fc-event {
  cursor: move;
  /* margin: 3px 0; */
}

.planning-technicien #calendar-container {
  /* margin: 10px; */
  padding-top: 10px;
}

.planning-technicien #calendar {
  border: solid 1px lightgrey;
  border-radius: 3px;
  padding: 5px;
}


.planning-technicien .hideDot .fc-list-event-graphic {
  background: white;
  min-width: 0px;
  width: 0px;
  padding-left: 0px !important;
}

.planning-technicien .hideDot .fc-list-event-time {
  background: white;
}

.planning-technicien .hideDot .fc-list-event-title {
  background: white;
}
</style>